import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import theme from '../../../config/theme';
import { CatalogExtended } from "../../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import DealExt from "../../../my-lemonade-library/model/DealExtended/DealExt";
import { getDealBackgroundToRender } from '../../service/dealDisplayService';

interface DealDisplayImageProps {
    selectedDeal: DealExt;
    selectedCatalog?: CatalogExtended;
}

const DealDisplayImage: React.FC<DealDisplayImageProps> = (props) => {
    const {
        selectedDeal,
        selectedCatalog
    } = props;

    const [backgroundToRender, setBackgroundToRender] = useState<string | null>(null);

    useEffect(() => {
        setBackgroundToRender(getDealBackgroundToRender(selectedDeal, selectedCatalog));
    }, [selectedDeal, selectedCatalog]);

    return (
        <Box
            flex={1}
            height={"100%"}
        >
            <Box
                style={{
                    position: 'relative',
                    borderRadius: "20px",
                    overflow: 'hidden',
                    height: '100%',
                    border: `1px solid ${theme.palette.grey[300]}`
                }}
            >
                {!backgroundToRender ? (
                    <Box
                        style={{
                            background: theme.palette.grey[200],
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ImageNotSupportedIcon style={{
                            fontSize: '200px',
                            color: theme.palette.grey[400]
                        }} />
                    </Box>
                ) : <img
                    src={backgroundToRender}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                    }}
                />}
            </Box>
        </Box>
    )
}

export default DealDisplayImage;