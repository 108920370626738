export enum ConnectorType {
    HUBRISE = 'hubrise',
    ZELTY = 'zelty',
    CARREPOS = 'carrepos',
    DELIVERECT = 'deliverect',
    SYMBIOZ = 'symbioz',
    TILLER = 'tiller',
    LEO2 = 'leo2',
    LIGHTSPEED = 'lightspeed',
    RUSHOUR = 'rushour',
    DELIVEROO = 'deliveroo',
    KEYTCHENS = 'keytchens',
    UNTILL = 'untill',
    PI = 'pi',
    NO_CONNECTOR = 'no_connector',
    OPOSSUM = 'opossum',
    ALLEATONE = "alleatone",
    SQUARE = "square",
    ORCHESTRA = "orchestra",

    KILLBILLS = "killbills",
    LABWARE = "labware",

    // Not implemented for now in conector but only in printer or to retrieve orders
    JALIA = "jalia",
    ADDITION = "addition",
    CLYO = "clyo",
    TRIVEC = "trivec",
    POPINA = "popina",
    // TODO: check if related to a version instead
    POPINA_TEXT = "popina_text",
    VEGA = "vega",
    PHARE = "phare",
    STARVISION = "starvision",
    CASHMAG = "cashmag",
    VENTAP = "ventap",
    OTHER = "other",
    CASHPAD = "cashpad",
    BOXAPOS = "boxapos",
    VECTRON = "vectron",

    // TAP Connectors
    TWELVE = "twelve",
    MPLUS = "mplus",
    BORK = "bork",
    // UNTILL = 'untill',
    TAPP_SENSOR = "tapp_sensor",
    TAPP_API_GATEWAY = "tapp_api_gateway",
    TAPP_LIGHTSPEED = "tapp_lightspeed",
    TAPP_LIGHTSPEED_OAUTH2 = "tapp_lightspeed_oauth2",
    GASTROFIX = "gastrofix",
    NEXT_TO_FOOD = "ntf",
    UNITOUCH = "unitouch",
    BOOQ = "booq",
    DEBUUT = "debuut",
    ZETTLE = "zettle",
}

export const connectorIdToType: { [connectorId: string]: ConnectorType } = {
    "02": ConnectorType.ADDITION,
    "03": ConnectorType.TRIVEC,
    "04": ConnectorType.KILLBILLS,
    "05": ConnectorType.LABWARE,
    "06": ConnectorType.JALIA,
    "07": ConnectorType.POPINA,

    // Germany
    "08": ConnectorType.VECTRON,

    // Netherlands
    "09": ConnectorType.TWELVE,
    "10": ConnectorType.MPLUS,
    "11": ConnectorType.BORK,
    "12": ConnectorType.UNTILL,
    "13": ConnectorType.TAPP_SENSOR,
    "14": ConnectorType.TAPP_API_GATEWAY,
    "15": ConnectorType.TAPP_LIGHTSPEED,
    "16": ConnectorType.TAPP_LIGHTSPEED_OAUTH2,
    "17": ConnectorType.GASTROFIX,
    "18": ConnectorType.NEXT_TO_FOOD,
    "19": ConnectorType.UNITOUCH,
    "20": ConnectorType.BOOQ,
    "21": ConnectorType.DEBUUT,
    "22": ConnectorType.ZETTLE,
}
