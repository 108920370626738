import _ from 'lodash';
import log from 'loglevel';
import { getDeviceDetectInfo } from '../../Common/helper/DeviceHelper';
import { getMylemonadeContext } from '../../Common/services/LogService';
import { Catalog } from '../../my-lemonade-library/model/Catalog';
import { Location, SupportedServiceType, Table } from '../../my-lemonade-library/model/Location';
import { OrderCharge, OrderChargeType, OrderInBase, OrderItem, OrderStatus, PaymentType } from '../../my-lemonade-library/model/Order';
import { OrderError } from '../../my-lemonade-library/model/OrderError';
import { DEFAULT_CURRENCY, getCurrency, substractMoney, updateCurrencyOfMoney } from '../../my-lemonade-library/src/common/models/Money';
import DeliveryStatus from '../../my-lemonade-library/src/delivery/models/DeliveryStatus';
import { DiscountType, OrderDiscount } from '../../my-lemonade-library/src/discounts/models/OrderDiscount';
import { OrderPriceArg } from '../../my-lemonade-library/src/orders/models/OrderPriceArg';
import { orderPrice } from '../../my-lemonade-library/src/orders/services/OrderPricing';
import { orderService } from '../../my-lemonade-library/src/orders/services/OrderService';
import { SpecificPaymentType } from '../../my-lemonade-library/src/payments/models/PaymentTypeExtended';
import { OrderState } from './models/OrderState';
import { ADD_CONTRIBUTOR_LOCAL, ADD_DEAL, ADD_DISCOUNT_TO_ORDER, ADD_ITEM, ADD_ITEMS, AddItemAction, CLOSE_MODAL, COMPUTE_ORDER_PRICE, EARN_LOYALTY, EARN_LOYALTY_ERROR, EARN_LOYALTY_SUCCESS, EDIT_DEAL, FORCE_SEND_TO_API_LOADER, LOAD_AVAILABLE_TIMESLOTS, LOAD_AVAILABLE_TIMESLOTS_ERROR, LOAD_AVAILABLE_TIMESLOTS_SUCCESS, LOAD_ORDERS, LOAD_ORDERS_ERROR, LOAD_ORDERS_SUCCESS, MERGE_ORDER, OPEN_MODAL, OrderActionTypes, REFRESH_ORDER_FROM_CONNECTOR, REFRESH_ORDER_FROM_CONNECTOR_ERROR, REFRESH_ORDER_FROM_CONNECTOR_SUCCESS, REMOVE_CONTRIBUTOR_LOCALLY, REMOVE_DEAL, REMOVE_ITEM, RESET_ITEMS, RESET_ORDER, RESET_ORDER_ERROR, RESET_ORDER_PAYMENTS, SEND_ORDER, SEND_ORDER_ERROR, SEND_ORDER_SUCCESS, SET_CHARGE, SET_CUSTOMER_INFO, SET_CUSTOMER_NOTES, SET_DELIVERY_ZONE, SET_EXPECTED_TIME, SET_INFO, SET_ITEMS_TO_PAY, SET_MASTER_USER, SET_ORDER, SET_ORDER_SERVICE_TYPE, SET_PAYMENT_TYPE, SET_PICKUP, SET_STATUS_WAITING_SUBMISSION, SET_TABLE_ID, UPDATE_CONTRIBUTOR, UPDATE_CURRENCY, UPDATE_ITEM_NOTE, UPDATE_LOYALTY_POINTS_USAGE, UpdateLoyaltyPointsUsageAction } from './OrderActions';

const orderInitialState: OrderState = {

    order: {
        id: '',
        account_id: '',
        location_id: '',
        catalog_id: '',
        table_id: '',
        created_at: null,
        display_id: '',
        collection_code: '',
        total: `0.00 ${DEFAULT_CURRENCY}`,
        status: OrderStatus.DRAFT,
        payment_type: PaymentType.TABLE,
        items: [],
        deals: {},
        service_type: SupportedServiceType.VIEW,
        customer: {
            uid: '',
            phone: '',
            first_name: '',
            devices: {}
        },
        charges: [],
        delivery_zone_ref: undefined,
        contributors: {},
        master_user_uid: undefined,
    },

    currentOrder: {
        sending: false,
        refreshing: false,
        last_refreshed_at: null,
        error: false,
        errorCode: "",
        errorMessage: "",
        errorValue: null,
    },

    initOrders: {
        loading: true,  // When launching the webapp, we're loading the orders
        error: null,
        latestUserOrders: [],
        latestTableOrders: [],
        connectorGetTableOpenedOrdersError: undefined,
    },

    availableTimeslots: {
        loading: false,
        data: null
    },

    openModal: false,
    displaySharedOrderPopup: false,

    payment_amount: undefined,
    table_id: undefined,
    account_id: undefined,
    location_id: undefined,
    catalog_id: undefined,
    items_to_pay: undefined,

    payment_page: undefined,
    payment_infos: undefined,
}

const deviceInfo = getDeviceDetectInfo();
if (deviceInfo && deviceInfo.uuid && orderInitialState.order.customer) {
    log.debug(`Device info`, deviceInfo);
    orderInitialState.order.customer.current_device = deviceInfo;
}

export default function orderReducers(
    state: OrderState = orderInitialState,
    action: OrderActionTypes,
): OrderState {
    let index: any;
    let nstate: OrderState;

    switch (action.type) {

        case LOAD_AVAILABLE_TIMESLOTS:
            return {
                ...state,
                availableTimeslots: {
                    loading: true,
                    data: null
                }
            }

        case LOAD_AVAILABLE_TIMESLOTS_SUCCESS:
            return {
                ...state,
                availableTimeslots: {
                    loading: false,
                    data: action.payload
                }
            }

        case LOAD_AVAILABLE_TIMESLOTS_ERROR:
            return {
                ...state,
                availableTimeslots: {
                    loading: false,
                    data: null
                }
            }

        case FORCE_SEND_TO_API_LOADER:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    sending: true,
                },
            }

        case COMPUTE_ORDER_PRICE:

            if (orderService.orderNeedsPricing(state.order)) {
                return computePrice(state, state.order.items, action.payload.catalog, action.payload.location, action.payload.table);
            }

            return state;

        case SET_CUSTOMER_INFO:

            if (!action.payload) {
                return state;
            }

            if (_.isNil(action.payload.customer)) {
                log.warn(`Trying to update customer information but provided value is null or undefined`);
            }

            if (action.payload.forceSetAll && action.payload.customer) {
                return {
                    ...state,
                    order: {
                        ...state.order,
                        customer: action.payload.customer,
                    }
                }
            }

            const currentCustomerClone = _.cloneDeep(state.order.customer ?? {});
            _.merge(currentCustomerClone, action.payload.customer);

            // Keep existing address
            if (!action.payload.setAddressFromCustomer) {
                currentCustomerClone.address_1 = state.order.customer?.address_1;
                currentCustomerClone.address_2 = state.order.customer?.address_2;
                currentCustomerClone.city = state.order.customer?.city;
                currentCustomerClone.state = state.order.customer?.state;
                currentCustomerClone.postal_code = state.order.customer?.postal_code;
                currentCustomerClone.country = state.order.customer?.country;
                currentCustomerClone.latitude = state.order.customer?.latitude;
                currentCustomerClone.longitude = state.order.customer?.longitude;
            }

            return {
                ...state,
                order: {
                    ...state.order,
                    customer: currentCustomerClone
                }
            }

        case SET_TABLE_ID:
            return {
                ...state,
                table_id: action.payload
            }
        case SET_ORDER_SERVICE_TYPE:
            return {
                ...state,
                order: {
                    ...state.order,
                    service_type: action.payload
                }
            }

        case OPEN_MODAL:
            return {
                ...state,
                openModal: true,
            };

        case CLOSE_MODAL:
            return {
                ...state,
                openModal: false,
            };

        case ADD_ITEMS:
            const listItems: any[] = action.payload.listItems;
            let nItems = [...state.order.items]
            listItems.forEach((item) => {
                const index = nItems.findIndex(
                    (elem: OrderItem) =>
                        elem.sku_ref === item.sku_ref && !elem.update_id &&
                        orderService.optionEquals(item.options, elem.options),
                );
                if (index !== -1) {
                    log.debug(`Product found in order`)
                    nItems[index].quantity += item.quantity;
                } else {
                    log.debug(`New product in order`)
                    nItems.push(item)
                }
            })
            nstate = computePrice(state, nItems, action.payload.catalog, action.payload.location, action.payload.table);
            return nstate;


        case ADD_ITEM:
            const addItemAction = action as AddItemAction;
            const addItemPayload = addItemAction.payload;

            log.debug("Adding order item");
            log.debug(addItemPayload.product);

            index = orderService.getSameItemIndex(state.order, addItemPayload.product);

            if (index !== -1) {
                let nitems: any = state.order.items;
                nitems[index].quantity += addItemPayload.product.quantity;

                nstate = computePrice(state, nitems, addItemPayload.catalog, addItemPayload.location, addItemPayload.table);

                return nstate;
            }

            nstate = computePrice(
                state,
                state.order.items.concat({
                    ...addItemPayload.product,
                }),
                addItemPayload.catalog,
                addItemPayload.location,
                addItemPayload.table,
            );

            return nstate;

        case REMOVE_ITEM:
            index = state.order.items.findIndex((elem: OrderItem) => (
                elem.sku_ref === action.payload.sku_ref
                && orderService.optionEquals(action.payload.options, elem.options)
                && !elem.update_id
            ));

            // if selected element is found in order
            if (index !== -1) {
                let nitems: OrderItem[] = state.order.items;

                //if only 1 quantity, delete item from OrderItem[]
                if (nitems[index].quantity === 1) {
                    nitems.splice(index, 1);

                    nstate = computePrice(state, nitems, action.payload.catalog, action.payload.location, action.payload.table);

                    return nstate;
                } else {
                    // if more than 1 quantity, minus 1 on quantity
                    nitems[index].quantity -= 1;

                    nstate = computePrice(state, nitems, action.payload.catalog, action.payload.location, action.payload.table);

                    return nstate;
                }
            }

            return {
                ...state,
            };

        case RESET_ITEMS:
            // Keep only already validated items
            const resetItems = state.order.items.filter((item) => item.update_id);

            // TODO: reset time ?

            // We assume that the sent total is correctly set
            // TODO: recompute if not ?
            const resetOrders = {
                ...state.order,
                total: state.order.sent_total ?? `0.00 ${getCurrency(state.order.total) ?? DEFAULT_CURRENCY}`,
                items: resetItems
            };

            nstate = {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    sending: false,
                },
                order: resetOrders
            };

            return nstate;

        case SET_INFO:
            return {
                ...state,
                account_id: action.payload.account_id,
                catalog_id: action.payload.catalog_id,
                location_id: action.payload.location_id,
                table_id: action.payload.table_id,
                order: {
                    ...state.order,
                    service_type: action.payload.service_type ? action.payload.service_type : SupportedServiceType.VIEW,
                    total: '0.00 ' + action.payload.currency,
                },
            };

        case SET_PAYMENT_TYPE:
            return {
                ...state,
                payment_type: action.payload,
            }

        case SEND_ORDER:

            let paymentType: PaymentType | undefined;
            switch (action.payload.type) {
                case SpecificPaymentType.LYRA_MARKETPLACE_DO_NOT_USE_SAVED_DATA:
                case SpecificPaymentType.LYRA_MARKETPLACE_FORCE_CONECS:
                    paymentType = PaymentType.LYRA_MARKETPLACE;
                    break;
                default:
                    paymentType = action.payload.type;
            }

            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    sending: true,
                    error: false,
                },
                payment_infos: undefined,
                payment_page: undefined,
                order: {
                    ...state.order,
                    payment_type: paymentType,
                },
            };

        case SEND_ORDER_SUCCESS:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    sending: false,
                    error: false,
                },
                order: action.payload.order,
                payment_infos: action.payload.paymentInfos,
                payment_page: action.payload.paymentPage,
            };

        case SEND_ORDER_ERROR:
            /**
             * clean tip charges form order if an error occurred
             */
            const charges = state.order.charges;
            const tipCharge = charges?.find(charge => charge.type === OrderChargeType.TIP && charge.contributor_id === state.order.user_id)
            let cleanedTipsCharges = charges
            let newTotalWithoutTipPrice = state.order.total
            if (tipCharge) {
                cleanedTipsCharges = charges?.filter(charge => charge.id !== tipCharge.id)
                newTotalWithoutTipPrice = substractMoney(state.order.total, tipCharge.price)
            }
            return {
                ...state,
                order: {
                    ...state.order,
                    charges: cleanedTipsCharges,
                    total: newTotalWithoutTipPrice
                },
                currentOrder: {
                    ...state.currentOrder,
                    sending: false,
                    error: true,
                    errorCode: action.payload.code ? action.payload.code : "",
                    errorMessage: action.payload.message ? action.payload.message : "",
                    errorValue: action.payload.value ? action.payload.value : null
                },
            };

        case RESET_ORDER_PAYMENTS:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    sending: false,
                    error: false,
                },
                payment_infos: undefined,
                payment_page: undefined,
            };

        case RESET_ORDER:
            nstate = {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    sending: false,
                    error: false,
                },
                order: {
                    ...orderInitialState.order,
                    service_type: state.order.service_type,
                    customer: state.order.customer,
                    // TODO: at the moment, we don't want to compute again the delivery & service_fee charges. Not a clean
                    // solution but it works for now.
                    charges: state.order.charges?.filter((c) => c.type === OrderChargeType.SERVICE_FEE || c.type === OrderChargeType.DELIVERY),
                    delivery_zone_ref: state.order.delivery_zone_ref,
                },
                displaySharedOrderPopup: false,
            };
            return nstate;

        case RESET_ORDER_ERROR:
            nstate = {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    error: false,
                    errorMessage: "",
                    errorCode: "",
                }
            };
            return nstate;

        case SET_ORDER:
            // Set the context for remote logs
            getMylemonadeContext().order_id = action.payload.order.id;
            getMylemonadeContext().service_type = action.payload.order.service_type;

            if (!action.payload.order.items || !Array.isArray(action.payload.order.items)) {
                action.payload.order.items = [];
            }
            log.debug(`Set order ${action.payload.order.display_id} with status ${action.payload.order.status}`);
            if (!action.payload.order.loyalty_config && orderService.isFullyEditable(action.payload.order)) {
                action.payload.order.loyalty_config = action.payload.location.loyalty;
            }

            // Replace order in latestorder if needed
            const updatedLatestOrders = { ...state.initOrders }
            const tableOrderIndex = updatedLatestOrders.latestTableOrders?.findIndex((order) => order.id === action.payload.order.id);
            if (tableOrderIndex > -1) {
                log.debug(`Replacing in table orders (index ${tableOrderIndex})`);
                updatedLatestOrders.latestTableOrders = [...updatedLatestOrders.latestTableOrders];
                updatedLatestOrders.latestTableOrders[tableOrderIndex] = action.payload.order;
            } else {
                updatedLatestOrders.latestTableOrders?.push(action.payload.order);
            }
            const userOrderIndex = updatedLatestOrders.latestUserOrders?.findIndex((order) => order.id === action.payload.order.id);
            if (userOrderIndex > -1) {
                log.debug(`Replacing in user orders (index ${userOrderIndex})`);
                updatedLatestOrders.latestUserOrders = [...updatedLatestOrders.latestUserOrders];
                updatedLatestOrders.latestUserOrders[userOrderIndex] = action.payload.order;
            } else {
                updatedLatestOrders.latestUserOrders?.push(action.payload.order);
            }

            nstate = {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    error: false,
                },
                order: action.payload.order,
                initOrders: updatedLatestOrders
            };


            if (orderService.orderNeedsPricing(nstate.order)) {
                return computePrice(nstate, nstate.order.items, action.payload.catalog, action.payload.location, action.payload.table);
            }
            else {
                return nstate;
            }

        case SET_PICKUP:
            return {
                ...state,
                order: {
                    ...state.order,
                    customer: {
                        ...state.order.customer,
                        ...action.payload.customerInfo
                    }
                },
            };

        case SET_EXPECTED_TIME: {

            const updatedStateExpectedDate = {
                ...state,
                order: {
                    ...state.order,
                },
            };

            if (action.payload.expectedTime) {

                const expectedTime = new Date(action.payload.expectedTime);
                updatedStateExpectedDate.order.expected_time = expectedTime;
                if (action.payload.endPreparationTime) {
                    updatedStateExpectedDate.order.end_preparation_time = new Date(action.payload.endPreparationTime);
                }

                // Sometimes (with Uber for example), we want to update the delivery delay from an estimated one to a real one.
                // The real one is obtained by the difference between the expected time and the end preparation time.
                // If you want to update the 2 times in function of the delivery delay, no problem its value should not change.
                if (
                    state.order.service_type === SupportedServiceType.DELIVERY
                    && updatedStateExpectedDate.order.delivery
                    && action.payload.endPreparationTime
                ) {
                    updatedStateExpectedDate.order.delivery.delivery_delay = Math.round((action.payload.expectedTime.getTime() - action.payload.endPreparationTime.getTime()) / 60000);;
                }
            }

            // If the property is not undefined (true or false)
            if (!_.isNil(action.payload.expectedTimeAsap)) {
                updatedStateExpectedDate.order.expected_time_asap = action.payload.expectedTimeAsap;
            }
            return updatedStateExpectedDate;
        }

        case ADD_DEAL:

            log.debug("Order reducer > ADD_DEAL", { orderFromState: _.cloneDeep(state.order) });

            const allItems: OrderItem[] = [...state.order.items];

            // If at list one item and it's a deal
            if (action.payload.dealItems && action.payload.dealItems.length) {
                const invalidItem = action.payload.dealItems.some((dealItem) => !dealItem.deal_line || !dealItem.deal_line.deal_key)

                if (!invalidItem) {
                    // Get the deal key to be affected to this new del
                    let currentMaxDealKey: number = -1
                    if (state.order.deals) {
                        for (let existingDealKey in state.order.deals) {
                            const dealKeyNumber = parseInt(existingDealKey);
                            if (!isNaN(dealKeyNumber) && dealKeyNumber > currentMaxDealKey) {
                                currentMaxDealKey = dealKeyNumber;
                            }
                        }
                    }
                    currentMaxDealKey = currentMaxDealKey + 1;
                    const dealKey = currentMaxDealKey.toString();;

                    action.payload.dealItems.forEach((dealItem) => {
                        dealItem.deal_line!.deal_key = dealKey;
                    })
                    // Other info filled by orderPrice
                    state.order.deals[dealKey] = {
                        ref: action.payload.dealRef,
                        name: "",
                        price: `0.00 ${action.payload.catalog.currency}`,
                        contributor_user_id: action.payload.userId,
                    }

                    // Add deal items to existing order items
                    allItems.push(...action.payload.dealItems);
                    log.debug("Order reducer > ADD_DEAL > ITEMS & ORDER", { allItems: _.cloneDeep(allItems), orderFromState: _.cloneDeep(state.order) });
                }

                try {

                    nstate = computePrice(state, allItems, action.payload.catalog, action.payload.location, action.payload.table);

                    log.debug("Submit order state", _.cloneDeep(nstate));
                    return nstate;
                }
                catch (error) {

                    if (error === OrderError.RESTRICTIONS_MIN_ORDER_AMOUNT) {

                        log.error(error);
                    }
                }
            } else {
                log.error(`Invalid submitted deal items`, action.payload.dealItems);
            }


            return state;

        case EDIT_DEAL:
            log.debug("Order reducer > EDIT_DEAL", { orderFromState: _.cloneDeep(state.order) });

            const orderItems: OrderItem[] = [...state.order.items];

            const alreadyExistingDealKey = action.payload.dealItems.every((dealItem) => (
                dealItem.deal_line?.deal_key
                && Object.keys(state.order.deals).includes(dealItem.deal_line.deal_key)
            ));

            if (alreadyExistingDealKey) {
                action.payload.dealItems.forEach((dealItem) => {
                    const foundItemIndex = state.order.items.findIndex((item) => (
                        item.deal_line?.deal_key === dealItem.deal_line?.deal_key
                        && item.deal_line?.deal_line_index === dealItem.deal_line?.deal_line_index
                    ));
                    if (foundItemIndex > -1) {
                        orderItems[foundItemIndex] = dealItem;
                    }
                })
            }

            try {

                nstate = computePrice(state, orderItems, action.payload.catalog, action.payload.location, action.payload.table);

                log.debug("Submit order state", _.cloneDeep(nstate));
                return nstate;
            }
            catch (error) {

                if (error === OrderError.RESTRICTIONS_MIN_ORDER_AMOUNT) {

                    log.error(error);
                }
            }

            return state;


        case REMOVE_DEAL:
            const newOrder = _.cloneDeep(state.order);
            delete newOrder.deals[action.payload.dealKey]

            return {
                ...state,
                order: newOrder,
            }

        case SET_CUSTOMER_NOTES:
            return {
                ...state,
                order: {
                    ...state.order,
                    customer_notes: action.payload.customer_notes ? action.payload.customer_notes : orderInitialState.order.customer_notes
                }
            }

        case UPDATE_LOYALTY_POINTS_USAGE:
            const updateLoyaltyPointsUsageAction = action as UpdateLoyaltyPointsUsageAction;
            const { userId, usePoints } = updateLoyaltyPointsUsageAction.payload;
            const updatedContributors = state.order.contributors ? { ...state.order.contributors } : {};
            if (!updatedContributors[userId]) {
                updatedContributors[userId] = {};
            }
            updatedContributors[userId].use_points = usePoints;
            const newOrderState = {
                ...state,
                order: {
                    ...state.order,
                    contributors: updatedContributors
                }
            }
            if (!orderService.isFullyEditable(newOrderState.order)) {
                return computePrice(newOrderState, newOrderState.order.items, action.payload.catalog, action.payload.location, action.payload.table, true);
            }
            else {
                return newOrderState;
            }

        case SET_CHARGE:
            nstate = state;
            if (action.payload.charge) {

                if (!nstate.order.charges) {

                    nstate.order.charges = [action.payload.charge];
                }
                else {

                    // If false, we push. If true, we just change the charge
                    let typeFound = false;

                    /**
                     * We iterate trough the charges. If we find the type, we replace the charge only if it's not a charg of typ TIP. If 
                     * not found, we push the charge
                     */
                    nstate.order.charges = nstate.order.charges.map((elem: OrderCharge) => {

                        if (elem.type === action.payload.charge.type && elem.type !== OrderChargeType.TIP) {

                            // We can change the charge because we know the type is a unique identifier
                            elem = action.payload.charge;

                            typeFound = true;
                        }

                        return elem;
                    });

                    if (!typeFound) {

                        nstate.order.charges.push(action.payload.charge);
                    }
                }
            }

            return computePrice(nstate, nstate.order.items, action.payload.catalog, action.payload.location, action.payload.table);

        case SET_DELIVERY_ZONE:
            return {
                ...state,
                order: {
                    ...state.order,
                    delivery_zone_ref: action.payload.ref,
                    delivery: {
                        zone_ref: action.payload.ref,
                        delivery_type: action.payload.provider_type,
                        status: DeliveryStatus.PENDING,
                        delivery_delay: action.payload.default_delivery_delay,
                    }
                }
            }

        case LOAD_ORDERS:
            return {
                ...state,
                initOrders: {
                    ...state.initOrders,
                    loading: true,
                    error: null,
                }
            }

        case LOAD_ORDERS_SUCCESS:

            return {
                ...state,
                initOrders: {
                    ...state.initOrders,
                    loading: false,
                    error: null,
                    latestUserOrders: action.payload.latestUserOrders,
                    latestTableOrders: action.payload.latestTableOrders,
                    connectorGetTableOpenedOrdersError: action.payload.connectorGetTableOpenedOrdersError ?? undefined,
                },
                currentOrder: {
                    ...state.currentOrder,
                    last_refreshed_at: new Date(),
                }
            }

        case LOAD_ORDERS_ERROR:
            return {
                ...state,
                initOrders: {
                    ...state.initOrders,
                    loading: false,
                    error: action.payload.orderError,
                },
            };

        case MERGE_ORDER:

            const mergedOrder = _.merge(state.order, action.payload);
            if (!mergedOrder.items || !Array.isArray(action.payload.items)) {
                mergedOrder.items = [];
            }
            return {
                ...state,
                order: mergedOrder
            };

        case ADD_CONTRIBUTOR_LOCAL:

            return {
                ...state,
                order: {
                    ...state.order,
                    contributors: state.order.contributors
                        ? {
                            ...state.order.contributors,
                            [action.payload.uid]: {
                                ...action.payload.user,
                                ...action.payload.patch ?? {},
                            }
                        }
                        : {
                            [action.payload.uid]: {
                                ...action.payload.user,
                                ...action.payload.patch ?? {},
                            }
                        }
                }
            }

        case REMOVE_CONTRIBUTOR_LOCALLY:

            const clonedOrder = _.cloneDeep(state.order);
            if (clonedOrder.contributors && clonedOrder.contributors[action.payload.uid]) {
                delete clonedOrder.contributors[action.payload.uid];
            }

            return {
                ...state,
                order: clonedOrder,
            }


        case SET_MASTER_USER:

            return {
                ...state,
                order: {
                    ...state.order,
                    master_user_uid: action.payload ?? undefined,
                }
            }

        case SET_STATUS_WAITING_SUBMISSION:

            return {
                ...state,
                order: {
                    ...state.order,
                    status: action.payload ? OrderStatus.WAITING_SUBMISSION : OrderStatus.DRAFT,
                }
            }

        case UPDATE_ITEM_NOTE:
            const { product_ref, sku_ref, note } = action.payload

            const triggeredProduct: OrderItem | undefined = state.order.items.find(item => item.product_ref === product_ref && item.sku_ref === sku_ref);
            if (triggeredProduct) {
                triggeredProduct.customer_notes = note
            }
            return state

        case ADD_DISCOUNT_TO_ORDER:
            const { discount, catalog, location, uid, table } = action.payload
            const order = state.order
            const tempOrderDiscount: OrderDiscount = {
                name: discount.name,
                ref: discount.ref,
                price_off: "",
                type: DiscountType.STANDARD_DISCOUNT,
                user_id: uid
            }
            if (!order.discounts?.length) {
                order.discounts = [tempOrderDiscount]
            } else {
                order.discounts.push(tempOrderDiscount)
            }
            const nState = {
                ...state,
                order: order
            }
            return computePrice(nState, nState.order.items, catalog, location, table);

        case UPDATE_CONTRIBUTOR: {

            const newContributor = action.payload.contributors[action.payload.uid];
            if (!newContributor) {
                return state;
            }

            return {
                ...state,
                order: {
                    ...state.order,
                    contributors: {
                        ...state.order.contributors,
                        [action.payload.uid]: newContributor,
                    }
                }
            }
        }

        case SET_ITEMS_TO_PAY:
            return {
                ...state,
                items_to_pay: action.payload.itemsToPay
            }

        case EARN_LOYALTY:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    sending: true,
                    error: false,
                },
            };

        case EARN_LOYALTY_SUCCESS:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    sending: false,
                    error: false,
                },
            };

        case EARN_LOYALTY_ERROR:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    sending: false,
                    error: true,
                    errorCode: action.payload.code ? action.payload.code : "",
                    errorMessage: action.payload.message ? action.payload.message : "",
                },
            };

        case REFRESH_ORDER_FROM_CONNECTOR: {
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    refreshing: true,
                }
            }
        }

        case REFRESH_ORDER_FROM_CONNECTOR_SUCCESS: {
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    refreshing: false,
                    last_refreshed_at: new Date(),
                }
            }
        }

        case REFRESH_ORDER_FROM_CONNECTOR_ERROR: {
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    refreshing: false,
                }
            }
        }

        case UPDATE_CURRENCY: {
            const updatedTotal = updateCurrencyOfMoney(state.order.total, action.payload.currency)

            return {
                ...state,
                order: {
                    ...state.order,
                    total: updatedTotal
                }
            }
        }

        default:
            return state;

    }
}
//export default orderReducers;


function computePrice(
    state: OrderState,
    allItems: OrderItem[],
    catalog: Catalog,
    location: Location,
    table: Table,
    spendingIncludeItemsWithUpdateId?: boolean,
) {

    const updatedOrder: OrderInBase = {
        ...state.order,
        items: allItems,
    };

    const nstate: OrderState = {
        ...state,
    };

    const order = updatedOrder;

    //TODO: what errors? 
    // Pricing the order only if it has some items, to avoid errors
    if (order.items.length || Object.keys(order.deals).length) {
        try {
            const orderPriceArg: OrderPriceArg = {
                order,
                catalog,
                location,
                table,
                disableExpectedTimeResetForEatin: false,
                checkMinAmount: false,
                patchOrder: true,
                doNotThrow: true,
                spendingIncludeItemsWithUpdateId,
            }

            orderPrice(orderPriceArg);
        }
        catch (error) {

            log.error('Error while computing price');
            log.error(error);

            return state;
        }
    }

    nstate.order = order;
    log.debug('orderComputedPrice', order)
    nstate.currentOrder = {
        ...nstate.currentOrder,
        error: false,
    }

    return nstate;
}
