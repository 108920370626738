import { ACCOUNT_PARAM, ACCOUNT_ROUTE, AccountRequestParams } from "../../accounts/configs/AccountsApiRoutes";
import { ROUTES_PREFIX_V1 } from "../../common/configs/CommonApiRoutes";
import MylemonadeScopes from "../../common/models/MyLemonadeScopes";

/**
 * Key used in route or query params
 */
export const LOCATION_KEY = "locationId";
export const FOR_DATE_KEY = "when";
export const SERVICE_TYPE_KEY = "serviceType";
export const DELIVERY_ZONE_KEY = "deliveryZone";
export const LOCATION_PARAM = `:${LOCATION_KEY}`;
export const LOCATION_REF_KEY = "locationRef";
export const LOCATION_REF_PARAM = `:${LOCATION_REF_KEY}`;
export const LOCATION_ANONYMOUS_KEY = "fyre_id";
export const LOCATION_ANONYMOUS_PARAM = `:${LOCATION_ANONYMOUS_KEY}`;

export const TABLE_LINK_KEY = "tablelinkId";
export const TABLE_LINK_KEYWORD = "tablelink";

export const LOCATIONS_CREATE_ROUTE = `${ACCOUNT_ROUTE}/${MylemonadeScopes.LOCATIONS}`;
export const LOCATIONS_IMPORT_ROUTE = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.LOCATIONS}/import`;
export const LOCATIONS_TRANSFORM_ROUTE = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.LOCATIONS}/transform`;
export const LOCATION_ROUTE = `${LOCATIONS_CREATE_ROUTE}/${LOCATION_PARAM}`;
export const LOCATION_PARTNERS_ROUTE = `${ROUTES_PREFIX_V1}/${MylemonadeScopes.LOCATIONS}/${LOCATION_ANONYMOUS_PARAM}`;
export const LOCATION_TIMESLOTS_ROUTE = `${LOCATION_ROUTE}/timeslots`;
export const LOCATION_BACKUPS_ROUTE = `${LOCATION_ROUTE}/backups-diffs`;
export const LOCATION_PRINT_ORDERS_SUMMARY = `${LOCATION_ROUTE}/print-orders-summary`;
export const LOCATION_PIN = `${LOCATION_ROUTE}/pin`;
export const RESET_TEST_LOCATION = `${ACCOUNT_ROUTE}/reset_tests_locations`;
export const CREATE_TEMPLATE_ACCOUNT = `${ACCOUNT_ROUTE}/create-template`;
export const GET_TEMPLATES = `${ACCOUNT_ROUTE}/get-templates`;

export type LocationRequestParams = AccountRequestParams & { [LOCATION_KEY]: string }

export type LocationPartnersRequestParams = { [LOCATION_ANONYMOUS_KEY]: string }

const locationsApiRoutes = {
    LOCATIONS_TRANSFORM_ROUTE,
    LOCATIONS_CREATE_ROUTE: LOCATIONS_CREATE_ROUTE,
    LOCATION_UPDATE_ROUTE: LOCATION_ROUTE,
    LOCATIONS_IMPORT_ROUTE: LOCATIONS_IMPORT_ROUTE,
    LOCATION_ROUTE: LOCATION_ROUTE,
    LOCATION_TIMESLOTS_ROUTE: LOCATION_TIMESLOTS_ROUTE,
    LOCATION_BACKUPS_ROUTE: LOCATION_BACKUPS_ROUTE,
    PRINT_ORDERS_SUMMARY: LOCATION_PRINT_ORDERS_SUMMARY,
    RESET_TEST_LOCATION: RESET_TEST_LOCATION,
    LOCATION_PIN: LOCATION_PIN,
    CREATE_TEMPLATE_ACCOUNT: CREATE_TEMPLATE_ACCOUNT,
    GET_TEMPLATES: GET_TEMPLATES,
    LOCATION_PARTNERS_ROUTE,
};
export default locationsApiRoutes;

export const getCreateLocationRoute = (accountId: string): string => {
    return LOCATIONS_CREATE_ROUTE.replace(ACCOUNT_PARAM, accountId);
}

export const getUpdateLocationRoute = (accountId: string, locationId: string): string => {
    return LOCATION_ROUTE.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId);
}

export const getPinLocationRoute = (accountId: string, locationId: string) => {
    return LOCATION_PIN.replace(ACCOUNT_PARAM, accountId).replace(LOCATION_PARAM, locationId);
}

export const getTemplatesNamesAndIdsRoute = (accountId: string) => {
    return GET_TEMPLATES.replace(ACCOUNT_PARAM, accountId)
}