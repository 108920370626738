import { Button, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DeviceContext } from '../../App';
import { AddButtonRedirect } from '../models/AddButtonRedirect';

interface EditButtonProps {
    onDealEdition: (redirect: AddButtonRedirect) => void;
    isButtonDisabled?: boolean;
}

const EditButton: React.FC<EditButtonProps> = (props) => {
    const {
        onDealEdition,
        isButtonDisabled,
    } = props;

    const intl = useIntl();
    const theme = useTheme();

    const { mobile_device } = useContext(DeviceContext);

    return (
        <Button
            variant="contained"
            color="primary"
            style={{
                wordWrap: "break-word",
                border: "thin",
                marginTop: theme.spacing(3),
                padding: theme.spacing(1.5, 2),
                width: mobile_device ? '100%' : "300px",
                margin: mobile_device ? "unset" : "auto",
                textTransform: "none",
            }}
            disabled={isButtonDisabled}
            onClick={() => onDealEdition(AddButtonRedirect.CATALOG)}
        >
            <Typography
                variant='h5'
                textTransform={'initial'}
            >
                {intl.formatMessage({ id: `order.edit` })}
            </Typography>
        </Button>
    )
}

export default EditButton;