import firebase, { firestore } from "firebase/app";
import "firebase/auth";
import _ from "lodash";
import { DateTime } from "luxon";
import { Task } from "redux-saga";
import { all, call, cancel, put, select, spawn, takeLatest } from "redux-saga/effects";
import { isUserLoggedIn } from "../../authentication/helpers/AuthenticationHelpers";
import { AuthenticationState } from "../../authentication/models/AuthenticationState";
import AuthenticationActions from "../../authentication/redux/AuthenticationActions";
import { getIdToken } from "../../authentication/services/AuthenticationService";
import { getDeviceDetectInfo } from "../../Common/helper/DeviceHelper";
import { loadAndSelectAvailableTimeSlots } from "../../Common/helper/Timeslots";
import { ErrorPageType } from "../../Common/models/ErrorPageType";
import { RedirectUrlFrom, RedirectUrlType } from "../../Common/models/RedirectUrlModels";
import TimeslotsAvailable from "../../Common/models/TimeslotsAvailable";
import { CommonActions } from "../../Common/redux/CommonActions";
import log from "../../Common/services/LogService";
import { auth, db, rsf } from "../../config/firebase";
import * as ROUTES from '../../config/routes';
import { getApiEndpoint } from "../../config/variables";
import { CustomerInformationModalFormToDisplay } from "../../customers/models/CustomerInformationModalFormToDisplay";
import { CustomerInformationModalActions } from "../../customers/redux/CustomerInformationModalActions";
import LocationState from "../../Locations/models/LocationState";
import { locationActions } from "../../Locations/redux/LocationActions";
import { removeUndefinedFromObject } from "../../my-lemonade-library/functions/Helpers";
import { Catalog, getTimezoneName } from "../../my-lemonade-library/model/Catalog";
import { CatalogExtended } from "../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import { Location, SupportedServiceType, Table } from "../../my-lemonade-library/model/Location";
import { Order, OrderCharge, OrderDeal, OrderInBase, OrderItem, OrderStatus } from "../../my-lemonade-library/model/Order";
import { OrderError } from "../../my-lemonade-library/model/OrderError";
import { ACCOUNT_KEY } from "../../my-lemonade-library/src/accounts/configs/AccountsApiRoutes";
import { Customer } from "../../my-lemonade-library/src/authentications/models/Customer";
import { getCustomerFirestoreDocPath } from "../../my-lemonade-library/src/authentications/service/AuthenticationService";
import { CATALOG_KEY } from "../../my-lemonade-library/src/catalogs/configs/CatalogApiRoutes";
import { HttpErrorResponse } from "../../my-lemonade-library/src/common/models/HttpError";
import { getCurrency } from "../../my-lemonade-library/src/common/models/Money";
import { getErrorMessage, getErrorStack } from "../../my-lemonade-library/src/common/services/LogService";
import { ConnectorGetTableOpenedOrdersError } from "../../my-lemonade-library/src/connectors/models/ConnectorGetTableOpenedOrdersError";
import { ONEBOX_BOXID_KEY } from "../../my-lemonade-library/src/connectors/models/ConnectorsParams";
import OrderDeliveryInfos from "../../my-lemonade-library/src/delivery/models/OrderDeliveryInfos";
import { OrderDiscount } from "../../my-lemonade-library/src/discounts/models/OrderDiscount";
import { LOCATION_KEY } from "../../my-lemonade-library/src/locations/configs/LocationsApiRoutes";
import EarnOrderLoyaltyResponse from "../../my-lemonade-library/src/loyalties/models/EarnOrderLoyaltyResponse";
import { LoyaltyToggleUsageRequest } from "../../my-lemonade-library/src/loyalties/models/LoyaltyToggleUsageRequest";
import { LoyaltyToggleUsageResponse } from "../../my-lemonade-library/src/loyalties/models/LoyaltyToggleUsageResponse";
import ordersApiRoutes, { ORDERS_FETCH_TABLE, ORDER_BY_RECEIPT_REF_KEY, ORDER_ID_PARAM } from "../../my-lemonade-library/src/orders/configs/OrdersApiRoutes";
import { WebappTableOrders } from "../../my-lemonade-library/src/orders/models/LocationOrdersConfig";
import { OrderAddContributorResponse } from "../../my-lemonade-library/src/orders/models/OrderAddContributorResponse";
import OrderAddItemsRequest from "../../my-lemonade-library/src/orders/models/OrderAddItemsRequest";
import OrderAddItemsResponse from "../../my-lemonade-library/src/orders/models/OrderAddItemsResponse";
import OrderCreateRequest from "../../my-lemonade-library/src/orders/models/OrderCreateRequest";
import OrderCreateResponse from "../../my-lemonade-library/src/orders/models/OrderCreateResponse";
import { OrderPriceArg } from "../../my-lemonade-library/src/orders/models/OrderPriceArg";
import OrderUpdateReason from "../../my-lemonade-library/src/orders/models/OrderUpdateReason";
import TableOpenedOrdersRequest from "../../my-lemonade-library/src/orders/models/TableOpenedOrdersRequest";
import TableOpenedOrdersResponse from "../../my-lemonade-library/src/orders/models/TableOpenedOrdersResponse";
import { orderPrice } from "../../my-lemonade-library/src/orders/services/OrderPricing";
import { getOrderFirestoreDocPath, getOrdersFirestoreCollectionPath, orderService } from "../../my-lemonade-library/src/orders/services/OrderService";
import { PAYMENT_ID_PARAM_KEY } from "../../my-lemonade-library/src/payments/configs/PaymentsApiRoutes";
import { SESSION_ID_HEADER } from "../../my-lemonade-library/src/sessions/services/SessionService";
import { TABLE_KEY } from "../../my-lemonade-library/src/tables/configs/TableApiRoutes";
import paymentService from "../../Payment/services/PaymentService";
import { RootState } from "../../redux/root-reducer";
import TableActions from "../../tables/redux/TableActions";
import { ORDER_ID_QUERY_PARAM_NAME, ORDER_REF_QUERY_PARAM_NAME } from "../configs/OrdersRouterConfig";
import { getLatestTableDWPOrder, getLatestUserDWPOrder, getOrderInLatestOrdersById } from "../helpers/ChoseOrderAndApplyLogicHelpers";
import { getFabOrderPriceWithoutDiscountCharges } from "../helpers/FabOrderHelpers";
import { isLocalLoyaltyOnly } from "../helpers/LoyaltyHelpers";
import { isOrderStatusDWP, needsToCreateDraftAfterReset } from "../helpers/OrderHelpers";
import { ListItemsToAdd } from "../models/ListItemsToAdd";
import SetOrderItems from "../models/SetOrderItems";
import { updateDateFieldsInFirestoreOrder } from "../services/OrderService";
import { OrderState } from "./models/OrderState";
import actions, { ADD_CONTRIBUTOR, ADD_DEAL, ADD_DISCOUNT_TO_ORDER, ADD_ITEM, ADD_ITEMS, AddContributorAction, AddDealAction, AddDiscountToOrderAction, AddItemAction, AddItemsAction, CLOSE_MODAL, CREATE_DRAFT_ORDER, CloseModalAction, CreateDraftOrderAction, EARN_LOYALTY, EDIT_DEAL, EarnLoyaltyAction, EditDealAction, LOAD_AVAILABLE_TIMESLOTS, LOAD_ORDERS, LoadAvailableTimeSlotsAction, LoadOrdersAction, MERGE_ORDER, MergeOrderAction, REFRESH_ORDER_FROM_CONNECTOR, REMOVE_DEAL, REMOVE_DISCOUNT_FROM_ORDER, REMOVE_ITEM, RESET_ITEMS, RESET_ORDER, RefreshOrderFromConnectorAction, RemoveDealAction, RemoveDiscountFromOrderAction, RemoveItemAction, ResetItemsAction, ResetOrderAction, SEND_ADDED_ITEMS, SEND_ORDER, SETUP_SYNC_CUSTOMER, SET_CHARGE, SET_CUSTOMER_INFO, SET_CUSTOMER_NOTES, SET_DELIVERY_ZONE, SET_EXPECTED_TIME, SET_LOYALTY_USER_ID, SET_MASTER_USER, SET_ORDER, SET_PICKUP, SET_STATUS_WAITING_SUBMISSION, SYNC_CUSTOMER, SYNC_ORDER, SendAddedItemsAction, SendOrderAction, SetChargeAction, SetCustomerInformationAction, SetCustomerNotesAction, SetDeliveryZoneAction, SetExpectedTimeAction, SetLoyaltyUserIdAction, SetMasterUserAction, SetOrderAction, SetPickupAction, SetStatusWaitingSubmissionAction, SetupSyncCustomerAction, SyncCustomerAction, SyncOrderAction, UPDATE_CONTRIBUTOR, UPDATE_ITEM_NOTE, UPDATE_LOYALTY_POINTS_USAGE, UpdateContributorAction, UpdateItemNoteAction, UpdateLoyaltyPointsUsageAction, orderActions } from "./OrderActions";

function* loadAvailableTimeSlots(action: LoadAvailableTimeSlotsAction) {

    const { selectedTable, selectedLocation, selectedCatalog } = (yield select((state: RootState) => state.locations)) as LocationState;

    if (selectedLocation && selectedCatalog) {
        try {
            let dateTime = action.payload.forDate;
            const dateNow = DateTime.now().setZone(getTimezoneName(selectedCatalog));
            if (dateNow.startOf('day').toSeconds() === dateTime.startOf('day').toSeconds()) {
                // Use now to take into account current hour and not allow timeslots before now
                dateTime = dateNow;
            }
            const timeslotsAvailable: TimeslotsAvailable = yield call(loadAndSelectAvailableTimeSlots, undefined, selectedLocation, selectedCatalog, selectedTable, action.payload.serviceType, action.payload.deliveryZoneRef, dateTime);
            yield put(orderActions.loadAvailableTimeSlotsSuccess(timeslotsAvailable))
        } catch (error) {
            let errorMessage = "Unknown error";
            if (error instanceof Error) {
                errorMessage = error.message;
            }
            log.error(`Error loading timelots: ${errorMessage}`)
            log.error(error);
            yield put(orderActions.loadAvailableTimeSlotsError())
        }
    }
}

/**
 * Load the latest orders, and the orderByRef or orderById in the redux state.
 * Also trigger the choseOrderAndApplyLogic function.
 * 
 * The number of latest orders to load is defined by the params "userOrdersLimit" and "tableOrdersLimit".
 * The tableOrders are either fetched from the API or fetched from firestore directly. This
 * behavior is controlled by the setting allow_fetch_external_table_orders of the location.
 * @param action 
*/
export function* loadOrders(action: LoadOrdersAction) {

    let loadOrdersError: OrderError | undefined = undefined;
    const tableOrders: OrderInBase[] = [];
    const userOrders: OrderInBase[] = [];

    let connectorGetTableOpenOrdersError: ConnectorGetTableOpenedOrdersError | undefined = undefined;

    const orderRefUrl = action.payload.requestParams?.get(ORDER_REF_QUERY_PARAM_NAME);
    const orderIdUrl = action.payload.requestParams?.get(ORDER_ID_QUERY_PARAM_NAME);

    /////////////////
    // LATEST ORDERS
    /////////////////

    const { selectedTable: selectedTableFromState, selectedLocation: selectedLocationFromState,
        selectedCatalog: selectedCatalogFromState, sessionId
    } = (yield select((state: RootState) => state.locations)) as LocationState;
    const user = auth.currentUser;

    // Either those objects are put in the payload, or fetched from the state.
    let selectedTable = action.payload.table ?? selectedTableFromState;
    const selectedCatalog = action.payload.catalog ?? selectedCatalogFromState;
    const selectedLocation = action.payload.location ?? selectedLocationFromState;

    let accountId = action.payload.acountId;
    if (!accountId && selectedLocation) {
        accountId = selectedLocation.account_id;
    }
    let locationId = action.payload.locationId;
    if (!locationId && selectedLocation) {
        locationId = selectedLocation.id;
    }

    let tableId = action.payload.tableId;
    if (!tableId && selectedTable) {
        tableId = selectedTable.id;
    }

    let catalogId = action.payload.catalogId;
    if (!catalogId && selectedCatalog && selectedCatalog.id) {
        catalogId = selectedCatalog.id;
    }

    let location = action.payload.location;
    if (!location && selectedLocation) {
        location = selectedLocation;
    }

    try {

        const ordersCollectionRef = db.collection(getOrdersFirestoreCollectionPath(accountId!, locationId!));

        // Get user orders
        if (user && action.payload.userOrdersLimit > 0) {

            const foundOrders: OrderInBase[] = [];

            // Step 1: look for orders with identical order.user_id
            const userOrdersCollectionRef = ordersCollectionRef
                .where("user_id", "==", user.uid)
                .orderBy("created_at", "desc")
                .limit(action.payload.userOrdersLimit) as firebase.firestore.CollectionReference; // Hack for typescript due to ReduxSagaFirebase
            const userOrdersSnap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = yield call(rsf.firestore.getCollection, userOrdersCollectionRef);
            userOrdersSnap.forEach((snap) => {
                const order = snap.data() as OrderInBase;
                foundOrders.push(order);
            });

            // Step 2: look for orders with old_anonymous_ids containing the current one
            const anonymousOrdersCollectionRef = ordersCollectionRef
                .where("customer.old_anonymous_ids", "array-contains", user.uid)
                .orderBy("created_at", "desc")
                .limit(action.payload.userOrdersLimit) as firebase.firestore.CollectionReference; // Hack for typescript due to ReduxSagaFirebase
            const anonymousOrdersSnap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = yield call(rsf.firestore.getCollection, anonymousOrdersCollectionRef);
            anonymousOrdersSnap.forEach((snap) => {
                const order = snap.data() as OrderInBase;
                foundOrders.push(order);
            });

            // De-duplicate, convert dates and push to userOrders array
            foundOrders.forEach((order) => {

                if (userOrders.find(uo => uo.id === order.id)) {
                    return;  // Already added
                }

                // Convert all times
                updateDateFieldsInFirestoreOrder(order, location!);
                userOrders.push(order);
            });
        }

        if (action.payload.tableOrdersLimit > 0 && tableId) {

            // We say it's errored, and then when the result will come properly we'll
            // turn it to false. 
            let errOrSkippedFetchApi: boolean = true;

            // Fetch from the API
            if (location?.orders?.allow_fetch_external_table_orders) {

                if (catalogId && locationId && accountId) {

                    const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);

                    const headers = new Headers();
                    headers.append("Content-Type", "application/json");
                    headers.append("Authorization", `Bearer ${tokenResult.token}`);
                    sessionId && headers.append(SESSION_ID_HEADER, sessionId);

                    const fetchTableLatestOrdersRequest: TableOpenedOrdersRequest = {
                        account_id: accountId,
                        catalog_id: catalogId,
                        location_id: locationId,
                        table_id: tableId,
                        limit: action.payload.tableOrdersLimit,
                    }

                    if (orderRefUrl) {
                        // Check if boxId
                        const boxId = action.payload.requestParams?.get(ONEBOX_BOXID_KEY);
                        if (boxId) {
                            // If box id, set the request parameters in order to identify the table from the box receipt
                            fetchTableLatestOrdersRequest.receipt_ref = orderRefUrl;
                            fetchTableLatestOrdersRequest.box_id = boxId;
                        }
                    }

                    const raw = JSON.stringify({ ...fetchTableLatestOrdersRequest });
                    log.debug(`Sending table latestOrders request`, raw);

                    const requestOptions: any = {
                        method: "POST",
                        headers: headers,
                        body: raw,
                    };

                    const apiTableLatestUrl = `${getApiEndpoint()}${ORDERS_FETCH_TABLE}`;

                    //@ts-ignore: error TS7057 'yield' expression implicitly results in an 'any' type
                    const response = yield fetch(apiTableLatestUrl, requestOptions);

                    if (!response.ok) {

                        log.error(response);

                        let errResult: string = yield response.text();
                        log.error(errResult);

                        throw new Error(errResult);
                    }

                    const result: TableOpenedOrdersResponse = yield response.json();

                    if (result) {
                        // If the table is not the same one, change table
                        if (result.table && result.table.ref !== selectedTable.ref) {
                            // Keep the service type from the original table
                            result.table.service_type = selectedTable?.service_type;
                            selectedTable = result.table;
                            tableId = selectedTable?.id;
                            yield put(locationActions.setTable(selectedTable));
                        }

                        result.orders.forEach((order) => {

                            // Convert the expected time to a JS Date
                            updateDateFieldsInFirestoreOrder(order, location!);

                            tableOrders.push(order);
                        });

                        connectorGetTableOpenOrdersError = result.errorMessage;

                        errOrSkippedFetchApi = false;

                    }
                }

            }
            // Else or if there was an error fetching from the API, fetch from firestore
            if (errOrSkippedFetchApi) {

                const tableOrdersCollectionRef = ordersCollectionRef.where("table_id", "==", tableId).orderBy("created_at", "desc").limit(action.payload.tableOrdersLimit) as firebase.firestore.CollectionReference; // Hack for typescript due to ReduxSagaFirebase
                const latestTableOrderSnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> = yield call(rsf.firestore.getCollection, tableOrdersCollectionRef);
                latestTableOrderSnapshot.forEach((snap) => {
                    const order = snap.data() as OrderInBase;

                    // Convert the expected time to a JS Date
                    updateDateFieldsInFirestoreOrder(order, location!);

                    tableOrders.push(order);
                });
            }
        }

        priceLatestOrders(userOrders, tableOrders, selectedCatalog, location!, selectedTable);
    }
    catch (error) {
        log.error("Error while fetching latest orders", error);
        error = OrderError.CANNOT_LOAD_LATEST_ORDERS.withValue({ error });
    }

    /////////////////
    // ORDER URL PICKING
    /////////////////

    let loadedOrderByRef: OrderInBase | null = null;
    let loadedOrderById: OrderInBase | null = null;

    /////////////////
    // ORDER BY REF
    // Do not load by ref if a connector is connected and 
    // Always use the connector in this case
    /////////////////

    if (orderRefUrl && !location?.orders?.allow_fetch_external_table_orders) {

        const orderRef = orderRefUrl;

        try {

            if (!selectedLocation || !selectedCatalog || !selectedCatalog.id || !action.payload.requestParams) {
                throw new Error(`Null location ${selectedLocation?.id} or catalog ${selectedCatalog?.id}, cannot fetch order by ref`);
            }

            log.info(`Fetching order with ref ${orderRef}`);

            loadedOrderByRef = yield call(fetchOrderByRefAPICall,
                orderRef,
                action.payload.requestParams,
                selectedLocation.account_id,
                selectedLocation.id,
                selectedCatalog.id,
                selectedTable.id,
                sessionId,
            );
            if (loadedOrderByRef) {
                updateDateFieldsInFirestoreOrder(loadedOrderByRef, selectedLocation);
            }
        }
        catch (error) {
            log.error(error);
            log.error("Error while fetching the order by its ref", { orderRef });
            loadOrdersError = OrderError.CANNOT_LOAD_ORDER_BY_REF.withValue({ error, payload: action.payload });
        }
    }

    /////////////////
    // ORDER BY ID
    /////////////////

    else if (orderIdUrl) {

        try {

            // search in both tableOrders and userOrders, and pick the first order with a matching ID
            let orderByIdPicked = getOrderInLatestOrdersById(
                orderIdUrl,
                selectedTable,
                selectedCatalog,
                userOrders,
                tableOrders,
                true,
            );

            if (!orderByIdPicked) {

                if (selectedLocation) {

                    log.info(`Order ${orderIdUrl} not found in latest orders, loading it from db (location: ${selectedLocation.id}, account: ${selectedLocation.account_id})`);

                    const orderPath = getOrderFirestoreDocPath(selectedLocation.account_id, selectedLocation.id, orderIdUrl);
                    const orderPickedSnap: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData> = yield call(rsf.firestore.getDocument, orderPath);
                    if (orderPickedSnap.exists) {
                        orderByIdPicked = orderPickedSnap.data() as OrderInBase;
                        orderByIdPicked.location_id = selectedLocation.id;
                        orderByIdPicked.account_id = selectedLocation.account_id;
                        updateDateFieldsInFirestoreOrder(orderByIdPicked, selectedLocation!);
                        log.info(`Order ${orderIdUrl} correctly loaded from db`);
                    }
                    else {
                        throw OrderError.CANNOT_LOAD_ORDER_BY_ID.withValue({
                            message: `Order ${orderIdUrl} does not exist in db (location: ${selectedLocation.id}, account: ${selectedLocation.account_id}`,
                            payload: action.payload,
                        });
                    }
                }
                else {
                    throw OrderError.CANNOT_LOAD_ORDER_BY_ID.withValue({
                        message: `selectedLocation is undefined, cannot fetch order from db`,
                        payload: action.payload,
                    });
                }
            }

            if (orderByIdPicked) {
                loadedOrderById = orderByIdPicked;
            }

        }
        catch (error) {
            log.error(error);
            loadOrdersError = error as OrderError;
        }
    }

    /////////////////
    // FINALLY
    /////////////////

    if (loadOrdersError) {

        yield put(actions.loadOrdersError(loadOrdersError));
        return;
    }

    // Start the chosing process
    yield call(choseOrderAndApplyLogic,
        userOrders,
        tableOrders,
        loadedOrderByRef,
        loadedOrderById,
        action.payload.allowLoadOrder,
        user?.uid,
        selectedLocation,
        selectedCatalog,
        selectedTable,
        orderRefUrl,
        orderIdUrl
    );

    yield put(actions.loadOrdersSuccess(userOrders, tableOrders, connectorGetTableOpenOrdersError))

    return { table: selectedTable, userOrders: userOrders, tableOrders: tableOrders };
}

const priceLatestOrders = (
    userOrders: OrderInBase[],
    tableOrders: OrderInBase[],
    catalog: Catalog | undefined,
    location: Location,
    table: Table,
) => {

    const priceLatestOrdersArray = (orderArray: OrderInBase[]) => {

        let indexesToDelete: number[] = [];

        orderArray.forEach((elem, index) => {

            try {
                if (catalog && orderService.orderNeedsPricing(elem)) {
                    const orderPriceArg: OrderPriceArg = {
                        order: elem,
                        catalog,
                        location,
                        table,
                        disableExpectedTimeResetForEatin: false,
                        checkMinAmount: false,
                        patchOrder: true,
                        doNotThrow: true,
                    }
                    orderPrice(orderPriceArg);
                }
            }
            catch (err) {

                //Remove it from the array
                indexesToDelete.push(index);
                log.info(`latestOrders: pricing error for order ${elem.display_id}, removing it from the array`, err)
            }
        });

        // Remove the values
        for (var i = indexesToDelete.length - 1; i >= 0; i--) {
            orderArray.splice(indexesToDelete[i], 1);
        }
    }

    priceLatestOrdersArray(userOrders);
    priceLatestOrdersArray(tableOrders);
}

function* setOrderToStateAndAddContributor(
    order: OrderInBase,
    allowLoadingOrder: boolean,
    userUID: string | undefined,
    selectedLocation: Location | undefined,
    selectedCatalog: CatalogExtended | undefined,
    selectedTable: Table | undefined
) {

    if (allowLoadingOrder) {

        if (selectedCatalog && selectedLocation && selectedTable) {

            log.info(`>-A-> Loading the order ${order.display_id} (${order.id})`);
            yield put(actions.setOrder(order, selectedCatalog, selectedLocation, selectedTable));
        }
        else {

            log.info(`>-A-> Cannot load order: missing catalog or location or table`);
        }

        if (userUID && (!order.contributors || !order.contributors[userUID]) && orderService.isFullyEditable(order)) {
            log.info(`>-A-> Adding user as a contributor`);
            yield put(actions.addContributor(userUID, { uid: userUID }, selectedLocation, selectedCatalog, selectedTable));
        }
    }
    else {

        log.info(`>-A-> Loading an order is disabled by the action caller`);
    }
}

/**
 * This function is triggered when the latestOrders + orderById and orderByRef are fully loaded.
 * It picks an order following some conditions described in a flowchart
 * (see README), and then applies logic on it (ex: loading it, redirecting, ...)
 * @param userOrders 
 * @param tableOrders 
 */
function* choseOrderAndApplyLogic(
    userOrders: OrderInBase[],
    tableOrders: OrderInBase[],
    loadedOrderByRef: OrderInBase | null,
    loadedOrderById: OrderInBase | null,
    allowLoadingOrder: boolean,
    userUID: string | undefined,
    selectedLocation: Location | undefined,
    selectedCatalog: CatalogExtended | undefined,
    selectedTable: Table | undefined,
    orderByRefUrl: string | null | undefined,
    orderByIdUrl: string | null | undefined
) {

    log.debug(">-A-> Starting the order picking and loading logic", {
        loadedOrderById,
        loadedOrderByRef,
        allowLoadingOrder,
        userUID,
    });

    if (selectedTable) {

        let orderPicked: OrderInBase | undefined = undefined;

        if (loadedOrderByRef) {
            orderPicked = loadedOrderByRef;
        }
        else if (loadedOrderById) {
            orderPicked = loadedOrderById;
        }
        else {

            log.debug(`>-A-> Selected location ${selectedLocation?.id} webapp table (${selectedTable?.id}: ${selectedTable?.service_type}) orders: ${selectedLocation?.orders?.webapp_table_orders}`);
            if (
                selectedLocation
                && selectedCatalog
                && selectedLocation?.orders?.webapp_table_orders
                && selectedLocation?.orders?.webapp_table_orders === WebappTableOrders.FORCE_LOAD
                // Restrictions on the service type
                && (
                    selectedTable.service_type === SupportedServiceType.EAT_IN
                    || (
                        (
                            selectedTable.service_type === SupportedServiceType.CHECKOUT
                            || selectedTable.service_type === SupportedServiceType.VIEW
                        )
                        && selectedLocation?.orders?.allow_online_payment_for_opened_orders
                    )
                )
            ) {

                // search in tableOrders and pick the most recent editable order
                orderPicked = getLatestTableDWPOrder(
                    selectedLocation,
                    tableOrders,
                    selectedTable,
                    selectedCatalog,
                );
            }
            else {

                // search in userOrders and pick the last order if it's a draft
                // Do not search for old drafts if a completed order has been submitted after
                orderPicked = getLatestUserDWPOrder(
                    selectedTable,
                    selectedCatalog,
                    userOrders,
                );
            }
        }

        // We want to check if the order has been created/updated for the last 6 hours
        // If it's not the case we ignore it and create a new one (except for validated ones, i.e not DWP or if we want a specific order by ref or id)
        // We don't want to load an old order that has been created/updated a long time ago
        if (
            orderPicked
            && (orderPicked.updated_at || orderPicked.created_at)
            && isOrderStatusDWP(orderPicked.status)
            && !orderByRefUrl
            && !orderByIdUrl
        ) {
            const timezone = getTimezoneName(selectedCatalog);

            const orderDate = DateTime.fromJSDate(orderPicked.updated_at ? orderPicked.updated_at : orderPicked.created_at).setZone(timezone);
            const now = DateTime.now().setZone(timezone);

            const diffInHours = now.diff(orderDate, 'hours').hours;
            const numberOfMaxHours = 3;

            if (
                !orderDate
                || diffInHours > numberOfMaxHours
            ) {
                log.warn(`>-A-> The order picked ${orderPicked?.id} (${orderDate}) has not been created/updated today, ignore it for eatin service and create a new one`);
                orderPicked = undefined;
            }
        }

        // an order has been picked
        if (orderPicked) {

            log.info(`>-A-> The order ${orderPicked.id} (status: ${orderPicked.status}) is going to be loaded`);

            // Set the service type only if orderId is in the query parameter
            if (orderPicked && selectedCatalog && selectedTable.service_type !== SupportedServiceType.CHECKOUT) {

                if (orderPicked.items && orderPicked.items.length > 0) {
                    log.debug(`>-A-> Setting the service_type ${orderPicked.service_type} to the table ${selectedTable.id}`);
                    yield put(TableActions.applySelectServiceType(selectedCatalog, orderPicked.service_type, selectedTable.area_ref));
                    selectedTable.service_type = orderPicked.service_type;
                } else {
                    log.info(`>-A-> The order is empty, we don't apply the service type to give the possibility to choose it if needed`);
                }

            }

            if (
                (
                    orderPicked.service_type === SupportedServiceType.DELIVERY
                    || orderPicked.service_type === SupportedServiceType.COLLECTION
                )
                && orderPicked.expected_time
                && orderPicked.expected_time <= new Date() // TODO: compare to new closest expected time
            ) {
                delete orderPicked.expected_time;
                delete orderPicked.expected_time_asap;
            }

            yield call(setOrderToStateAndAddContributor,
                orderPicked,
                allowLoadingOrder,
                userUID,
                selectedLocation,
                selectedCatalog,
                selectedTable
            );
        }
        // no order has been picked
        else {

            log.info(">-A-> No order picked");

            if (needsToCreateDraftAfterReset(selectedLocation?.orders?.webapp_table_orders, selectedTable.service_type)) {

                log.info(">-A-> Creating a new draft order and writing it to firestore");
                yield put(actions.createDraftOrder(selectedLocation, selectedCatalog, selectedTable));
            }
        }
    } else {
        log.warn(">-A-> Selected table is null");
    }
}

function* postOrder(action: SendOrderAction) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    // RootState
    const { tableLinkId, sessionId } = (yield select((state: RootState) => state.locations)) as LocationState;
    try {
        yield put(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(
            CustomerInformationModalFormToDisplay.SHARED_ORDER_FINALIZE,
        ));
        const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);

        headers.append("Authorization", `Bearer ${tokenResult.token}`);
        // TODO: updating the state with the new token
        sessionId && headers.append(SESSION_ID_HEADER, sessionId);

        const state: OrderState = yield select((nState: RootState) => nState.order);

        log.info(`Sending order with expected time ${state.order.expected_time?.toISOString()} & expected time asap ${state.order.expected_time_asap}`);

        const successUrl = window.location.origin + ROUTES.getOrderConfirmationFullRoute(tableLinkId, `:${ORDER_ID_QUERY_PARAM_NAME}`, `:${PAYMENT_ID_PARAM_KEY}`);
        // TODO: Cancel url with dedicated page
        const cancelUrl = window.location.origin + ROUTES.getCategoriesFullRoute(tableLinkId);
        const errorUrl = window.location.origin + ROUTES.getErrorFullRoute(tableLinkId, `:${ORDER_ID_QUERY_PARAM_NAME}`);

        const createOrderRequest: OrderCreateRequest = {
            account_id: state.account_id!,
            location_id: state.location_id!,
            catalog_id: state.catalog_id!,
            table_id: state.table_id!,
            order: state.order,
            payment_type: action.payload.type,
            payment_data: action.payload.paymentData,
            payment_amount: action.payload.paymentAmount ? action.payload.paymentAmount : state.order.total,
            success_url: successUrl,
            error_url: errorUrl,
            cancel_url: cancelUrl,
            table_link_id: tableLinkId,
            payment_items: action.payload.payment_items,
            payment_amount_type: action.payload.payment_amount_type,
            tip_charge_amount: action.payload.tip_charge_amount,
        }

        const deviceInfo = getDeviceDetectInfo();
        if (!createOrderRequest.order.customer) {
            createOrderRequest.order.customer = {};
        }
        if (deviceInfo && deviceInfo.uuid && createOrderRequest.order.customer) {
            log.debug(`Device info`, deviceInfo);
            // Clean device info to only send the current device
            createOrderRequest.order.customer.current_device = deviceInfo;
        } else {
            log.error(`No device info detected`);
        }
        // Do not send other devices & roles
        delete createOrderRequest.order.customer.devices;
        delete createOrderRequest.order.customer.roles;

        const raw = JSON.stringify({ ...createOrderRequest, pin: action.payload.pin });
        log.debug(`Sending order`, raw);

        const requestOptions: any = {
            method: "POST",
            headers: headers,
            body: raw,
        };

        const apiOrderUrl = getApiEndpoint() + ordersApiRoutes.ORDER_CREATE;

        const response: Response = yield fetch(apiOrderUrl, requestOptions);
        const result: OrderCreateResponse = yield response.json();
        if (!response.ok || !result) {
            log.error(`Send order error with status ${response.status}`, result)
            const httpErrorResponse: HttpErrorResponse = result as unknown as HttpErrorResponse;
            yield put(actions.orderSendError(httpErrorResponse?.code, httpErrorResponse?.message, httpErrorResponse?.value));
            yield put(CommonActions.setRedirectURL(ROUTES.getErrorFullRoute(tableLinkId)))
        } else {
            // Response ok, get the order creation response
            let paymentPage: string | undefined = paymentService.getPaymentPage(tableLinkId, result.payment_infos);
            yield put(actions.orderSendSuccess(result.order, result.payment_infos, paymentPage));

            // Reload user data 
            yield put(AuthenticationActions.loadUserInfo())

            // Redirect to confirmation or payment page.
            // /!\ WARNING: this code is duplicated in PaymentSaga > createOrderPayment
            if (paymentPage) {
                yield put(CommonActions.setRedirectURL(paymentPage));
            } else {
                yield put(CommonActions.setRedirectURL(
                    ROUTES.getOrderConfirmationFullRoute(tableLinkId, result.order.id),
                    undefined,
                    RedirectUrlType.CONFIRM,
                    RedirectUrlFrom.ORDER_SAGA,
                ));
            }
        }

    }
    catch (error) {
        log.error(`Error while sending order: ${getErrorMessage(error)} (${getErrorStack(error)})`);
        log.error(error);
        yield put(actions.orderSendError());
        yield put(CommonActions.setRedirectURL(ROUTES.getErrorFullRoute(tableLinkId)))
    }
}

function* sendAddedItems(action: SendAddedItemsAction) {

    const { tableLinkId, sessionId } = (yield select((state: RootState) => state.locations)) as LocationState;

    try {

        const { tableLinkId } = (yield select((state: RootState) => state.locations)) as LocationState;
        const { order }: OrderState = yield select((nState: RootState) => nState.order);
        const addedItems = order.items.filter((orderItem) => !orderItem.update_id);

        try {
            const updatedOrder: OrderInBase = yield call(addItemsToOrder, order.id, addedItems, null, true, sessionId);
            yield put(actions.orderSendSuccess(updatedOrder, undefined, undefined));
            if (action.payload.successUrl) {
                yield put(CommonActions.setRedirectURL(action.payload.successUrl));
            }
        }
        // Catching the error which might be an invalid_content (invalid items or deals)
        catch (error) {

            const httpErrorResponse = error as HttpErrorResponse;
            yield put(actions.orderSendError(httpErrorResponse?.code, httpErrorResponse?.message, httpErrorResponse?.value));
            yield put(CommonActions.setRedirectURL(ROUTES.getErrorFullRoute(tableLinkId, undefined, ErrorPageType.ADD_ORDER_ITEM)));
        }
    }
    catch (error) {
        log.error("Error while sending added order items");
        log.error(error);
        yield put(actions.orderSendError());
        yield put(CommonActions.setRedirectURL(ROUTES.getErrorFullRoute(tableLinkId, undefined, ErrorPageType.ADD_ORDER_ITEM)))
    }
}

const addItemsToOrder = async (
    orderId: string,
    orderItems: OrderItem[],
    deals: { [key: string]: OrderDeal } | null,
    confirm: boolean,
    sessionId: string | undefined,
): Promise<OrderInBase> => {

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const addItemsUrl = getApiEndpoint() + ordersApiRoutes.ORDER_ADD_ITEMS.replace(ORDER_ID_PARAM, orderId);
    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();

    headers.append("Authorization", `Bearer ${tokenResult?.token}`);
    sessionId && headers.append(SESSION_ID_HEADER, sessionId);

    let addItemsRequest: OrderAddItemsRequest = {
        items: orderItems,
        confirm: confirm
    };

    if (deals) {
        addItemsRequest.deals = deals
    }

    const raw = JSON.stringify(addItemsRequest);
    const requestOptions: RequestInit = {
        method: "PUT",
        headers: headers,
        body: raw,
    };

    const response = await fetch(addItemsUrl, requestOptions);
    const result: OrderAddItemsResponse = await response.json() as OrderAddItemsResponse;

    if (!response.ok) {
        console.warn(response)
        console.warn(result)
        log.error(`Add items error with status ${response.status}`, result)
        const httpErrorResponse: HttpErrorResponse = result as unknown as HttpErrorResponse;
        throw httpErrorResponse;
    }

    // Response ok
    return result.order;
}

export const getOrdersCollectionReference = (
    location: Location | undefined,
): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | null => {

    let accountId: string | undefined = location?.account_id;
    let locationId: string | undefined = location?.id;

    if (!accountId || !locationId) {

        return null;
    }

    const ordersCollectionReference: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db
        .collection('accounts').doc(accountId)
        .collection('locations').doc(locationId)
        .collection('orders');

    return ordersCollectionReference;
}

/**
 * This function communicates with firestore and set a draft order
 * reacting to actions: add items, remove items, reset order, ...
 * NOTE: this function does not compute anything regarding the item list
 * for example. Everything is done in the reducer, the saga only sends the
 * information to firestore.
 * TODO: to split
 */
function* setDraftOrder(actionParam: AddItemAction | AddItemsAction
    | AddDealAction | RemoveDealAction | EditDealAction | RemoveItemAction | ResetItemsAction
    | ResetOrderAction | CreateDraftOrderAction | AddContributorAction
    | SetDeliveryZoneAction | SetChargeAction | SetCustomerInformationAction
    | SetMasterUserAction | SetStatusWaitingSubmissionAction | SetOrderAction
    | SetCustomerNotesAction | UpdateItemNoteAction | UpdateContributorAction
    | SetPickupAction | SetExpectedTimeAction) {

    let { selectedLocation, selectedTable, selectedCatalog, tableLinkId, sessionId } = (yield select((state: RootState) => state.locations)) as LocationState;
    const { order } = (yield select((state: RootState) => state.order)) as OrderState;
    const { data } = (yield select((state: RootState) => state.authentication)) as AuthenticationState;

    // Draft order can be called before complete loading: location, catalog not yet in state
    if (actionParam.type === CREATE_DRAFT_ORDER || actionParam.type === ADD_CONTRIBUTOR) {
        const action = actionParam as CreateDraftOrderAction;
        if (action.payload.location) {
            selectedLocation = action.payload.location;
        }
        if (action.payload.catalog) {
            selectedCatalog = action.payload.catalog;
        }
        if (action.payload.table) {
            selectedTable = action.payload.table;
        }
    }

    let accountId: string | undefined = selectedLocation?.account_id;
    let locationId: string | undefined = selectedLocation?.id;
    let orderId: string = order.id;
    let tableId: string = selectedTable.id;
    let catalogId: string | undefined = selectedCatalog?.id;

    log.debug("entering setDraftOrder function");

    try {

        // If we are here because of SET_ORDER and that the ignoreDraft is set to true,
        // we just leave the function and do nothing
        if (actionParam.type === SET_ORDER) {
            const action = actionParam as SetOrderAction;
            if (action.payload.ignoreDraft) {
                return;
            }
        }

        // When resetting the order, make sure to add the current user as contributor
        if (actionParam.type === RESET_ORDER && data.user_authentication_state.user?.uid) {
            yield put(actions.addContributor(
                data.user_authentication_state.user.uid,
                data.user_authentication_state.user
            ));
        }

        // Reset table service type to let the user choose it again
        if (
            (
                actionParam.type === RESET_ORDER
                || actionParam.type === RESET_ITEMS
            )
            && selectedTable.restrictions?.service_types
            && selectedTable.restrictions.service_types.length > 1
        ) {
            yield put(locationActions.setServiceType(null));
        }

        if (accountId && locationId && catalogId) {

            const collectionReference = getOrdersCollectionReference(selectedLocation);
            if (!collectionReference) {
                log.error("Trying to get the orders collection reference without location_id or update_id: aborted");
                return;
            }

            if (orderId === "") {

                if (actionParam.type === ADD_CONTRIBUTOR) {
                    log.debug("addContributor saga: updating the local order directly");
                    yield put(actions.addContributorLocal(actionParam.payload.uid, actionParam.payload.user, actionParam.payload.patch));
                }

                // We need to create a draft on the database either:
                // - when explicitely asked for (action CREATE_DRAFT_ORDER)
                // - when adding item(s) or deal(s)
                if (
                    actionParam.type === CREATE_DRAFT_ORDER
                    || actionParam.type === ADD_ITEM
                    || actionParam.type === ADD_ITEMS
                    || actionParam.type === ADD_DEAL
                    || actionParam.type === EDIT_DEAL
                    || actionParam.type === SET_ORDER
                    || actionParam.type === UPDATE_CONTRIBUTOR
                ) {
                    // TODO : Split ? Why list all different ? 
                    log.debug("draft saga: new ID");

                    const id = collectionReference.doc().id;
                    const now = new Date();

                    // Order has already been updated (item added if ADD_ITEM has been used)
                    const orderCopy = _.cloneDeep(order);

                    let newOrder: OrderInBase = {
                        ...orderCopy,
                        id: id,
                        display_id: orderService.createOrderDisplayId(id),
                        created_at: now, // Will be overriden by the API when order posted
                        draft_created_at: now, // Will stay
                        account_id: accountId,
                        table_id: tableId,
                        location_id: locationId,
                        catalog_id: catalogId,
                        table_link_id: tableLinkId,
                        status: OrderStatus.DRAFT,
                        user_id: data.user_authentication_state.user?.uid ? data.user_authentication_state.user.uid : undefined,
                        loyalty_config: selectedLocation?.loyalty,
                        service_type: selectedTable.service_type ?? order.service_type,
                        total: getFabOrderPriceWithoutDiscountCharges(order, getCurrency(order.total)),
                    }

                    if (selectedTable.area_ref) {
                        newOrder.table_area_ref = selectedTable.area_ref;
                    }

                    if (actionParam.type === UPDATE_CONTRIBUTOR) {
                        const action = actionParam as UpdateContributorAction;
                        newOrder.contributors = action.payload.contributors;
                    }
                    else if (data.user_authentication_state.user?.uid) {
                        newOrder.contributors = {
                            [data.user_authentication_state.user.uid]: data.user_authentication_state.user,
                        }
                    }

                    if (isUserLoggedIn(data.user_authentication_state)) {
                        newOrder.loyalty_user_id = data.user_authentication_state.user?.uid;
                    }

                    newOrder.last_update_reason = OrderUpdateReason.CREATE_DRAFT_ORDER;
                    removeUndefinedFromObject(newOrder);
                    log.debug("OrderSaga setDraftOrder: newOrder", newOrder);

                    // Send to firebase
                    collectionReference.doc(id).set(newOrder);

                    /**
                     * Now that the order has been sent to firestore, we will set it to the state. But
                     * the total sent to firestore is the totalSinDiscountsAndCharges. So we have to delete
                     * the total before setting the state: the state will keep the real total
                     */
                    //@ts-ignore
                    delete newOrder.total;

                    yield put(actions.mergeOrder(newOrder));
                }
                else {
                    log.debug(`draft saga: no ID and action ${actionParam.type} does not require to create a draft`);
                }
            }
            else {
                log.debug("draft saga: existing ID");

                // FIRST CASE: ADD_ITEM
                if (actionParam.type === ADD_ITEM) {

                    const action = actionParam as AddItemAction;

                    // We have to add the item to a new order because this one is not editable
                    if (selectedLocation && !orderService.canAddItems(selectedLocation, order)) {

                        log.info("WARNING: trying to add an item on a non-editable order: reset order and call the function again");
                        yield put(actions.resetOrder());

                        yield put(actions.addItem(
                            action.payload.product.product_ref,
                            action.payload.product.sku_ref,
                            action.payload.product.options,
                            action.payload.catalog,
                            action.payload.location,
                            action.payload.table,
                            action.payload.product.product_name,
                            action.payload.product.quantity,
                            action.payload.product.price,
                            action.payload.product.customer_notes,
                            action.payload.product.contributor_user_id,
                            action.payload.calculPrice,
                        ));
                        return;
                    }

                    // Searching for the item
                    const index = orderService.getSameItemIndex(order, action.payload.product);

                    const orderItem = order.items[index];

                    if (orderItem) {
                        // Found with a different quantity, let's update
                        if (orderItem.quantity !== action.payload.product.quantity) {

                            if (orderService.isFullyEditable(order)) {
                                log.debug("addItem saga: saving all items because the quantity has changed");
                                // Ajouter les deals
                                yield call(updateItems, order.items, null, orderId, order, collectionReference);
                            }
                            else {
                                log.info(`Adding items through api`);
                                // Send to API but not yet confirmed
                                const addedItems = order.items.filter((item) => !item.update_id)
                                yield call(addItemsToOrder, order.id, addedItems, null, false, sessionId);
                            }
                        }
                        // Not found, adding it
                        else {

                            log.debug("addItem saga: creating the item");

                            removeUndefinedFromObject(orderItem);

                            if (orderService.isFullyEditable(order)) {
                                yield call(updateItems,
                                    firestore.FieldValue.arrayUnion(orderItem),
                                    null,
                                    orderId,
                                    order,
                                    collectionReference,
                                    false
                                );
                            }
                            else {
                                log.info(`Adding items through api`);
                                // Send to API but not yet confirmed
                                const addedItems = order.items.filter((item) => !item.update_id)
                                yield call(addItemsToOrder, order.id, addedItems, null, false, sessionId);
                            }
                        }
                    } else {
                        // Not suppose to occur, 
                        if (orderService.isFullyEditable(order)) {
                            log.debug(`Order item not found in order, send all items`, order.items);
                            yield call(updateItems, order.items, order.deals, orderId, order, collectionReference);
                        }
                        else {
                            log.info(`Adding items through api`);
                            // TODO: extract new deals and add it
                            // Send to API but not yet confirmed
                            const addedItems = order.items.filter((item) => !item.update_id)
                            yield call(addItemsToOrder, order.id, addedItems, order.deals, false, sessionId);
                        }
                    }

                }

                // SECOND CASE: ADD_ITEMS
                else if (actionParam.type === ADD_ITEMS) {

                    const action = actionParam as AddItemsAction;

                    // We have to add the items to a new order because this one is not editable
                    if (selectedLocation && !orderService.canAddItems(selectedLocation, order)) {

                        log.info("WARNING: trying to add items on a non-editable order: reset order and call the function again");
                        yield put(actions.resetOrder());

                        yield put(actions.addItems(
                            action.payload.listItems,
                            action.payload.catalog,
                            action.payload.location,
                            action.payload.table,
                            action.payload.calculPrice,
                        ));
                        return;
                    }

                    const listItems: ListItemsToAdd[] = action.payload.listItems;
                    removeUndefinedFromObject(listItems)

                    if (orderService.isFullyEditable(order)) {

                        yield call(updateItems,
                            firestore.FieldValue.arrayUnion(...listItems),
                            null,
                            orderId,
                            order,
                            collectionReference,
                            false
                        );
                    } else {
                        log.info(`Add / remove items through api`);
                        // Send to API but not yet confirmed
                        const addedItems = order.items.filter((item) => !item.update_id)
                        yield call(addItemsToOrder, order.id, addedItems, null, false, sessionId);
                    }
                }

                // 4TH CASE: REMOVE_ITEM, RESET_ITEM, RESET_ORDER
                else if (
                    actionParam.type === REMOVE_ITEM
                    || actionParam.type === REMOVE_DEAL
                    || actionParam.type === RESET_ITEMS
                    || actionParam.type === RESET_ORDER
                ) {
                    log.debug("removeItem saga: updating the item list to remove item");
                    if (orderService.isFullyEditable(order)) {
                        yield call(updateItems, order.items, order.deals, orderId, order, collectionReference);
                    }
                    else {
                        log.info(`Add / remove items through api`);
                        // Send to API but not yet confirmed
                        const addedItems = order.items.filter((item) => !item.update_id)
                        yield call(addItemsToOrder, order.id, addedItems, null, false, sessionId);
                    }
                }

                // 5TH CASE: ADD_DEAL
                else if (actionParam.type === ADD_DEAL) {

                    const action = actionParam as AddDealAction;

                    // We have to add the deal to a new order because this one is not editable
                    if (selectedLocation && !orderService.canAddItems(selectedLocation, order)) {

                        log.info("WARNING: trying to add a deal on a non-editable order: reset order and call the function again");
                        yield put(actions.resetOrder());

                        yield put(actions.addDeal(
                            action.payload.dealRef,
                            action.payload.dealItems,
                            action.payload.catalog,
                            action.payload.location,
                            action.payload.table,
                            action.payload.userId,
                        ));
                        return;
                    }

                    log.debug("addDeal saga: updating the item list");
                    if (orderService.isFullyEditable(order)) {
                        yield call(updateItems, order.items, order.deals, orderId, order, collectionReference);
                    }
                    else {
                        log.info(`Add items through api`);
                        // Send to API but not yet confirmed
                        const addedItems = order.items.filter((item) => !item.update_id)
                        yield call(addItemsToOrder, order.id, addedItems, null, false, sessionId);
                    }
                }

                // 5TH CASE: EDIT_DEAL
                else if (actionParam.type === EDIT_DEAL) {

                    const action = actionParam as EditDealAction;

                    // Check if the order is editable
                    if (selectedLocation && !orderService.canAddItems(selectedLocation, order)) {
                        log.info("WARNING: trying to edit a deal on a non-editable order: reset order and call the function again");
                        yield put(actions.resetOrder());

                        yield put(actions.editDeal(
                            action.payload.dealRef,
                            action.payload.dealItems,
                            action.payload.catalog,
                            action.payload.location,
                            action.payload.table,
                            action.payload.userId,
                        ));
                        return;
                    }

                    log.debug("addDeal saga: updating the item list");
                    if (orderService.isFullyEditable(order)) {
                        yield call(updateItems, order.items, order.deals, orderId, order, collectionReference);
                    }
                }

                // 6TH CASE: ADD CONTRIBUTOR
                else if (actionParam.type === ADD_CONTRIBUTOR) {

                    log.debug("addContributor saga: adding via API");
                    const addedViaApi: boolean = yield call(addOrUpdateContributorViaAPI, order.id, sessionId);  // Losing patch, tant pis
                    if (!addedViaApi) {
                        log.debug("addContributor saga: error while adding via API: updating the local order directly");
                        yield put(actions.addContributorLocal(actionParam.payload.uid, actionParam.payload.user, actionParam.payload.patch));
                    }

                    const userId = data.user_authentication_state.user?.uid;
                    if (
                        orderService.isFullyEditable(order)
                        && !order.loyalty_user_id
                        && isUserLoggedIn(data.user_authentication_state)
                        && userId
                    ) {

                        yield call(updateLoyaltyUserId, userId, orderId, collectionReference);
                    }
                }

                // 6,5TH CASE: UPDATE CONTRIBUTOR 
                else if (actionParam.type === UPDATE_CONTRIBUTOR) {
                    const action = actionParam as UpdateContributorAction
                    const contributors = action.payload.contributors

                    yield call(updateContributors, contributors, orderId, collectionReference)
                }

                // 7TH CASE: SET_CHARGE (actually this action is also setting the customer address)
                // or SET_CUSTOMER_INFORMATION or SET_DELIVERY_ZONE
                else if (
                    actionParam.type === SET_CHARGE
                    || actionParam.type === SET_CUSTOMER_INFO
                    || actionParam.type === SET_DELIVERY_ZONE
                ) {

                    log.info("updateCustomer saga: updating the order");
                    if (orderService.isFullyEditable(order)) {
                        yield call(
                            updateCustomerAndCharges,
                            order.customer,
                            order.charges,
                            orderId,
                            order.delivery_zone_ref,
                            order.delivery,
                            collectionReference
                        );
                    }
                }

                // 8TH CASE: SET_MASTER_USER
                else if (
                    actionParam.type === SET_MASTER_USER
                ) {

                    log.debug("setMasterUser saga : setting the master user");

                    yield call(updateMasterUser, order.master_user_uid, orderId, collectionReference);
                }

                // 9TH CASE: SET STATUS
                else if (
                    actionParam.type === SET_STATUS_WAITING_SUBMISSION
                ) {

                    log.debug("change status saga : setting the status");

                    yield call(updateStatus, order.status, orderId, collectionReference);
                }
                // 10TH CASE: SET CUSTOMER NOTE
                else if (
                    actionParam.type === SET_CUSTOMER_NOTES
                ) {
                    log.debug("update customer note");
                    yield call(updateCustomerNotes, orderId, order.customer_notes, collectionReference);
                }
                // 11TH CASE: UPDATE ITEM NOTE
                else if (
                    actionParam.type === UPDATE_ITEM_NOTE
                ) {
                    log.debug("Update item note")
                    const action = actionParam as UpdateItemNoteAction;
                    const { product_ref, sku_ref, note } = action.payload;

                    const triggeredProduct: OrderItem | undefined = order.items.find(item => item.product_ref === product_ref && item.sku_ref === sku_ref);
                    if (triggeredProduct) {
                        triggeredProduct.customer_notes = note
                        yield call(updateItemNote, order, collectionReference)
                    } else {
                        log.error(`Cannot find item with product_ref ${product_ref} and sku ${sku_ref} in order`)
                    }

                }
                // 12TH CASE: UPDATE expected / end preparation time
                else if (actionParam.type === SET_EXPECTED_TIME) {

                    const action = actionParam as SetExpectedTimeAction;

                    log.info(`OrderSaga: Update expected / end preparation time`, {
                        expectedTime: action.payload.expectedTime,
                        expectedTimeAsap: action.payload.expectedTimeAsap,
                        endPreparationTime: action.payload.endPreparationTime,
                    });

                    yield call(updateExpectedOrEndPreparationTime,
                        order.id,
                        order.expected_time,
                        order.end_preparation_time,
                        order.expected_time_asap,
                        collectionReference,
                    );
                }
                // 13TH CASE: UPDATE customer
                else if (
                    actionParam.type === SET_PICKUP
                ) {

                    const action = actionParam as SetPickupAction;
                    log.info(`OrderSaga: Update customer`, {
                        customer: action.payload.customerInfo,
                    });

                    log.info(`Update customer ${order.customer?.uid}`);
                    yield call(updateCustomer,
                        order.id,
                        action.payload.customerInfo,
                        collectionReference
                    );
                }
            }
        } else {
            log.warn(`No account set yet, nothing to do for action ${actionParam.type}`);
        }
    }
    catch (error) {
        log.error(`Error while manipulating draft order ${order.display_id} (status ${order.status}, action: ${actionParam.type})`, error);
    }
}

export function* setLoyaltyUserId(action: SetLoyaltyUserIdAction) {

    const { selectedLocation } = (yield select((state: RootState) => state.locations)) as LocationState;
    const { order } = (yield select((state: RootState) => state.order)) as OrderState;

    if (!orderService.isFullyEditable(order)) {

        log.info(`Cannot set loyalty_user_id for order ${order.display_id} because it is not editable.`);
        return;
    }

    const ordersCollectionReference = getOrdersCollectionReference(selectedLocation);
    if (!ordersCollectionReference || !order.id) {
        log.debug("Trying to update the loyalty_user_id: missing location or orderID, skipped.");
        return;
    }

    log.debug("update loyalty user_id");
    yield call(updateLoyaltyUserId, action.payload.userId, order.id, ordersCollectionReference);
}

export function* setDiscounts(action: AddDiscountToOrderAction | RemoveDiscountFromOrderAction) {
    const { order } = (yield select((state: RootState) => state.order)) as OrderState;

    if (!orderService.isFullyEditable(order)) {

        log.info(`Cannot set loyalty_user_id for order ${order.display_id} because it is not editable.`);
        return;
    }
    yield call(updateDiscount, order);
}

const updateItemNote = async (order: Order, collectionReference: firestore.CollectionReference<firestore.DocumentData>) => {
    removeUndefinedFromObject(order.items);
    const update: Partial<OrderInBase> = {
        items: order.items,
        last_update_reason: OrderUpdateReason.UPDATE_ITEM_NOTE,
    }
    await collectionReference.doc(order.id).update(update);
}

function* removeDiscountFromOrder(action: RemoveDiscountFromOrderAction) {

    const { discount, contributor_uid_to_remove_auto_apply, location } = action.payload;
    const { order } = (yield select((state: RootState) => state.order)) as OrderState;

    const foundDiscountIndex = order.discounts?.findIndex(currentDiscount => currentDiscount.ref === discount.ref);

    if (order.discounts && !_.isNil(foundDiscountIndex)) {

        const newDiscounts = _.cloneDeep(order.discounts);
        newDiscounts.splice(foundDiscountIndex, 1);

        // Setting the field in contributor
        const newContributors = _.cloneDeep(order.contributors);
        const newContributor = newContributors?.[contributor_uid_to_remove_auto_apply];

        if (newContributor) {
            newContributor.disable_auto_discount = true;
        }

        const ordersCollectionReference = getOrdersCollectionReference(location);
        if (!ordersCollectionReference) {
            log.error("Trying to get the orders collection reference without location_id or update_id: aborted");
            return;
        }
        yield call(
            updateDiscountsAndContributors,
            newDiscounts,
            newContributors,
            order.id,
            ordersCollectionReference,
        );
    }

}

const updateDiscountsAndContributors = async (
    discounts: OrderDiscount[],
    contributors: OrderInBase["contributors"],
    orderId: string,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>,
) => {

    const orderUpdate: Partial<OrderInBase> = {
        discounts,
        contributors,
        last_update_reason: OrderUpdateReason.UPDATE_DISCOUNTS_AND_CONTRIBUTORS,
    };
    await collectionReference.doc(orderId).update(orderUpdate);
}

/**
 * Updates the items of an order in firestore
 * @param items 
 * @param orderId 
 * @param order 
 * @param collectionReference 
 */
const updateItems = async (items: OrderItem[] | firestore.FieldValue,
    deals: { [key: string]: OrderDeal } | null,
    orderId: string, order: Order,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>,
    removeUndefined: boolean = true) => {

    if (removeUndefined) {
        removeUndefinedFromObject(items)
    }

    const orderUpdate: SetOrderItems | Partial<OrderInBase> = {
        items: items,
        total: getFabOrderPriceWithoutDiscountCharges(order, getCurrency(order.total)),
        last_update_reason: OrderUpdateReason.UPDATE_ITEMS,
    };

    if (deals) {
        orderUpdate.deals = deals;
    }

    await collectionReference.doc(orderId).update(orderUpdate);
}

/**
 * Update the contributors object of an order in firestore
 * @param contributors 
 * @param orderId 
 * @param order 
 * @param collectionReference 
 */
const updateContributors = async (
    contributors: { [key: string]: Customer } | undefined,
    orderId: string,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>,
) => {

    if (contributors) {

        const orderUpdate: Partial<OrderInBase> = {
            contributors: contributors,
            last_update_reason: OrderUpdateReason.UPDATE_CONTRIBUTORS,
        }

        removeUndefinedFromObject(orderUpdate);
        await collectionReference.doc(orderId).update(orderUpdate);
    }
}

const updateMasterUser = async (
    master_uid: string | undefined,
    orderId: string,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>,
) => {

    const update: Partial<OrderInBase> = {
        master_user_uid: master_uid || null!,  // Forcing null in case we want to delete it
        last_update_reason: OrderUpdateReason.UPDATE_MASTER_USER,
    }
    await collectionReference.doc(orderId).update(update);
}

const updateCustomerNotes = async (
    orderId: string,
    customer_notes: string | undefined,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>
) => {

    const update: Partial<OrderInBase> = {
        customer_notes: customer_notes ?? null!,  // Forcing null in case we want to delete it
        last_update_reason: OrderUpdateReason.UPDATE_CUSTOMER_NOTES,
    }
    await collectionReference.doc(orderId).update(update);
}

export const updateServiceType = async (
    orderId: string,
    service_type: SupportedServiceType,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>
) => {
    const update: Partial<OrderInBase> = {
        service_type: service_type,
        last_update_reason: OrderUpdateReason.UPDATE_SERVICE_TYPE,
    }
    await collectionReference.doc(orderId).update(update);
}

const updateDiscount = async (order: OrderInBase) => {
    const docReference = db.doc(getOrderFirestoreDocPath(order.account_id, order.location_id, order.id));
    const update: Partial<OrderInBase> = {
        discounts: order.discounts ?? null!,  // Forcing null here, the function is probably used to remove discounts
        last_update_reason: OrderUpdateReason.UPDATE_DISCOUNTS,
    }
    await docReference.update(update);
}

const updateCustomer = async (
    orderId: string,
    customer: Customer,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>
) => {

    const orderUpdate: Partial<OrderInBase> = {
        customer,
        last_update_reason: OrderUpdateReason.UPDATE_CUSTOMER,
    };
    removeUndefinedFromObject(orderUpdate);
    await collectionReference.doc(orderId).update(orderUpdate);
}

const updateExpectedOrEndPreparationTime = async (
    orderId: string,
    expected_time: Date | undefined,
    end_preparation_time: Date | undefined,
    expected_time_asap: boolean | undefined,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>
) => {

    const orderUpdate: Partial<OrderInBase> = {};

    if (expected_time) {
        orderUpdate.expected_time = expected_time;
    }
    if (end_preparation_time) {
        orderUpdate.end_preparation_time = end_preparation_time;
    }
    if (!_.isNil(expected_time_asap)) {
        orderUpdate.expected_time_asap = expected_time_asap;
    }

    if (Object.keys(orderUpdate).length > 0) {
        orderUpdate.last_update_reason = OrderUpdateReason.UPDATE_EXPECTED_OR_END_PREPARATION_TIME;
        removeUndefinedFromObject(orderUpdate);
        await collectionReference.doc(orderId).update(orderUpdate);
    }
}

const updateStatus = async (
    status: OrderStatus,
    orderId: string,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>
) => {

    const update: Partial<OrderInBase> = {
        status,
        last_update_reason: OrderUpdateReason.UPDATE_STATUS,
    }
    await collectionReference.doc(orderId).update(update);
}

const updateLoyaltyUserId = async (
    userId: string,
    orderId: string,
    collectionReference: firestore.CollectionReference<firestore.DocumentData>,
) => {

    const update: Partial<OrderInBase> = {
        loyalty_user_id: userId,
        last_update_reason: OrderUpdateReason.UPDATE_LOYALTY_USER_ID,
    }
    await collectionReference.doc(orderId).update(update);
}

/**
 * This function sets a listener on the order in firestore. When the order is
 * modified, the function will call the SYNC_ORDER action and trigger the syncUpdateOrder function below.
 */
let syncOrdersTask: Task;
let syncOrderId: string = "";
function* setupSyncOrder(actionParam: SetOrderAction | MergeOrderAction) {

    const { selectedLocation: selectedLocationFromState } = (yield select((state: RootState) => state.locations)) as LocationState;
    const { order } = (yield select((state: RootState) => state.order)) as OrderState;
    const orderId: string = order.id;
    const selectedLocation = (actionParam.type === SET_ORDER && actionParam.payload.location) ? actionParam.payload.location : selectedLocationFromState;

    // We don't want to cancel and respawn the task if we're already listening to this order
    if (orderId === syncOrderId) {
        return;
    }

    // TODO: no need to use the location, the order is supposed to have account id, location id
    if (!order.account_id || !order.location_id) {
        log.error(`Order ${order.id} does not have account id (${order.account_id}) or location id (${order.location_id})`);
    }

    const accountId: string = selectedLocation?.account_id ?? order.account_id;
    const locationId: string = selectedLocation?.id ?? order.location_id;

    // If we are here because of SET_ORDER and that the ignoreDraft is set to true,
    // we just leave the function and do nothing
    if (actionParam.type === SET_ORDER) {
        const action = actionParam as SetOrderAction;
        if (action.payload.ignoreDraft) {
            return;
        }
    }

    try {
        if (syncOrdersTask) {
            log.info("Cancelling previous order listening task");
            yield cancel(syncOrdersTask);
        }

        log.info(`Setting up synchronization for order ${order.display_id} (${orderId}), location ${locationId}, account ${accountId}.`);

        const orderSyncSuccessAction: any = {
            successActionCreator: (data: firebase.firestore.DocumentSnapshot) => {
                return actions.syncOrder(data);
            }
        }

        const orderDocumentRef = db.doc(getOrderFirestoreDocPath(accountId, locationId, orderId));

        // TODO: replace spawn by fork otherwise cancel does not work
        syncOrdersTask = yield spawn(rsf.firestore.syncDocument, orderDocumentRef, orderSyncSuccessAction);
        syncOrderId = orderId;

        // Setup the customer sync in case it is not already done
        yield call(setupSyncCustomer, { type: SETUP_SYNC_CUSTOMER, payload: {} });

        log.info(`Order ${order.display_id} (${orderId}) is now synced with firestore (status ${order.status})`)
    }
    catch (error) {
        log.error(`Error while syncing order ${order.display_id} (${orderId}), location ${locationId}, account ${accountId}.`)
    }
}

/**
 * This functions is triggered each time there is a modification on the order
 * we're synced with (from firestore). It updates the order in state with the new
 * (and most recent) one.
 * @param action 
 */
function* syncUpdateOrder(action?: SyncOrderAction) {

    const { selectedLocation, selectedCatalog, selectedTable, tableLinkId } = (yield select((state: RootState) => state.locations)) as LocationState;
    const { order } = (yield select((state: RootState) => state.order)) as OrderState;
    const { data } = (yield select((state: RootState) => state.authentication)) as AuthenticationState;

    const accountId: string = selectedLocation ? selectedLocation.account_id : "";
    const locationId: string = selectedLocation ? selectedLocation.id : "";
    const orderId: string = order.id;

    try {
        // Getting the updated order
        let orderSnapshot: firebase.firestore.DocumentSnapshot;
        let listeningSync = false;
        if (action && action.payload) {
            listeningSync = true;
            orderSnapshot = action.payload;
        }
        else {

            // In case the function was not called properly, we fetch the order in firestore
            const documentRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = db
                .collection('accounts').doc(accountId)
                .collection('locations').doc(locationId)
                .collection('orders').doc(orderId);

            orderSnapshot = yield call(rsf.firestore.getDocument, documentRef);
        }

        const updatedOrder: OrderInBase = orderSnapshot.data() as OrderInBase;

        if (!updatedOrder.loyalty_config && selectedLocation?.loyalty) {
            updatedOrder.loyalty_config = selectedLocation.loyalty;
        }

        /**
        * If the order is not editable anymore, set the use_points to false so that we remove any "temporary"
        * loyalty discount from the order. The "use_points" logic will be done locally using redux.
        */
        if (isLocalLoyaltyOnly(updatedOrder, selectedLocation?.enable_share_payment)) {
            const contributors = updatedOrder.contributors;
            const uid = data.user_authentication_state.user?.uid;
            if (contributors && uid) {
                const contributor = contributors[uid];
                contributor.use_points = false;
            }
        }


        if (getCurrency(order.total) !== getCurrency(updatedOrder.total)) {
            log.error(`State order and base order ${order.id}'s currency doesn't match `)
        }

        if (!listeningSync || updatedOrder.id === order.id) {
            updateDateFieldsInFirestoreOrder(updatedOrder, selectedLocation!);

            // Trigger the logic which will redirect to pages or open the "finalize order?" popup
            yield call(syncOrderLogic, order, updatedOrder, data.user_authentication_state.user?.uid, tableLinkId);

            // Now let's replace the items and the total
            if (selectedCatalog && selectedLocation && selectedTable) {
                log.info(`Setting order ${updatedOrder.id} after sync (listening ${listeningSync}, status ${updatedOrder.status})`)
                yield put(actions.setOrder(updatedOrder, selectedCatalog, selectedLocation, selectedTable, true));
            }
            else {
                log.error("No catalog selected.")
                throw ({});
            }
        } else {
            log.warn(`No supposed to receive updates for order ${updatedOrder.id}, listening to ${order.id}`)
        }
    }
    catch (error) {
        log.error(error);
        log.error(`Error while updating order ${order.display_id} (${orderId}), location ${locationId}, account ${accountId}.`)
    }
}

let syncCustomerTask: Task;
let syncCustomerId: string = "";
export function* setupSyncCustomer(action: SetupSyncCustomerAction) {

    const { data } = (yield select((state: RootState) => state.authentication)) as AuthenticationState;
    const { order } = (yield select((state: RootState) => state.order)) as OrderState;

    const userId = action.payload.userId ?? data.user_authentication_state.user?.uid;
    const isAnonymous = action.payload.isAnonymous ?? !isUserLoggedIn(data.user_authentication_state);

    if (!userId) {
        log.info("Cannot subscribe to customer: missing UID (in action payload or in state)");
        return;
    }

    // We don't want to cancel and respawn the task if we're already listening to this customer
    if (userId === syncCustomerId) {
        return;
    }

    try {

        if (syncCustomerTask) {
            log.info("Cancelling previous customer listening task");
            yield cancel(syncCustomerTask);
        }

        if (isAnonymous) {
            log.debug(`User ${userId} is anonymous, do not subscribe`);
            return;
        }

        const customerSyncSuccessAction: any = {
            successActionCreator: (data: firebase.firestore.DocumentSnapshot) => {
                return actions.syncCustomer(data);
            }
        }

        const customerDocumentRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> = db.doc(getCustomerFirestoreDocPath(order.account_id, order.location_id, userId));
        syncCustomerTask = yield spawn(rsf.firestore.syncDocument, customerDocumentRef, customerSyncSuccessAction);
    }
    catch (error) {
        log.error(`Error while setting up the customer syncing process (uid: ${userId}).`)
    }
}

/**
 * This functions is triggered each time there is a modification on the customer
 * we're synced with (from firestore). It calls the API to update the contributor in the order
 * (and most recent) one.
 * @param action 
 */
function* syncUpdateCustomer(action?: SyncCustomerAction) {

    const { sessionId }: LocationState = (yield select((state: RootState) => state.locations)) as LocationState;

    const { order } = (yield select((state: RootState) => state.order)) as OrderState;
    yield call(addOrUpdateContributorViaAPI, order.id, sessionId);
}

/**
 * This functions is triggered each time there is a modification on the order
 * we're synced with (from firestore). It follows the flowchart "SyncOrder" (see README)
 * @param currentOrder the order in state before loading the one from firestore
 * @param newOrder the new order from firestore
 */
function* syncOrderLogic(currentOrder: Order, newOrder: OrderInBase, user_uid: string | undefined, tableLinkId: string) {

    // Still before submission (either draft or waiting_submission)
    if (orderService.isFullyEditable(currentOrder) &&
        orderService.isFullyEditable(newOrder)) {

        // A master user has been added
        if (!currentOrder.master_user_uid &&
            newOrder.master_user_uid) {

            log.info(`Current order master user: ${currentOrder.master_user_uid}, new order id: ${newOrder.master_user_uid}`)
            log.info(">-B-> A master user has been added");
            yield put(CustomerInformationModalActions.setCustomerInformationModal(
                CustomerInformationModalFormToDisplay.SHARED_ORDER_FINALIZE,
            ));
        }
        // The master user has been removed
        else if (currentOrder.master_user_uid && !newOrder.master_user_uid) {

            log.debug(">-B-> A master user has been removed");
            yield put(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(
                CustomerInformationModalFormToDisplay.SHARED_ORDER_FINALIZE,
            ));
        }
        // Status changed from DRAFT to WAITING_SUBMISSION
        else if (currentOrder.status === OrderStatus.DRAFT && newOrder.status === OrderStatus.WAITING_SUBMISSION) {

            log.debug(">-B-> DRAFT -> WAITING_SUBMISSION");

            // Current user is not the master
            if (newOrder.master_user_uid && newOrder.master_user_uid !== user_uid) {

                // Redirect to landing page
                log.debug(">-B-> User is not the master, redirect to landing page");
                yield put(CommonActions.setRedirectURL(`/${tableLinkId}${ROUTES.SharedOrderLanding}`));
                yield put(actions.openModal());
                yield put(CustomerInformationModalActions.closeCustomerInformationModalIfDisplayed(
                    CustomerInformationModalFormToDisplay.SHARED_ORDER_FINALIZE,
                ));
            }
        }
        // Status changed from WAITING_SUBMISSION to DRAFT
        else if (currentOrder.status === OrderStatus.WAITING_SUBMISSION && newOrder.status === OrderStatus.DRAFT) {

            log.debug(">-B-> WAITING_SUBMISSION -> DRAFT");

            // Redirect to catalog and remove the master user 
            yield put(CommonActions.setRedirectURL(ROUTES.getCategoriesFullRoute(tableLinkId)));
            yield put(actions.closeModal());
            yield put(orderActions.setMasterUser(null));
        }
    }
    // Status changed from WAITING_SUBMISSION or PENDING_PAYMENT to anything but DRAFT, WAITING_SUBMISSION or PENDING_PAYMENT
    else if (
        (
            currentOrder.status === OrderStatus.WAITING_SUBMISSION
            || currentOrder.status === OrderStatus.PENDING_PAYMENT
        )
        && newOrder.status !== OrderStatus.WAITING_SUBMISSION
        && newOrder.status !== OrderStatus.DRAFT
        && newOrder.status !== OrderStatus.PENDING_PAYMENT
    ) {

        log.debug(">-B-> WAITING_SUBMISSION or PENDING_PAYMENT -> anything but DRAFT or PENDING_PAYMENT");

        if (newOrder.status === OrderStatus.REJECTED ||
            newOrder.status === OrderStatus.REJECTED_PAYMENT) {
            // Redirect to error page 
            yield put(CommonActions.setRedirectURL(ROUTES.getErrorFullRoute(tableLinkId, newOrder.id)));
        } else {
            // Redirect to confirmation page 
            yield put(CommonActions.setRedirectURL(
                ROUTES.getOrderConfirmationFullRoute(tableLinkId, newOrder.id),
                undefined,
                RedirectUrlType.CONFIRM,
                RedirectUrlFrom.ORDER_SAGA,
            ));
        }
    }
}

/**Update the customer object of an order in firestore
* Also update the charges and the delivery zone ref
* @param customer
* @param orderId
* @param order
* @param collectionReference
*/
const updateCustomerAndCharges = async (customer: Customer | undefined, charges: OrderCharge[] | undefined,
    orderId: string, delivery_zone_ref: string | undefined, delivery: OrderDeliveryInfos | undefined, collectionReference: firestore.CollectionReference<firestore.DocumentData>) => {

    if (customer && charges) {

        if (delivery_zone_ref) {
            const orderUpdate = {
                customer: customer,
                charges: charges,
                delivery_zone_ref: delivery_zone_ref,
                delivery: delivery
            }
            removeUndefinedFromObject(orderUpdate);
            await collectionReference.doc(orderId).update(orderUpdate);
        }
        else {
            const orderUpdate = {
                customer: customer,
                charges: charges,
            }
            removeUndefinedFromObject(orderUpdate);
            await collectionReference.doc(orderId).update(orderUpdate);
        }
    }
}

/**
 * This function updates the usage of loyalty points.
 * If the user wants to use his points to get a loyalty discount
 * the boolean usePoints have to be setted to true;
 * @param action 
 */
function* updateLoyaltyPointsUsage(action: UpdateLoyaltyPointsUsageAction) {

    const { selectedLocation, selectedCatalog, selectedTable, sessionId } = (yield select((state: RootState) => state.locations)) as LocationState;
    const { order } = (yield select((state: RootState) => state.order)) as OrderState;

    // object could be undefined, so we split variables;
    const accountId: string = selectedLocation ? selectedLocation.account_id : "";
    const locationId: string = selectedLocation ? selectedLocation.id : "";

    const { usePoints, userId } = action.payload;

    try {
        if (orderService.isFullyEditable(order)) {

            const documentReference = db.doc(getOrderFirestoreDocPath(accountId, locationId, order.id));

            const newContributors = _.cloneDeep(order.contributors);
            const foundContributor = newContributors?.[userId];
            if (foundContributor) {
                foundContributor.use_points = usePoints;
            }
            const update: Partial<OrderInBase> = {
                contributors: newContributors,
                last_update_reason: OrderUpdateReason.TOGGLE_CONTRIBUTOR_USE_LOYALTY_POINTS,
            };

            yield call(rsf.firestore.updateDocument, documentReference, update);
        }
        else {

            const headers = new Headers();
            headers.append("Content-Type", "application/json");

            const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);
            headers.append("Authorization", `Bearer ${tokenResult.token}`);
            sessionId && headers.append(SESSION_ID_HEADER, sessionId);

            const fetchUrl = getApiEndpoint() + ordersApiRoutes.ORDER_TOGGLE_LOYALTY_USAGE.replace(ORDER_ID_PARAM, order.id);

            const fetchBody: LoyaltyToggleUsageRequest = {
                use_points: usePoints,
            }

            const requestOptions: any = {
                method: "POST",
                headers: headers,
                body: JSON.stringify(fetchBody),
            };

            const response: Response = yield fetch(fetchUrl, requestOptions);
            const result: LoyaltyToggleUsageResponse = yield response.json();

            if (!response.ok || !result) {

                console.warn(response);
                console.warn(result);
                log.error(`Earn loyalty response status: ${response.status}`, result);
                yield put(actions.earnLoyaltyError());
            }
            else {

                // Response ok, set the order in state if possible
                if (selectedCatalog && selectedLocation && selectedTable) {
                    yield put(orderActions.setOrder(result.order, selectedCatalog, selectedLocation, selectedTable));
                }
                else {
                    log.warn("Cannot set order after toggling loyalty usage: missing location or catalog. Waiting for the firebase update for the order to change.")
                }
            }
        }
    }
    catch (error) {
        log.error("Loyalty usage not updated", error);
    }
}

/**
 * When the order modal is closed, chose what to do. For example: change the status of
 * the order to draft ; go to PaymentInProgressPage
 * @param action 
 */
function* changeStatusOnModalClose(action: CloseModalAction) {

    const { order } = (yield select((state: RootState) => state.order)) as OrderState;

    // error TS7057 'yield' expression implicitly results in an 'any' type
    //@ts-ignore
    const { tableLinkId } = (yield select((state: RootState) => state.locations)) as LocationState;
    //const { hasChosenBetweenPayAndSeeMenu } = (yield select((state: RootState) => state.customerInformationModal)) as CustomerInformationModalState;

    log.debug("order saga: close modal -> change status or redirect")

    // If we close the modal while having the status WAITING_SUBMISSION (not from the landing page),
    // set the status to DRAFT and reset the master user
    if (order.status === OrderStatus.WAITING_SUBMISSION) {

        log.debug("Waiting_submission: setting the status to draft and removing the master user")

        yield put(actions.setStatusWaitingSubmission(false));
        yield put(actions.setMasterUser(null));
    }
}

async function fetchOrderByRefAPICall(
    orderRef: string,
    requestQuery: URLSearchParams,
    accountId: string,
    locationId: string,
    catalogId: string,
    tableId: string,
    sessionId: string | undefined,
): Promise<OrderInBase> {

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
    headers.append("Authorization", `Bearer ${tokenResult?.token}`);
    sessionId && headers.append(SESSION_ID_HEADER, sessionId);

    // Building the query
    requestQuery.append(ACCOUNT_KEY, accountId);
    requestQuery.append(LOCATION_KEY, locationId);
    requestQuery.append(CATALOG_KEY, catalogId);
    requestQuery.append(TABLE_KEY, tableId);
    requestQuery.append(ORDER_BY_RECEIPT_REF_KEY, "true");

    // Building the URL with the params & query
    const fetchUrl = getApiEndpoint()
        + ordersApiRoutes.ORDER_BY_ID
            .replace(ORDER_ID_PARAM, orderRef)
        + `?${requestQuery.toString()}`;

    const requestOptions: RequestInit = {
        method: "GET",
        headers: headers,
    };

    const response = await fetch(fetchUrl, requestOptions);

    if (!response.ok) {
        log.error(response.json());
        throw new Error(`Error while fetching the order with ref ${orderRef}`);
    }

    // Response ok, get the order creation response
    const result: OrderInBase = await response.json() as OrderInBase;
    return result;
}

function* earnLoyalty(action: EarnLoyaltyAction) {

    const { sessionId }: LocationState = yield select((state: RootState) => state.locations);

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    sessionId && headers.append(SESSION_ID_HEADER, sessionId);

    try {

        const tokenResult: firebase.auth.IdTokenResult = yield call(getIdToken);
        headers.append("Authorization", `Bearer ${tokenResult.token}`);

        const earnLoyaltyUrl = getApiEndpoint() + ordersApiRoutes.ORDER_EARN_LOYALTY.replace(ORDER_ID_PARAM, action.payload.orderId);

        const requestOptions: any = {
            method: "POST",
            headers: headers,
        };

        const response: Response = yield fetch(earnLoyaltyUrl, requestOptions);
        const result: EarnOrderLoyaltyResponse = yield response.json();

        if (!response.ok || !result) {

            console.warn(response);
            console.warn(result);
            log.error(`Earn loyalty response status: ${response.status}`, result);
            yield put(actions.earnLoyaltyError());
        }
        else {

            // Response ok, get the order creation response
            yield put(AuthenticationActions.setUserLoyaltyBalance(result.loyalty_result?.new_balance));
            yield put(actions.earnLoyaltySuccess(result.loyalty_result));
        }
    }
    catch (error) {

        log.error("Error while earning loyalty");
        log.error(error);
        yield put(actions.earnLoyaltyError());
    }
}

function* refreshOrderFromConnector(action: RefreshOrderFromConnectorAction) {

    const { selectedTable, selectedLocation, selectedCatalog, sessionId } = (yield select((state: RootState) => state.locations)) as LocationState;

    let accountId = action.payload.accountId;
    if (!accountId && selectedLocation) {
        accountId = selectedLocation.account_id;
    }
    let locationId = action.payload.locationId;
    if (!locationId && selectedLocation) {
        locationId = selectedLocation.id;
    }

    let catalogId = action.payload.catalogId;
    if (!catalogId && selectedCatalog && selectedCatalog.id) {
        catalogId = selectedCatalog.id;
    }

    let tableId = action.payload.tableId;
    if (!tableId && selectedTable) {
        tableId = selectedTable.id;
    }

    try {

        const tableOpenedOrders: TableOpenedOrdersResponse = yield call(
            refreshOrderFromConnectorAPICall,
            accountId,
            locationId,
            catalogId,
            tableId,
            sessionId,
        );

        const foundOrder = tableOpenedOrders.orders.find((order) => order.id === action.payload.currentOrderId);

        if (foundOrder) {

            if (selectedLocation && selectedCatalog && selectedTable) {
                const setOrderAction: SetOrderAction = {
                    type: SET_ORDER,
                    payload: {
                        catalog: selectedCatalog,
                        location: selectedLocation,
                        table: selectedTable,
                        order: foundOrder,
                    }
                }
                yield call(setDraftOrder, setOrderAction);
                yield put(orderActions.refreshOrderFromConnectorSuccess());
            }
            else {
                yield put(orderActions.refreshOrderFromConnectorError());
                log.info(`Could not update order in state from the connector: missing location or catalog`);
            }
        }
        else {
            yield put(orderActions.refreshOrderFromConnectorError());
        }
    }
    catch (error) {
        log.error(error);
        yield put(orderActions.refreshOrderFromConnectorError());
    }
}

async function refreshOrderFromConnectorAPICall(
    accountId: string,
    locationId: string,
    catalogId: string,
    tableId: string,
    sessionId: string | undefined,
): Promise<TableOpenedOrdersResponse> {

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
    headers.append("Authorization", `Bearer ${tokenResult?.token}`);
    sessionId && headers.append(SESSION_ID_HEADER, sessionId);

    const fetchTableLatestOrdersRequest: TableOpenedOrdersRequest = {
        account_id: accountId,
        catalog_id: catalogId,
        location_id: locationId,
        table_id: tableId,
        limit: 0,  // TODO: how many?
    }

    const raw = JSON.stringify(fetchTableLatestOrdersRequest);
    log.debug(`Sending table latestOrders request`, raw);

    const requestOptions: any = {
        method: "POST",
        headers: headers,
        body: raw,
    }

    const apiTableLatestUrl = `${getApiEndpoint()}${ORDERS_FETCH_TABLE}`;
    const response = await fetch(apiTableLatestUrl, requestOptions);

    if (!response.ok) {
        log.error(response.json());
        throw new Error(`Error while fetching the orders for table ${tableId} (account ${accountId} / location ${locationId} / catalog ${catalogId})`);
    }

    // Response ok, get the order creation response
    const result: TableOpenedOrdersResponse = await response.json() as TableOpenedOrdersResponse;
    return result;
}

/**
 * Add or udpate a contributor via API.
 * The user UID is contained in the token sent to the API.
 * @param orderId 
 */
const addOrUpdateContributorViaAPI = async (orderId: string, sessionId: string | undefined): Promise<boolean> => {

    try {

        const headers = new Headers();
        headers.append("Content-Type", "application/json");

        const tokenResult: firebase.auth.IdTokenResult | null = await getIdToken();
        headers.append("Authorization", `Bearer ${tokenResult?.token}`);
        sessionId && headers.append(SESSION_ID_HEADER, sessionId);

        const fetchUrl = getApiEndpoint() + ordersApiRoutes.ORDER_ADD_CONTRIBUTOR.replace(ORDER_ID_PARAM, orderId);

        const requestOptions: any = {
            method: "POST",
            headers: headers,
        };

        const response: Response = await fetch(fetchUrl, requestOptions);
        const result: OrderAddContributorResponse = await response.json();

        if (!response.ok || !result) {
            console.warn(response);
            console.warn(result);
            log.error(`Add contributor response status: ${response.status}`, result);
            return false;
        }

        return true;
    }
    catch (error) {
        log.error(`Could not add contributor via API: ${getErrorMessage(error)} (${getErrorStack(error)})`);
        return false;
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(SEND_ORDER, postOrder),
        takeLatest(LOAD_ORDERS, loadOrders),
        takeLatest(SEND_ADDED_ITEMS, sendAddedItems),
        takeLatest(ADD_ITEM, setDraftOrder),
        takeLatest(ADD_ITEMS, setDraftOrder),
        takeLatest(ADD_DEAL, setDraftOrder),
        takeLatest(EDIT_DEAL, setDraftOrder),
        takeLatest(REMOVE_DEAL, setDraftOrder),
        takeLatest(REMOVE_ITEM, setDraftOrder),
        takeLatest(RESET_ITEMS, setDraftOrder),
        takeLatest(RESET_ORDER, setDraftOrder),
        takeLatest(CREATE_DRAFT_ORDER, setDraftOrder),
        takeLatest(ADD_CONTRIBUTOR, setDraftOrder),
        takeLatest(UPDATE_CONTRIBUTOR, setDraftOrder),
        takeLatest(ADD_DISCOUNT_TO_ORDER, setDiscounts),
        takeLatest(SET_CHARGE, setDraftOrder),
        takeLatest(SET_PICKUP, setDraftOrder),
        takeLatest(SET_EXPECTED_TIME, setDraftOrder),
        takeLatest(SET_DELIVERY_ZONE, setDraftOrder),
        takeLatest(SET_CUSTOMER_INFO, setDraftOrder),
        takeLatest(SET_CUSTOMER_NOTES, setDraftOrder),
        takeLatest(SET_MASTER_USER, setDraftOrder),
        takeLatest(SET_STATUS_WAITING_SUBMISSION, setDraftOrder),
        takeLatest(UPDATE_ITEM_NOTE, setDraftOrder),
        takeLatest(SET_ORDER, setDraftOrder),  // the property ignoreDraft is important in the payload to avoid infinite loops
        takeLatest(SET_ORDER, setupSyncOrder),  // the property ignoreDraft is important in the payload to avoid infinite loops
        takeLatest(MERGE_ORDER, setupSyncOrder),
        takeLatest(SYNC_ORDER, syncUpdateOrder),
        takeLatest(SYNC_CUSTOMER, syncUpdateCustomer),
        takeLatest(CLOSE_MODAL, changeStatusOnModalClose),
        takeLatest(UPDATE_LOYALTY_POINTS_USAGE, updateLoyaltyPointsUsage),
        takeLatest(LOAD_AVAILABLE_TIMESLOTS, loadAvailableTimeSlots),
        takeLatest(SET_LOYALTY_USER_ID, setLoyaltyUserId),
        takeLatest(EARN_LOYALTY, earnLoyalty),
        takeLatest(REFRESH_ORDER_FROM_CONNECTOR, refreshOrderFromConnector),
        takeLatest(SETUP_SYNC_CUSTOMER, setupSyncCustomer),
        takeLatest(REMOVE_DISCOUNT_FROM_ORDER, removeDiscountFromOrder)
    ]);
}
