import { Result } from '@mapbox/mapbox-gl-geocoder';
import { getAlpha2Code } from 'i18n-iso-countries';
import phone from 'phone';
import { DEFAULT_LANGUAGE } from '../../../model/Catalog';
import { DEFAULT_COUNTRY } from '../../../model/Location';
import { GeographyFilter, LocationAddress } from '../../locations/models/GeographyFilter';
import Address from '../models/Address';
import { MapBoxContext } from '../models/MapboxIdShortCut';
import MapboxPlaceType from '../models/MapboxPlaceType';
import Position from '../models/Position';
import codesJson from './assets/country_and_sub_region_codes.json';
import { log } from './LogService';
const regions = require('@etalab/decoupage-administratif/data/regions.json');
const departments = require('@etalab/decoupage-administratif/data/departements.json');

export const fixCountryCodeIfNeeded = (address: { country?: string; }, language?: string): void => {
    if (address.country) {
        if (address.country.length === 2) {
            address.country = address.country.toUpperCase();
        } else {
            const countryCode = getAlpha2Code(address.country, language ? language : DEFAULT_LANGUAGE);
            if (countryCode) {
                address.country = countryCode;
            } else {
                address.country = DEFAULT_COUNTRY;
            }
        }
    }
}

export const fixPhone = (customer: { phone?: string }, likelyCountryCode?: string): boolean => {
    if (customer.phone) {
        if (!customer.phone.startsWith("+")) {
            // Try first to add a plus sign
            const phoneWithPlus = `+${customer.phone}`;
            const checkedPhone = likelyCountryCode ? phone(phoneWithPlus, { country: likelyCountryCode }) : phone(phoneWithPlus);
            if (checkedPhone.isValid) {
                customer.phone = checkedPhone.phoneNumber;
                return true;
            } else if (likelyCountryCode) {
                const checkedPhone = phone(customer.phone, { country: likelyCountryCode });
                if (checkedPhone.isValid) {
                    customer.phone = checkedPhone.phoneNumber;
                    return true;
                }
            }
        } else {
            const checkedPhone = phone(customer.phone);
            if (checkedPhone.isValid) {
                customer.phone = checkedPhone.phoneNumber;
                return true;
            } else {
                log.error(`The phone seems to be invalid: ${customer.phone}`);
            }
        }
    }
    return false;
}

type PartialAddress = Pick<LocationAddress, "country" | "city" | "address" | "postal_code" | "administrative_area1" | "administrative_area1_name">

type AddressAdministrativeArea = Pick<LocationAddress, "administrative_area0" | "administrative_area0_name" | "administrative_area1" | "administrative_area1_name">

export function extractAddressFromFeature(context: MapBoxContext[] | undefined) {
    const location_address: PartialAddress = {};

    if (!(Array.isArray(context) && context.length > 0)) {
        return location_address
    }

    context.forEach((item) => {
        if (item.id.startsWith('country')) {
            location_address.country = item.short_code.toUpperCase();
        } else if (item.id.startsWith('region')) {
            location_address.administrative_area1 = item.short_code.toUpperCase();
            location_address.administrative_area1_name = item.text;
        } else if (item.id.startsWith('place')) {
            location_address.city = item.text;
        } else if (item.id.startsWith('locality')) {
            location_address.address = item.text;
        } else if (item.id.startsWith('postcode')) {
            location_address.postal_code = item.text;
        }
    });

    return location_address;
}

export const fixFrenchAdministrativeArea = (geography: Pick<LocationAddress, "country" | "administrative_area1" | "administrative_area1_name">): AddressAdministrativeArea => {
  const administrative_area: AddressAdministrativeArea = {}

  switch(geography.country){
    case "FR": {
  
  
      if (!geography?.administrative_area1) {
         break;
      }
  
      const departmentCode = geography?.administrative_area1?.replace('FR-', '');
  
      const foundDepartment = departments.find((department: any) => department.code === departmentCode);
  
      if (!foundDepartment) {
        break;
      }
  
      const foundRegion = regions.find((region: any) => region.code === foundDepartment.region);
  
      if (foundRegion) {
          administrative_area.administrative_area0 = `FR-${foundRegion.code}`;
          administrative_area.administrative_area0_name = foundRegion.nom;
      }
  
      break;
    }

    case "NL": {
      administrative_area.administrative_area0 = geography.administrative_area1;
      administrative_area.administrative_area0_name = geography.administrative_area1_name;

      administrative_area.administrative_area1 = undefined;
      administrative_area.administrative_area1_name = undefined;

      break;
    }

    default: {
      break;
    }
  }
   return administrative_area;
}

export const transformMapboxLocationToLocationAddress = (result: Result): GeographyFilter | undefined => {
    let geography: GeographyFilter = {
        id: typeof result.id === 'string' ? result.id : '',
        name: result.text,
        place_type: result?.place_type?.[0] as MapboxPlaceType,
    };

    /**
     * In case of country, we don't have a context so we just return the country
     */
    if (geography.place_type === MapboxPlaceType.COUNTRY) {
        geography = {
            ...geography,
            country: result?.properties?.short_code?.toUpperCase?.(),
        }

        if (result.center) {
            geography = {
                ...geography,
                position: {
                    latitude: result.center[1],
                    longitude: result.center[0],
                },
            }
        }

        return geography;
    }

    const place_type = geography.id.split('.').shift() as MapboxPlaceType;

    switch (place_type) {
        case MapboxPlaceType.POI: {
            const partial_address = extractAddressFromFeature(result.context);

            geography = {
                ...geography,
                ...partial_address,
                place_type,
                name: result.text,
                address: result?.properties?.address
            };

            break;
        }

        case MapboxPlaceType.POSTCODE: {
            const partial_address = extractAddressFromFeature(result.context);

            geography = {
                ...geography,
                ...partial_address,
                place_type,
                name: result.place_name,
                postal_code: result.text,
            };

            break;
        }

        case MapboxPlaceType.PLACE: {
            const partial_address = extractAddressFromFeature(result.context);

            geography = {
                ...geography,
                ...partial_address,
                place_type,
                name: result.place_name,
                city: result.text,
            };

            break;
        }

        case MapboxPlaceType.REGION: {
            const partial_address = extractAddressFromFeature(result.context);

            geography = {
                ...geography,
                ...partial_address,
                place_type,
                name: result.place_name,
                administrative_area1: result?.properties?.short_code?.toUpperCase(),
                administrative_area1_name: result.text,
            };

            break;
        }

        case MapboxPlaceType.NEIGHBORHOOD: {
            const partial_address = extractAddressFromFeature(result.context);

            geography = {
                ...geography,
                ...partial_address,
                place_type,
                name: result.place_name
            };

            break;
        }

        case MapboxPlaceType.ADDRESS: {
            const partial_address = extractAddressFromFeature(result.context);

            const { address, text } = result;

            geography = {
                ...geography,
                ...partial_address,
                place_type,
                name: result.place_name,
                address: [address, text].filter(Boolean).join(' '),
            };

            break;
        }

        case MapboxPlaceType.LOCALITY: {
            const partial_address = extractAddressFromFeature(result.context);

            geography = {
                ...geography,
                ...partial_address,
                place_type,
                name: result.place_name
            };

            break;
        }

        default: {
            return undefined;
        }
    }

    if (result.center) {
        const position: Position = {
            latitude: result.center[1],
            longitude: result.center[0],
        };

        geography = {
            ...geography,
            position,
        };
    }

    return geography;
}

export const getGeographyFilterFromMapboxResponse = (response: Result): GeographyFilter | null => {
    const filter = transformMapboxLocationToLocationAddress(response);

    if (!filter) {
        return null
    }

    let geography: GeographyFilter = filter;

    geography = {
        ...geography,
        ...fixFrenchAdministrativeArea(geography)
    }

    return geography;
};

export const getAddressFromMapboxResult = (result: Result): Address => {
    log.debug(`define address from mapbox result`, result);

    const address_1 =
        !!result?.properties?.address
            ? result?.properties?.address
            : `${result?.address ?? ''} ${result?.text ?? ''}`;

    let address: Address = {
        address_1,
        city: '',
        country: '',
        postal_code: '',
        name: result.text,
    };

    result.context?.forEach((elem: MapBoxContext) => {
        if (typeof elem.id !== 'string') {
            return;
        }

        const shortcut = elem.id.split('.').shift() as MapboxPlaceType;

        switch (shortcut) {
            case MapboxPlaceType.COUNTRY: {
                if (elem.short_code) {
                    address = {
                        ...address,
                        country: elem.short_code.toUpperCase(),
                    };
                }

                break;
            }

            case MapboxPlaceType.POSTCODE: {
                address = {
                    ...address,
                    postal_code: elem.text,
                };

                break;
            }

            case MapboxPlaceType.PLACE: {
                address = {
                    ...address,
                    city: elem.text,
                };

                break;
            }

            case MapboxPlaceType.REGION: {
                address = {
                    ...address,
                    administrative_area1_name: elem.text,
                };

                if (elem.short_code) {
                    address = {
                        ...address,
                        administrative_area1: elem.short_code.toUpperCase(),
                    };
                }

                break;
            }
        }
    });

    address = {
        ...address,
        ...fixFrenchAdministrativeArea(address)
    }

    // Now the coordinates
    if (result.center) {
        const position: Position = {
            latitude: result.center[1],
            longitude: result.center[0],
        };

        address = {
            ...address,
            position,
        };
    }

    log.debug('address define', address);

    return address;
};

interface CountryAndSubRegionCode {
    country_code: string;
    sub_region_code: string;
}

/**
 * Gets all the country codes in the region of the given country.
 * Used to preset some countries in phoneSelector
 * @param country
 * @returns
 */
export const getSameSubRegionCountryCodes = (country: string, upperCase?: boolean): string[] => {
    const countryCode = country.toLowerCase();

    const codes = codesJson as CountryAndSubRegionCode[];
    codes.forEach((c) => c.country_code = c.country_code.toLowerCase());

    const foundCode = codes.find(c => c.country_code === countryCode);

    if (!foundCode) {
        return [upperCase ? countryCode.toUpperCase() : countryCode];
    }

    const preferredCountries = codes.filter(c => c.sub_region_code === foundCode.sub_region_code);

    if (upperCase) {
        return preferredCountries.map(c => c.country_code.toUpperCase());
    }

    return preferredCountries.map(c => c.country_code);
}
