import { useMemo } from "react";
import { Product } from "../../my-lemonade-library/model/Catalog";
import { isProductAlcohol } from "../../my-lemonade-library/src/products/helpers/ProductHelpers";
import { RootState, useTypedSelector } from "../../redux/root-reducer";

interface DealSelectedSkus {
    skuRef: string
    product: Product | null
}

export default function useShowAlcoholDisclaimer(entity: Product | DealSelectedSkus[] | null, isDeal?: boolean): boolean {
    const { order, user_authentication_state } = useTypedSelector((state: RootState) => ({
        order: state?.order?.order,
        user_authentication_state: state?.authentication?.data?.user_authentication_state
    }))

    const hasAcceptedAlcoholTerms = useMemo(() => {
        const user_id = user_authentication_state?.user?.uid;

        if (!user_id) {
            return false;
        }

        const contributor = order?.contributors?.[user_id];

        return !!contributor?.has_accepted_alcohol
    }, [order.contributors, user_authentication_state]);

    const shouldDisplayAlcoholDisclaimer = useMemo(() => {
        if (hasAcceptedAlcoholTerms) {
            return false;
        }

        if (!entity) {
            return false;
        }


        if (isDeal && Array.isArray(entity)) {
            return entity.some((deal) => !!(deal?.product && isProductAlcohol(deal.product)))
        }

        return isProductAlcohol(entity as Product);
    }, [entity, hasAcceptedAlcoholTerms, isDeal]);

    return shouldDisplayAlcoholDisclaimer;
};