import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import { Box, Button, Fab, Icon, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DeviceContext } from '../../App';
import { defaultMyLemonadeButtonBoxShadow } from '../../config/theme';
import { Money, MoneyToStringWithSymbol } from '../../my-lemonade-library/src/common/models/Money';
import { AddButtonRedirect } from '../models/AddButtonRedirect';

interface AddButtonProps {
    price: Money;
    isButtonDisabled: boolean;
    hidePrice?: boolean;
    displayShadow?: boolean;
    displayFastCheckoutButton: boolean;
    buttonTitleId?: string;
    onSubmit: (redirect: AddButtonRedirect) => void;
}

const AddButton: React.FC<AddButtonProps> = (props) => {

    const {
        price,
        isButtonDisabled,
        hidePrice = false,
        displayShadow,
        displayFastCheckoutButton,
        buttonTitleId,
        onSubmit
    } = props;

    const theme = useTheme();
    const intl = useIntl();

    const { mobile_device } = useContext(DeviceContext);

    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            width={1}
        >

            <Button
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                data-test="order_addButton"
                onClick={() => onSubmit(AddButtonRedirect.CATALOG)}
                style={{
                    wordWrap: "break-word",
                    border: "thin",
                    marginTop: theme.spacing(3),
                    padding: theme.spacing(1.5, 2),
                    width: mobile_device ? '100%' : "300px",
                    margin: mobile_device ? "unset" : "auto",
                    boxShadow: displayShadow ? defaultMyLemonadeButtonBoxShadow : "none",
                    textTransform: "none",
                }}
            >

                <Box
                    display='flex'
                    width={1}
                    justifyContent={displayFastCheckoutButton ? "space-between" : undefined}
                    paddingX={1}
                >
                    {!displayFastCheckoutButton && <Box flex={1} />}

                    <Typography
                        variant='h5'
                    >
                        {intl.formatMessage({ id: buttonTitleId ?? `Order.addButton.add` })}
                    </Typography>

                    {
                        // TODO: must be undefined, see ProductDetailPage  // GOTO 5DR3
                        (!hidePrice && price !== "")
                            ? <Box
                                flex={displayFastCheckoutButton ? undefined : 1}
                            >
                                <Typography
                                    variant='body1'
                                    style={{
                                        textAlign: 'end',
                                    }}
                                >
                                    {MoneyToStringWithSymbol(price)}
                                </Typography>
                            </Box>
                            : <Box flex={1} />
                    }

                </Box>
            </Button>

            {displayFastCheckoutButton &&
                <Box
                    alignSelf="flex-end"
                    data-test="product.modal.fast_checkout_button"
                >
                    <Fab
                        color="primary"
                        size='medium'
                        sx={{
                            boxShadow: 'none',
                        }}
                        disabled={isButtonDisabled}
                        onClick={() => onSubmit(AddButtonRedirect.SUMMARY_PAGE)}
                    >
                        <Icon
                            component={ShoppingCartCheckoutIcon}
                            style={{ color: theme.palette.common.white }}
                        />
                    </Fab>
                </Box>
            }
        </Box>
    )
}

export default AddButton