import { Box, Dialog, useTheme } from '@mui/material';
import log from 'loglevel';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generatePath, Route, useHistory, useRouteMatch } from "react-router-dom";
import { DeviceContext } from '../../App';
import CategoriesList from '../../Categories/pages/desktop/CategoriesList';
import { LARGE_DIALOG_WIDTH, MEDIUM_DIALOG_WIDTH } from '../../Common/configs/DialogSizeConst';
import { RADIUS_MODAL } from '../../Common/configs/styleVariable';
import { useHeaderHeight } from '../../config/hooks/useHeaderHeight';
import * as ROUTES from '../../config/routes';
import { DESKTOP_MENU_BORDER_RADIUS } from '../../config/theme';
import MenuList from '../../deals/pages/desktop/MenuList';
import Menu from "../../deals/pages/Menu";
import { Catalog } from '../../my-lemonade-library/model/Catalog';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { paymentHelper } from '../../my-lemonade-library/src/payments/services/PaymentHelper';
import suggestionService from '../../my-lemonade-library/src/suggestions/services/SuggestionService';
import { ORDER_ID_QUERY_PARAM_NAME } from '../../orders/configs/OrdersRouterConfig';
import OrderSummaryPage from '../../orders/pages/OrderSummaryPage';
import OrderAction, { orderActions } from '../../orders/redux/OrderActions';
import ProductDetailPage from '../../Products/component/ProductDetailPage';
import ProductsPageDesktop from '../../Products/pages/desktop/ProductsPageDesktop';
import { useTypedSelector } from '../../redux/root-reducer';
import RouterComponent from "../components/Navigation";

interface LocationHomeDeskTopProps { }

/**
 * Main Screen on desktop version
 * @param props 
 */
const LocationHomeDeskTop: React.FC<LocationHomeDeskTopProps> = (props) => {

    const { selectedCatalog, selectedLocation, selectedTable } = useTypedSelector(state => state.locations);
    const { order, openModal } = useTypedSelector(state => state.order);

    const dispatch = useDispatch();
    const history = useHistory();
    const match = useRouteMatch<ROUTES.RouteParams>();
    const theme = useTheme();
    const { desktop_device } = useContext(DeviceContext);
    const headerHeight = useHeaderHeight();

    const [suggestionToDisplay, setSuggestionToDisplay] = useState<boolean>(false)

    useEffect(() => {
        const suggestion = suggestionService.getSuggestionProductsByOrder(order, selectedCatalog as Catalog)
        if (suggestion) {
            setSuggestionToDisplay(true)
        } else {
            setSuggestionToDisplay(false)
        }
    }, [order, selectedCatalog])

    /**
     * What happens if the user closes the main modal(can be a clic on the X but also
     * a clic outside of the modal or pressing escape)
     */
    const onModalClose = () => {

        if (selectedLocation && paymentHelper.isOrderToCreditWallet(selectedLocation, order)) {
            log.info('Cannot close the modal because we are in the wallet case.')
            return; // Do not close the modal if we are in the wallet case
        }

        // If it's displaying the landing page, you cannot close the modal
        if (!history.location.pathname.includes(ROUTES.SharedOrderLanding)) {
            history.push(generatePath(ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }))
            dispatch(OrderAction.closeModal())
        }
    }

    /**
     * If we open the WA on the summary page without an order id, redirect to categories.
     * If we have an order id, open the modal to show the summary page
     */
    useEffect(() => {

        if (history.location.pathname.includes(ROUTES.OrderSummaryPage)) {

            if (history.location.search.includes(ORDER_ID_QUERY_PARAM_NAME)) {

                dispatch(orderActions.openModal());
            }
            else {

                history.push(
                    generatePath(
                        ROUTES.LocationHome + ROUTES.CategoriesPage, { tableLinkKey: match.params.tableLinkKey }
                    )
                );

            }
        }

    }, []);

    if (selectedCatalog && selectedLocation && selectedTable && order) {
        return (
            <Box
                p={4}
                mt={headerHeight}
                height="100%"
                display="flex"
                flexWrap="nowrap"
                alignItems="stretch"
                justifyContent="center"
                style={{
                    margin: "0 auto", // margin left and right -> align at the center
                }}
                bgcolor={theme.palette.background.default}
            >
                <Box
                    width={desktop_device ? "15%" : "25%"}
                    minWidth="300px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    style={{
                        backgroundColor: 'transparent',
                        height: '100%',
                        overflowY: 'auto'
                    }}
                >

                    {(!selectedCatalog.display || !selectedCatalog.display.hide_deals_desktop_menu) &&
                        <MenuList />
                    }

                    <Box style={{ backgroundColor: theme.palette.background.paper, borderRadius: DESKTOP_MENU_BORDER_RADIUS }} >
                        <CategoriesList />
                    </Box>

                </Box>

                <Box
                    flex={1}
                    id="productContainer"
                    paddingLeft={3}
                    width={desktop_device && order.service_type !== SupportedServiceType.VIEW ? "45%" : "unset"}
                    minWidth={desktop_device ? "380px" : "unset"}
                    style={{
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                    m={0}
                >
                    <Route exact path={ROUTES.LocationHome + ROUTES.CategoriesPage} component={ProductsPageDesktop} />

                    <Route exact path={ROUTES.LocationHome + ROUTES.Menu}>
                        <Menu />
                    </Route>
                    <Route exact path={ROUTES.LocationHome + ROUTES.ProductDetail}  >
                        <ProductDetailPage />
                    </Route>

                </Box>

                {order.service_type !== SupportedServiceType.VIEW &&
                    desktop_device &&

                    <Box
                        width={"27%"}
                        minWidth={"220px"}
                        maxHeight="100%"
                        height="fit-content"
                        style={{
                            overflowY: 'auto'
                        }}
                        paddingLeft={3}
                    >
                        <OrderSummaryPage />
                    </Box>
                }

                <Dialog
                    fullWidth={history.location.pathname.includes(ROUTES.OrderSummaryPage) && suggestionToDisplay}
                    open={openModal}
                    onClose={onModalClose}
                    disableEnforceFocus={true}
                    maxWidth={'md'}
                    disableEscapeKeyDown
                    PaperProps={{
                        style: {
                            width: history.location.pathname.includes(ROUTES.OrderSummaryPage) && suggestionToDisplay ? LARGE_DIALOG_WIDTH : MEDIUM_DIALOG_WIDTH,
                            maxHeight: '700px',
                            overflowY: 'auto',
                            borderRadius: RADIUS_MODAL,
                        }
                    }}
                >
                    <RouterComponent />
                </Dialog>
            </Box >
        )
    } else {
        return null
    }
}

export default LocationHomeDeskTop;