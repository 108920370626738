import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { DeviceContext } from '../../../App';
import DealExt from "../../../my-lemonade-library/model/DealExtended/DealExt";
import translationService from "../../../my-lemonade-library/src/translations/services/TranslationService";
import { getDealTranslation } from '../../service/dealDisplayService';

interface DealDisplayHeaderProps {
    selectedDeal: DealExt;
}

const DealDisplayHeader: React.FC<DealDisplayHeaderProps> = (props) => {
    const {
        selectedDeal
    } = props;

    const { mobile_device } = useContext(DeviceContext);
    const theme = useTheme();
    const intl = useIntl();
    const history = useHistory();

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            padding={mobile_device ? '10px 20px' : '5px'}
        >
            {!mobile_device &&
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => history.goBack()}
                    style={{
                        maxWidth: 'max-content',
                        background: theme.palette.common.white,
                        borderColor: theme.palette.grey[300]
                    }}
                    startIcon={
                        <ArrowBackIosIcon
                            style={{
                                fontSize: '16px',
                                marginLeft: '5px'
                            }}
                            sx={{ color: theme.palette.secondary.main }}
                        />
                    }
                >
                    {intl.formatMessage({ id: "product_detail.back.button" })}
                </Button>
            }
            <Box
                display={'flex'}
                flexDirection={'column'}
                gap={2}
            >
                <Typography variant='h1'>
                    {getDealTranslation(intl, translationService.getDealNameTranslationKey(selectedDeal), selectedDeal.name)}
                </Typography>

                {selectedDeal.description && mobile_device &&
                    <Box
                        px={mobile_device ? 0 : 2}
                        mb={2}
                        width="100%"
                        alignSelf="center"
                        textAlign="left"
                        color={theme.palette.text.disabled}
                    >
                        <Typography variant="body1">
                            {getDealTranslation(intl, translationService.getDealDescriptionTranslationKey(selectedDeal), selectedDeal.description)}
                        </Typography>
                    </Box>
                }
            </Box>
        </Box >
    )
}

export default DealDisplayHeader;