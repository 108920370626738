import { Box } from "@mui/material";
import { useContext } from "react";
import { DeviceContext } from "../../../App";
import AddButton from "../../../Common/components/AddButton";
import EditButton from "../../../Common/components/EditButton";
import { AddButtonRedirect } from "../../../Common/models/AddButtonRedirect";
import { CatalogExtended } from "../../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import { numberToMoney } from "../../../my-lemonade-library/src/common/models/Money";

interface DealActionButtonProps {
    selectedCatalog?: CatalogExtended;
    isDealEdition: boolean;
    currentDealPrice: number;
    isButtonDisabled: boolean;
    handleSubmitDeal: (redirect: AddButtonRedirect) => void;
    handleEditDeal: (redirect: AddButtonRedirect) => void;
}

const DealActionButton: React.FC<DealActionButtonProps> = (props) => {
    const {
        selectedCatalog,
        isDealEdition,
        currentDealPrice,
        isButtonDisabled,
        handleSubmitDeal,
        handleEditDeal
    } = props;

    const { mobile_device, desktop_device } = useContext(DeviceContext)

    if (!selectedCatalog) return null;

    const buttonActionsMobileStyling = {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        background: 'white',
        paddingBottom: '20px',
        boxShadow: '0px -1px 28px 0px rgba(0,0,0,0.18)',
        padding: '10px 20px'
    }

    const buttonsActionsDesktopStyling = {
        padding: '5px'
    }

    return (
        <Box
            style={mobile_device ? buttonActionsMobileStyling : buttonsActionsDesktopStyling}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            {
                isDealEdition ?
                    <EditButton onDealEdition={handleEditDeal} />
                    :
                    <AddButton
                        onSubmit={handleSubmitDeal}
                        price={numberToMoney(currentDealPrice, selectedCatalog.currency)}
                        isButtonDisabled={isButtonDisabled}
                        displayShadow
                        displayFastCheckoutButton={!desktop_device}
                    />
            }
        </Box>
    );
}

export default DealActionButton;