import log from "loglevel";
import { IntlShape } from "react-intl";
import { isTemporallyAvailableForOrder } from "../../my-lemonade-library/functions/Helpers";
import { Deal, DealLine, getTimezoneName, Product, Sku } from "../../my-lemonade-library/model/Catalog";
import { CatalogExtended } from "../../my-lemonade-library/model/catalogExtended/CatalogExtended";
import DealExt from "../../my-lemonade-library/model/DealExtended/DealExt";
import { Order, OrderItem } from "../../my-lemonade-library/model/Order";
import { moneyToNumber, numberToMoney } from "../../my-lemonade-library/src/common/models/Money";
import FullDealSku from "../../my-lemonade-library/src/deals/models/FullDealSku";
import { computeSkuDealPriceNumber, getDealLineRef } from "../../my-lemonade-library/src/deals/services/DealHelper";

export const updateOrderItem = (selectedDeal: DealExt, product: Product, skuSelected: Sku, lineIndex: number, dealKey: string): OrderItem => {
    const orderItems: OrderItem = {
        product_ref: product.ref,
        product_name: product.name,
        sku_ref: skuSelected.ref,
        quantity: 1,
        options: [],
        price: selectedDeal.lines[lineIndex].pricing_value as string,
        deal_line: {
            deal_key: dealKey,
            deal_line_ref: getDealLineRef(selectedDeal, lineIndex),
            deal_line_index: lineIndex
        }
    }
    return orderItems;
}

export const getFullDealSkuList = (line: DealLine, selectedCatalog: CatalogExtended, order: Order): FullDealSku[] => {
    let productIncluded: FullDealSku[] = []
    let minLinePrice = Number.MAX_VALUE;

    line.skus.forEach(dealSku => {
        const productList = selectedCatalog.data.products;

        productList.forEach((product: Product) => {
            const productAvailable = !product.disable && (!product.restrictions || isTemporallyAvailableForOrder(order, getTimezoneName(selectedCatalog), product.restrictions, false));
            product.skus.forEach((sku: Sku) => {
                if (sku.ref === dealSku.ref && !sku.disable && productAvailable) {
                    const dealPrice = computeSkuDealPriceNumber(sku, line) ?? 0;
                    if (dealPrice < minLinePrice) minLinePrice = dealPrice;

                    const productInDeal: FullDealSku = {
                        ...dealSku,
                        product: product,
                        sku: sku,
                        deal_price: numberToMoney(dealPrice ? dealPrice : 0, selectedCatalog.currency)
                    }
                    productIncluded.push(productInDeal)

                }
            })
        })

        // Now that we have computed the price for each, conpute the full extra charge
        productIncluded.forEach((fullDealSku) => {
            const fullExtraCharge = moneyToNumber(fullDealSku.deal_price, false, fullDealSku) - minLinePrice;
            fullDealSku.full_extra_charge = fullExtraCharge ? numberToMoney(fullExtraCharge, selectedCatalog.currency) : undefined;
        });
    })

    return productIncluded;
}

export const getDealTranslation = (
    intl: IntlShape,
    translationKey?: string,
    defaultTranslation?: string
): string | undefined => {
    if (translationKey && intl.messages[translationKey]) {
        return intl.formatMessage({ id: translationKey });
    }

    log.debug(`Missing translation for deal (name or description id: ${translationKey})`);
    return defaultTranslation;
}

export const getDealBackgroundToRender = (deal: Deal, selectedCatalog?: CatalogExtended): string | null => {
    if (deal.image) return deal.image;

    const skuRefs = deal.lines.flatMap(line => line.skus.map(sku => sku.ref));
    for (const skuRef of skuRefs) {
        const product = (selectedCatalog?.data?.products || []).find(product =>
            product.skus.some(sku => sku.ref === skuRef)
        );
        if (product?.image) return product.image;
    }
    return null;
};
