import _ from "lodash";
import { DateTime } from "luxon";
import moment, { Moment } from "moment-timezone";
import { getDealApply, isApplicable, isTemporallyAvailableForOrder, SkuQuantityHelper } from "../../../functions/Helpers";
import { Catalog, Deal, DealLine, getTimezoneName, Option, OptionList, PricingEffect, Product, Sku } from "../../../model/Catalog";
import { Location, SupportedServiceType } from "../../../model/Location";
import { Order, OrderCharge, OrderChargeType, OrderDeal, OrderDealLine, OrderItem, OrderOption } from "../../../model/Order";
import { OrderError } from "../../../model/OrderError";
import { SignInProviders } from "../../authentications/models/BaseUser";
import { Customer } from "../../authentications/models/Customer";
import { addMoney, Money, moneyToNumber, multiplyMoney, numberToMoney, substractMoney } from "../../common/models/Money";
import { log } from "../../common/services/LogService";
import { checkRestrictionAndGetDealNumberOfUsagesLeft } from "../../deals/services/DealHelper";
import deliveryService from "../../delivery/services/DeliveryHelper";
import discountService from "../../discounts/service/DiscountService";
import { ComputeLoyaltyResult } from "../../loyalties/models/ComputeLoyaltyResult";
import { LoyaltyLocationConfig } from "../../loyalties/models/LoyaltyLocationConfig";
import { computeLoyaltyForAllUsers } from "../../loyalties/services/LoyaltyHelper";
import { feesService } from "../../payments/services/FeesService";
import { PriceOverride } from "../../products/models/PriceOverride";
import productService from "../../products/services/ProductService";
import { Restriction } from "../../restrictions/model/Restriction";
import { getRestrictionsArray, isMatchingTemporalRestriction } from "../../restrictions/services/RestrictionsService";
import taxHelper from "../../taxes/services/TaxHelper";
import OrderContributor from "../models/OrderContributor";
import { OrderDealError, OrderItemError } from "../models/OrderItemError";
import { DealContributorsAvailability, DealExecution, OrderPriceArg, SkuAndPrice } from "../models/OrderPriceArg";
import { OrderRefusalReason } from "../models/OrderRefusalReason";
import childOrderHelper from "./ChildOrderHelper";
import { default as orderCatalogHelper } from "./OrderCatalogHelper";
import { getDisabledEntities, getUnavailableEntities, orderService } from "./OrderService";

const getIndexFromId = (id: string): number => {
    if (!id) return -1;

    const match = id.match(/_(\d+)$/);
    return match ? parseInt(match[1]) : 0;
};

/**
 * Get the total amount of an order
 * // TODO: check availability catalog and add ids to order
 * // TODO: create method check disable entities
 * // TODO: create method check restriction entities
 * @param orderPriceArgs
 * @returns
 */
export function orderPrice(orderPriceArgs: OrderPriceArg): {
    price: number,
    invalidItems: OrderItemError[],
    invalidDeals: OrderDealError[],
    loyaltyResult: ComputeLoyaltyResult[] | null,
} {
    // Track if child location items have been found, in this case we need to compute stats
    let hasChildLocationItems = false;

    const {
        order,
        catalog,
        location,
        disableExpectedTimeResetForEatin,
        checkMinAmount,
        doNotThrow,
        patchOrder,
        spendingIncludeItemsWithUpdateId,
        earningIncludeItemsWithUpdateId,
        table
    } = orderPriceArgs;

    const loyaltyConfig: LoyaltyLocationConfig | undefined = order.loyalty_config;
    const products = catalog.data.products;

    let allOptionsPrice = 0

    let skusQuantityMap = new Map<string, number>();
    let skusMap = new Map<string, Sku>();
    let dealApplicationMap = new Map<string, DealExecution>()
    let newItems: OrderItem[] = []

    let loyaltyResult: ComputeLoyaltyResult[] | null = null;

    let orderMoment = orderService.getOrderMoment(order, getTimezoneName(catalog));
    if (order.service_type === SupportedServiceType.EAT_IN && (!orderMoment || !disableExpectedTimeResetForEatin)) {
        orderMoment = moment();
        order.expected_time = orderMoment.toDate();
        order.end_preparation_time = orderMoment.toDate();
    }
    if (!orderMoment) {
        throw OrderError.MISSING_ORDER_MOMENT;
    }

    /**
     * Getting the current restriction. If not found, we just keep going. If the 
     * restriction is not met, we throw an error
     * No need to reset expected time for eat-in as it has been done just above
     */
    // TODO: not only catalog restrictions
    let currentRestriction: Restriction | null = null;
    if (orderService.isOrderStatusDWP(order.status)) { // Only for draft order
        currentRestriction = getCurrentRestriction(catalog, order, true);
    }


    /////////////////
    // Checking availability of items & deals
    /////////////////

    let existingItemsPrice = 0;
    const alreadyCheckedDealKeys: string[] = [];
    const invalidOrderItem: OrderItemError[] = []
    const invalidOrderDeal: OrderDealError[] = []

    const { disableDeals, disableItems } = getDisabledEntities(order, catalog, patchOrder)

    invalidOrderDeal.push(...disableDeals)
    invalidOrderItem.push(...disableItems)

    const { unavailableDeals, unavailableItems } = getUnavailableEntities(order, catalog, patchOrder)

    invalidOrderDeal.push(...unavailableDeals)
    invalidOrderItem.push(...unavailableItems)

    /////////////////
    // Checking that the options are valid
    /////////////////
    productService.checkAllOrderItemOptions(
        order,
        catalog.data.products,
        catalog.data.option_lists,
        location.id,
        catalog.id!,
        getTimezoneName(catalog),
    );

    // If we have a deal without associated items it means that it is an empty deal
    // So we have to delete it from the order to avoid prices errors
    Object.entries(order.deals).forEach(([dealKey, deal]) => {
        if (!order.items.find(item => item.deal_line?.deal_key === dealKey)) {

            const unavailableDealWithError: OrderDealError = {
                ..._.cloneDeep(deal),
                deal_key: dealKey,
                refusal_reason: OrderRefusalReason.ENTITY_WITH_INVALID_CONTENT,
            };

            invalidOrderDeal.push(unavailableDealWithError);
            delete order.deals[dealKey];
        }
    });

    // This array is filled with deal_keys for which some items could not been affected to.
    // Only used to stop the computing of other items of this deal.
    const invalidDealKeys: string[] = [];

    order.items.forEach((item, itemIndex) => {

        let newOrderItem: OrderItem;

        // The item has already been sent to the server, do not change it
        if (item.update_id) {

            newOrderItem = item;
            // Affect missing catalog informations
            orderCatalogHelper.affectCatalogInfoToItem(order.id, order.service_type, newOrderItem, catalog, true);

            existingItemsPrice += item.subtotal ? moneyToNumber(item.subtotal) : 0;

            // Track that this deal must be kept
            if (item.deal_line?.deal_key && !alreadyCheckedDealKeys.includes(item.deal_line.deal_key)) {
                alreadyCheckedDealKeys.push(item.deal_line.deal_key);
            }
        }
        // Not yet sent to the server, check avaibility and rebuild the item with consolidated info
        else {

            // We can't compute the subtotal here as the price can be modified when applying the deal
            // REMINDER: the deal does not affect options price
            newOrderItem = {
                product_name: "",
                product_ref: item.product_ref,
                price: "",
                sku_ref: item.sku_ref,
                quantity: item.quantity,
                options: item.options,
                contributor_user_id: item.contributor_user_id,
                customer_notes: item.customer_notes
            };

            const catalogInfo = orderCatalogHelper.affectCatalogInfoToItem(order.id, order.service_type, newOrderItem, catalog);
            if (catalogInfo.hasChildLocationItems) {
                hasChildLocationItems = true;
            }
            const usedProduct = catalogInfo.product!;
            const usedSku = catalogInfo.sku!;

            // Checking if price overrides matches current conditions
            // If it matches, it will change the sku's price
            changeSkuPriceIfPriceOverridesApplies(usedSku, order.service_type, orderMoment!, getTimezoneName(catalog), table.area_ref)

            newOrderItem.price = usedSku.price;

            // Check if option exists, get price from catalog
            const optionPrice = consolidateOptionsInfo(newOrderItem, usedProduct, catalog.data.option_lists, catalog.data.options);
            allOptionsPrice += optionPrice * newOrderItem.quantity

            if (newOrderItem.options && newOrderItem.options.length) {
                newOrderItem.options_price = numberToMoney(optionPrice, catalog.currency);
            }

            /////////////////
            // DEALS
            /////////////////

            /**
             * OrderPrice is handling deals in 2 different manners.
             * First, the deals that are already built. They are represented by
             * an OrderDeal object, and the items associated have deal_lines. These ones can be, for example,
             * created by the user in the webapp (ex: "menu du jour")
             * Then there are the deals built by the system. Here later in the function, we
             * take all the order items (skus) and we try to build deals with them. Then, on some
             * conditions, we can reunite the items inside deals.
             */

            /////////////////
            // ALREADY-BUILT DEALS
            /////////////////

            // Will be set to true whenever the item is affected to a deal
            let itemHasBeenAffectedToDeal = false;

            if (item.deal_line?.deal_key) {

                log.debug(`OrderPrice: Deal line key ${item.deal_line.deal_key}`, { item })

                // Check if the deal hasn't been flagged as invalid yet
                if (!invalidDealKeys.includes(item.deal_line.deal_key)) {

                    if (order.deals[item.deal_line.deal_key]) {

                        const orderDeal = order.deals[item.deal_line.deal_key];

                        // We haven't checked this deal yet
                        if (!alreadyCheckedDealKeys.includes(item.deal_line.deal_key)) {

                            // Retrieving the deals availables from the catalog and the one referenced in the order
                            // No need to reset expected time, done at the begining of orderPrice
                            const availableDeals: Deal[] = getTemporallyAvailableCatalogDeals(catalog, order, true);
                            const dealFromCatalog: Deal | undefined = availableDeals.find(deal => deal.ref === orderDeal.ref)

                            if (dealFromCatalog) {

                                log.info(`OrderPrice: Found deal from catalog`, { item, dealFromCatalog })

                                // Checking if the user can use the deal
                                const contributor = (orderDeal.contributor_user_id && order.contributors && order.contributors[orderDeal.contributor_user_id]) ? order.contributors[orderDeal.contributor_user_id] : undefined;
                                const dealUsagesLeft: number = checkRestrictionAndGetDealNumberOfUsagesLeft(dealFromCatalog, contributor, catalog.id);

                                // Cannot use the deal? -> add it to the invalidDeals array
                                if (dealUsagesLeft <= 0) {
                                    if (!invalidOrderDeal.find(d => d.deal_key === item.deal_line?.deal_key)) {

                                        log.info(`OrderPrice: the deal ${dealFromCatalog.ref} cannot be used by customer, adding it to the invalidOrderDeal array`);

                                        const dealError = _.cloneDeep(orderDeal) as OrderDealError;
                                        dealError.refusal_reason = OrderRefusalReason.MAX_USAGE_COUNT_REACHED;
                                        dealError.deal_key = item.deal_line.deal_key;

                                        invalidOrderDeal.push(dealError);
                                    }
                                }

                                // If the user can't use the deal and we decided to patch order, then we'll just
                                // remove any link between the item and the deal: the items will exist, but won't be
                                // part of the deal
                                if (patchOrder && dealUsagesLeft <= 0) {
                                    delete item.deal_line;
                                }
                                else {

                                    // Creating quantity and sku map of the items to trigger a deal application
                                    let dealItemQuantityMap = new Map<string, number>()
                                    let dealSkuMap = new Map<string, Sku>()

                                    const orderItemsInDeal = order.items.filter(orderItem =>
                                        orderItem.deal_line && item.deal_line?.deal_key === orderItem.deal_line.deal_key)

                                    // Setting the dealSkuMap
                                    orderItemsInDeal.forEach(orderItem => {
                                        dealItemQuantityMap.set(orderItem.sku_ref, (dealItemQuantityMap.get(orderItem.sku_ref) || 0) + orderItem.quantity)
                                        const dealSku = productService.getSkuBasedOnProducRefAndSkuRefAndProducts(orderItem.product_ref, orderItem.sku_ref, products, catalog.location_id, catalog.ref)
                                        dealSkuMap.set(orderItem.sku_ref, dealSku)
                                    });

                                    // We must here apply the deal, even if the user's not authorized. The most important
                                    // thing is to have a contributor_uid to add to the orderDeal.
                                    const dealAvailability: DealContributorsAvailability = {
                                        deal: dealFromCatalog,
                                        number_of_usages: contributor ? { [orderDeal.contributor_user_id!]: dealUsagesLeft } : {}
                                    }

                                    const dealApplication = applyDeal(dealItemQuantityMap, dealSkuMap, dealAvailability, catalog)

                                    if (dealApplication) {

                                        // Pushing the current deal key so we won't have to check the deal again
                                        alreadyCheckedDealKeys.push(item.deal_line.deal_key);

                                        // Adding the deal application to the map so that it can be used later on
                                        dealApplicationMap.set(item.deal_line.deal_key, dealApplication)

                                        newOrderItem.deal_line = {
                                            deal_key: item.deal_line.deal_key,
                                            deal_line_ref: item.deal_line.deal_line_ref,
                                            deal_line_index: -1
                                        };

                                        // Checking that the sku referenced in the deal is the same as the item provided
                                        const dealItemIndex = getIndexFromId(newOrderItem.deal_line.deal_line_ref);

                                        if (dealItemIndex > -1) {
                                            const dealApplicationSkuRef = dealApplication.skuRefs[dealItemIndex];
                                            newOrderItem.price = dealApplicationSkuRef.price;
                                            newOrderItem.deal_line!.deal_line_ref = dealApplication.lineRefs[dealItemIndex];
                                            newOrderItem.deal_line!.label = dealApplicationSkuRef.label;
                                            newOrderItem.deal_line!.deal_line_index = dealApplicationSkuRef.line_index;
                                            if (dealApplicationSkuRef.no_choice) {
                                                newOrderItem.deal_line!.no_choice = dealApplicationSkuRef.no_choice;
                                            }
                                        } else {
                                            log.error('orderPrice: No deal item matching current item', { item, dealApplication })
                                        }

                                        const dealPrice: number = moneyToNumber(dealApplication.price)
                                        existingItemsPrice += dealPrice
                                        orderDeal.price = dealApplication.price;
                                        orderDeal.name = dealFromCatalog.name;

                                        itemHasBeenAffectedToDeal = true;
                                    }
                                }
                            }

                            // If the item hasn't been affected, it means there is an error with this deal: flag it
                            if (!itemHasBeenAffectedToDeal) {

                                // Only flag if the item still has a deal_line. If it doesn't, do not flag so that
                                // the other items of the deal can be processed and removed from the deal.
                                if (item.deal_line && !invalidDealKeys.includes(item.deal_line.deal_key)) {
                                    invalidDealKeys.push(item.deal_line.deal_key)
                                }
                            }
                        }

                        // We've already checked this deal
                        else {
                            const dealApplication = dealApplicationMap.get(item.deal_line.deal_key)

                            if (dealApplication) {
                                const dealItemIndex = getIndexFromId(item.deal_line.deal_line_ref);

                                newOrderItem.deal_line = {
                                    deal_key: item.deal_line.deal_key,
                                    deal_line_ref: item.deal_line.deal_line_ref,
                                    deal_line_index: -1
                                }
                                if (dealItemIndex > -1) {
                                    const dealApplicationSkuRef = dealApplication.skuRefs[dealItemIndex];
                                    newOrderItem.price = dealApplicationSkuRef.price;
                                    newOrderItem.deal_line!.deal_line_ref = dealApplication.lineRefs[dealItemIndex];
                                    newOrderItem.deal_line!.label = dealApplicationSkuRef.label;
                                    newOrderItem.deal_line!.deal_line_index = dealApplicationSkuRef.line_index;
                                    if (dealApplicationSkuRef.no_choice) {
                                        newOrderItem.deal_line!.no_choice = dealApplicationSkuRef.no_choice;
                                    }
                                }
                                else {
                                    log.error('OrderPrice: No deal item matching current item', { item, dealApplicationMap: Array.from(dealApplicationMap) })
                                }
                            }

                            itemHasBeenAffectedToDeal = true;
                        }
                    }
                }
            }

            // The item hasn't been affected to a deal, we can add it to the skuMap
            if (!itemHasBeenAffectedToDeal) {
                skusQuantityMap.set(usedSku.ref, (skusQuantityMap.get(usedSku.ref) || 0) + item.quantity)
                skusMap.set(usedSku.ref, usedSku);
            }
        }

        newItems.push(newOrderItem);
    })

    if (invalidOrderDeal.length > 0 || invalidOrderItem.length > 0) {
        log.info("Invalid deals or items: ", { invalidOrderDeal, invalidOrderItem });
    }

    // If we decided to throw if error and there are errors, throw them.
    // NOTE: for the deals, as it may be possible than errored deals could be
    // auto-rebuilt later, we only throw deals which cannot be auto-built for sure
    if (
        !doNotThrow
        && (
            invalidOrderItem.length
            || (
                invalidOrderDeal.length
                // Only throw if there's at least one refusal du to the limit of usages reached.
                && invalidOrderDeal.find(d => d.refusal_reason === OrderRefusalReason.MAX_USAGE_COUNT_REACHED)
            )
        )
    ) {
        throw OrderError.INVALID_ORDER_CONTENT.withValue({
            deal: invalidOrderDeal,
            product: invalidOrderItem
        });
    }

    order.items = newItems;



    /////////////////
    // AUTO-BUILT DEALS
    /////////////////


    // Getting all the contributor availabilities for the deals that match the temporal restrictions.
    // No need to reset expected time, done at the begining of orderPrice
    const dealContributorsAvailabilities = getAllDealsContributorsAvailabilities(skusQuantityMap, catalog, order, true);

    // TODO: Try several deal order to find the less expensive
    const autoDealsTotal = addAutoGeneratedDeals(skusQuantityMap, skusMap, dealContributorsAvailabilities, order, catalog, location)

    // Adding options to deal price
    log.debug("order.deals", order.deals);


    /////////////////
    // END OF DEALS
    /////////////////


    let finalPrice: Money = addMoney(autoDealsTotal, numberToMoney(allOptionsPrice + existingItemsPrice, catalog.currency));
    order.subtotal = finalPrice;

    // Once the Deal are applied, we can compute the subtotal for each item
    order.items.forEach((item) => {
        const subtotal: number = item.quantity * (moneyToNumber(item.price) + (item.options_price ? moneyToNumber(item.options_price) : 0))
        item.subtotal = numberToMoney(subtotal, catalog.currency);
    })

    //TODO: discount doesn't have to depend of items, remove condition and check side-effect (negative price etc)
    if (order.items.length) {
        discountService.applyDiscountsToOrder(DateTime.now(), order, catalog, moneyToNumber(finalPrice), false, table.area_ref);
    }

    // TODO: not like that. We may want to apply a discount. If not, the loyalty is useless

    // Loyalty: apply a loyalty discount if available on this order.
    // NOTE: loyalty config with "payments_only" won't have any effect here, as it's only applied
    // when a payment is made.
    // WARNING should be done after applying discount to order 
    if (loyaltyConfig) {

        loyaltyResult = computeLoyaltyForAllUsers(
            order,
            loyaltyConfig,
            earningIncludeItemsWithUpdateId,
            spendingIncludeItemsWithUpdateId,
        );
    }

    // looking for the delivery charge
    if (order.items.length > 0 && order.charges && order.charges.length > 0) {

        // For each charge, we check if it's a delivery charge. If yes, we check if the order total
        // is >= than the amount set in the delivery zone. If yes, we just set the delivery charge to 0€
        order.charges = order.charges.map((elem: OrderCharge) => {

            // Delivery charge found && 
            if (elem.type === OrderChargeType.DELIVERY && order.delivery_zone_ref) {

                const zoneFound = deliveryService.getDeliveryZoneFromRef(order.delivery_zone_ref, location.delivery);

                // Just because of typescript. If no zone is found, an error is thrown
                if (zoneFound) {

                    // Total is higher
                    if (
                        zoneFound.amount_to_reach_for_free_delivery
                        && moneyToNumber(finalPrice) >= moneyToNumber(zoneFound.amount_to_reach_for_free_delivery)
                    ) {

                        return {
                            ...elem,
                            price: numberToMoney(0, catalog.currency),  // We set it to 0€, free delivery
                        }
                    }
                    else {
                        // We trust the delivery provider type price, will be checked at the api level
                        return elem;
                    }
                }
            }

            return elem;
        });
    }

    // Compute service fees
    feesService.checkAndApplyServiceFees(
        location.service_fees,
        table.area_ref,
        order,
        finalPrice,
        catalog.currency,
    );

    // TODO: recompute
    let discounts = 0

    order.discounts?.forEach((discount) => {
        const discountPriceOff = moneyToNumber(discount.price_off);
        discounts += discountPriceOff;
    });
    if (discounts > 0) {
        finalPrice = substractMoney(finalPrice, numberToMoney(discounts, catalog.currency));
    }

    // Apply charges if there are
    let totalCharges: number = 0;
    if (order.items.length && order.charges && order.charges.length > 0) {

        order.charges = order.charges.filter((elem: OrderCharge) => {
            // We don't want delivery charges for a not-delivery order
            return !(elem.type === OrderChargeType.DELIVERY && order.service_type !== SupportedServiceType.DELIVERY);
        });

        order.charges.forEach((elem: OrderCharge) => {
            const chargePrice = moneyToNumber(elem.price);
            // Apply a charge ONLY IF positive value. Price cannot be a discount
            if (chargePrice > 0) {
                totalCharges += chargePrice;
            }
        });
    }

    if (totalCharges && totalCharges > 0) {
        finalPrice = addMoney(finalPrice, numberToMoney(totalCharges, catalog.currency));
    }

    for (const dealKey in order.deals) {
        order.deals[dealKey] = computeDealWithOptions(order.deals[dealKey], dealKey, order, catalog.currency)
    }

    order.total = finalPrice;

    // Once the full amount has been computed, we can compute the taxes
    taxHelper.computeOrderTaxes(order, catalog);

    // And compute the total percentage for each seller if needed
    if (hasChildLocationItems) {
        childOrderHelper.computeChildOrderParts(order);
    }

    /**
     * If we want to check if the min amount is reached. We make sure that there is a restriction with a min
     * amount in effect, and then do the test and throw if amount is not reached
     */
    if (checkMinAmount && currentRestriction && currentRestriction.min_order_amount) {

        if (!isMinAmountReached(currentRestriction, finalPrice)) {

            throw OrderError.RESTRICTIONS_MIN_ORDER_AMOUNT;
        }
    }

    return {
        price: moneyToNumber(finalPrice),
        invalidItems: invalidOrderItem,
        invalidDeals: invalidOrderDeal,
        loyaltyResult,
    };
}

/**
 * Get the current restriction, the one that is met right now
 * @param catalog 
 * @param order 
 * @param now 
 * @returns the current restriction or null
 * @throws OrderError.RESTRICTIONS_NOT_MET if the restrictions are not met
 */
export function getCurrentRestriction(catalog: Catalog, order: Order, disableExpectedTimeResetForEatin: boolean): Restriction | null {

    if (catalog.restrictions && catalog.restrictions.length !== 0) {

        for (let restri of catalog.restrictions) {

            if (isTemporallyAvailableForOrder(order, getTimezoneName(catalog), restri, disableExpectedTimeResetForEatin)) {

                return restri;
            }
        }

        const orderMoment = orderService.getOrderMoment(order, getTimezoneName(catalog));
        throw OrderError.RESTRICTIONS_NOT_VERIFIED.withCustomMessage(
            `Catalog restrictions not met for time ${orderMoment?.toISOString()} and service type ${order.service_type}`
            , {
                location_id: catalog.location_id,
                catalog_id: catalog.id,
                restrictions: catalog.restrictions
            })
    }

    return null;
}

/**
 * Check if the provided sku has price overrides that matches the conditions
 * and update the sku's price
 * If the price override has no condition, it does nothing
 * // TODO: test it !! and 
 * @param usedSku Current sku 
 * @param currentServiceType
 * @param now 
 * @param timezone
 */
export const changeSkuPriceIfPriceOverridesApplies = (
    usedSku: Sku,
    currentServiceType: SupportedServiceType,
    now: Moment,
    timezone: string,
    tableArea: string | undefined,
) => {

    if (usedSku.price_overrides) {

        const priceOverrides: PriceOverride[] = usedSku.price_overrides
        let priceChanged = false;

        //Iterate every priceOverride
        priceOverrides.forEach((priceOverride: PriceOverride) => {

            // Use to only use the first price override
            // TODO : Maybe change price override priority, not just the first one
            if (!priceChanged && priceOverride.price) {

                // Check if price override is a service type matched with a time constrain
                // As price overrides can have the key with an empty string or array,
                // we must check that it exists and it filled in
                if (priceOverride.service_types &&
                    priceOverride.service_types.length > 0 &&
                    ((priceOverride.dow && priceOverride.dow.length > 0 && priceOverride.dow !== '-------') ||
                        (priceOverride.start_time && priceOverride.start_time.length > 0) ||
                        (priceOverride.end_time && priceOverride.end_time.length > 0) ||
                        (priceOverride.start_date && priceOverride.start_date.length > 0) ||
                        (priceOverride.end_date && priceOverride.end_date.length > 0)
                    )) {
                    for (const serviceType of priceOverride.service_types) {
                        // TODO: why include on a string????
                        if (currentServiceType.includes(serviceType)) {

                            // Matching temporal AND table area restrictions
                            if (isMatchingTemporalRestriction(now, timezone, priceOverride, undefined, undefined, undefined, tableArea)) {
                                usedSku.price = priceOverride.price;
                                priceChanged = true;
                            }
                            //Break the loop because the first one matching is enough
                            break
                        }
                    }
                }

                // Check if the price override is a service_type
                else if (priceOverride.service_types && priceOverride.service_types.length > 0) {
                    for (const serviceType of priceOverride.service_types) {
                        // TODO: why include on a string????
                        if (currentServiceType.includes(serviceType)) {
                            usedSku.price = priceOverride.price;
                            priceChanged = true;
                            //Break the loop because the first one matching is enough
                            break
                        }
                    }
                }
                // Check if this is the correct time slot
                else if (
                    priceOverride.dow ||
                    priceOverride.start_time ||
                    priceOverride.end_time ||
                    priceOverride.start_date ||
                    priceOverride.end_date
                ) {
                    // Matching temporal AND table area restrictions
                    if (isMatchingTemporalRestriction(now, timezone, priceOverride, undefined, undefined, undefined, tableArea)) {
                        usedSku.price = priceOverride.price;
                        priceChanged = true;
                    }
                }
            }
        })
    }
}

/**
 * Checking if the minimum order amount is reached.
 * @param currentRestriction
 * @param total the order amount
 * @returns true or false
 */
export function isMinAmountReached(currentRestriction: Restriction, total: Money): boolean {

    // We are below the min amount
    if (currentRestriction.min_order_amount) {

        if (moneyToNumber(total) < moneyToNumber(currentRestriction.min_order_amount)) {

            return false;
        }
    }

    return true;
}

/**
 * Deal can apply only if not yet used and not yet sent to mylemonade
 * @param item 
 * @param sku_ref 
 */
function canDealApply(item: OrderItem, sku_ref: SkuAndPrice) {
    return item.sku_ref === sku_ref.ref && !item.deal_line && !item.update_id
}

// TODO: please explain the prupose of this function. Why is it called calculPrice if it's
// about deals? Why is the total used after to calculate the subtotal of the order??

/**
 * This function tries to generate as much deals as possible with the skus given in the
 * parameters. If the setting location.orders.disable_auto_deal is on, the deals will be applied
 * directly in the order (order.deals and order.items will be changed).
 * @param availableSkus 
 * @param catalogSkus 
 * @param dealContributorsAvailabilities 
 * @param order 
 * @param catalog 
 * @param maxDealKeyOfMap 
 * @param location 
 * @returns 
 */
const addAutoGeneratedDeals = (
    availableSkus: Map<string, number>,  // Skus that can be used in auto-buit deals
    catalogSkus: Map<string, Sku>,
    dealContributorsAvailabilities: DealContributorsAvailability[],
    order: Order,
    catalog: Catalog,
    location: Location,
): Money => {

    // This index is tracking the max of the deal_keys in order.deals, so that the next one added will be +1
    let latestDealIndex: number = 0;
    if (order.deals) {
        latestDealIndex = _.max(Object.keys(order.deals).map(key => parseInt(key))) ?? Object.keys(order.deals).length - 1;
    }

    let autoDealsTotalPrice: Money = numberToMoney(0, catalog.currency);
    const generatedDeals = new Array<DealExecution>();

    while (availableSkus.size > 0) {

        const createdDeal = autoCreateDeal(availableSkus, catalogSkus, dealContributorsAvailabilities, catalog)

        if (createdDeal && !location.orders?.disable_auto_deal) {

            generatedDeals.push(createdDeal);
            autoDealsTotalPrice = addMoney(autoDealsTotalPrice, createdDeal.price);

            new SkuQuantityHelper().removeSkuFromQuantityMap(availableSkus, createdDeal.skuRefs);

        }
        // Could not create any deal with the remaining skus: clearing them, exiting loop
        else {

            availableSkus.forEach((value, key, map) => {
                autoDealsTotalPrice = addMoney(autoDealsTotalPrice, multiplyMoney(catalogSkus.get(key)!.price, value));
                map.delete(key);
            })
        }
    }

    generatedDeals.forEach((generatedDealExecution) => {

        // The deal key is +1 above the previous one
        const dealKey = (latestDealIndex + 1).toString();
        latestDealIndex++;

        let dealPrice: Money = generatedDealExecution.price;

        const deal: OrderDeal = {
            name: generatedDealExecution.dealName,
            ref: generatedDealExecution.dealRef,
            price: generatedDealExecution.price,
            contributor_user_id: generatedDealExecution.contributor_user_id,
        }

        for (const j in generatedDealExecution.skuRefs) {

            const skuRef = generatedDealExecution.skuRefs[j];
            const lineRef = generatedDealExecution.lineRefs[j];
            const orderItem = order.items.find(elem => canDealApply(elem, skuRef));

            if (!orderItem) {
                throw OrderError.SKU_NOT_FOUND
            }

            if (orderItem.options) {
                for (const option of orderItem.options) {
                    dealPrice = addMoney(dealPrice, option.price);
                }
            }

            /**
             * The purpose of this block is to separate the identical deals.
             * For example, let's say we have a free coke deal available in the catalog. User takes
             * 3 cokes in the cart. This function will detect that the 3 cokes can be put in 3 identical deals:
             * the item coke will have quantity 3 and dealKey set to the 1st deal detected.
             * As we don't want the deals to stack, we'll have to separate the 3 cokes into 3 different items, each
             * one having a different dealKey.
             */
            const dealLine: OrderDealLine = {
                deal_key: dealKey,
                deal_line_ref: lineRef,
                deal_line_index: skuRef.line_index,
                label: skuRef.label
            }
            if (skuRef.no_choice) {
                dealLine.no_choice = skuRef.no_choice;
            }
            if (orderItem.quantity === 1) {
                orderItem.deal_line = dealLine;
                orderItem.price = skuRef.price;
            }
            else {
                const unitWeight = (orderItem.weight ?? 0) / orderItem.quantity;
                orderItem.quantity -= 1;
                orderItem.weight = unitWeight * orderItem.quantity;
                const splittedDealOrderItem: OrderItem = {
                    ...orderItem,
                    weight: unitWeight,
                    price: skuRef.price,
                    quantity: 1,
                    deal_line: dealLine
                }
                order.items.push(splittedDealOrderItem);
            }
        }

        deal.price = dealPrice;

        // Adding the deal to the order
        if (!order.deals) {
            order.deals = {};
        }
        order.deals[dealKey] = deal;

    });

    return autoDealsTotalPrice;
}

/**
 * Given a catalog deal and the availabilities of the contributors for this deal,
 * and the available skus, this function will try to create a deal execution.
 * @param skusQuantity 
 * @param skus 
 * @param dealAvailability if the object number_of_usages is empty, don't check the usages and do not assign any user to the deal
 * @param catalog 
 * @returns 
 */
const applyDeal = (
    skusQuantity: Map<string, number>,
    skus: Map<string, Sku>,
    dealAvailability: DealContributorsAvailability,
    catalog: Catalog,
): DealExecution | undefined => {

    const deal = dealAvailability.deal;
    const createdDealLines = getDealApply(skusQuantity, deal);

    if (!createdDealLines || createdDealLines.length === 0) {
        return undefined;
    }

    let price: Money = numberToMoney(0, catalog.currency);
    let skuRefs = new Array<SkuAndPrice>()
    let lineRefs = new Array<string>()

    createdDealLines.forEach(line => {

        const new_price: Money = getDealLinePrice(skus.get(line.skuref)!, line.line, line.extra_charge, catalog.currency);

        price = addMoney(price, new_price);

        const skuAndPrice: SkuAndPrice = {
            ref: line.skuref,
            price: new_price,
            label: line.line.label,
            line_index: line.line.index ?? 0
        };
        if (line.line.skus.length === 1) {
            skuAndPrice.no_choice = true;
        }
        skuRefs.push(skuAndPrice);
        lineRefs.push(line.line.ref)
    });

    // Assigning the deal to a contributor if possible
    let assignedToDealContributorId: string | undefined = undefined;

    for (const [contributorId, usagesLeft] of Object.entries(dealAvailability.number_of_usages)) {
        if (usagesLeft > 0) {
            assignedToDealContributorId = contributorId;
            dealAvailability.number_of_usages[contributorId] = usagesLeft - 1;
            break;
        }
    }

    // If given a map of contributors and we can't assign the deal to any of them, return;
    if (Object.keys(dealAvailability.number_of_usages).length > 0 && !assignedToDealContributorId) {
        return undefined;
    }

    return {
        dealName: deal.name,
        dealRef: deal.ref,
        price,
        skuRefs,
        lineRefs,
        contributor_user_id: assignedToDealContributorId,
    }
}

/**
 * Add options prices to the deal
 * @param deal current deal
 * @param dealKey current deal key as string
 * @param order 
 * @returns a new order deal with the correct price
 */
const computeDealWithOptions = (deal: OrderDeal, dealKey: string, order: Order, currency: string): OrderDeal => {
    const newDeal: OrderDeal = _.cloneDeep(deal)
    let dealPriceWithOptions: Money = numberToMoney(0, currency)

    order.items.forEach(item => {
        if (item.deal_line && item.deal_line.deal_key === dealKey) {
            let itemPrice = item.price
            item.options?.forEach(opt => itemPrice = addMoney(itemPrice, opt.price))
            dealPriceWithOptions = addMoney(dealPriceWithOptions, itemPrice)
        }
    })

    newDeal.price_with_options = dealPriceWithOptions

    return newDeal
}

/**
 * Given a list of skus and the available catalog deals, try to create a deal.
 * TODO: Try several deals to find the less expensive
 * @param skusQuantity 
 * @param skus 
 * @param dealAvailabilities 
 * @param catalog 
 * @returns 
 */
function autoCreateDeal(
    skusQuantity: Map<string, number>,
    skus: Map<string, Sku>,
    dealAvailabilities: DealContributorsAvailability[],
    catalog: Catalog
): DealExecution | undefined {

    for (const dealAvailability of dealAvailabilities) {

        // Try to apply the deal
        const dealExecution = applyDeal(skusQuantity, skus, dealAvailability, catalog);

        // The deal can be applied, return it
        if (dealExecution) {
            return dealExecution;
        }
    };

    // None of the deals could be applied
    return undefined
}

/**
 * This functions explores the catalog, takes all the deals that could be applied according
 * to the temporal restrictions and the remaining skus.
 * It returns, for each deal, the availabilities for each order contributor.
 * @param skusQuantity 
 * @param catalog 
 * @param order 
 * @param disableExpectedTimeResetForEatin 
 * @returns 
 */
function getAllDealsContributorsAvailabilities(
    skusQuantity: Map<string, number>,
    catalog: Catalog,
    order: Order,
    disableExpectedTimeResetForEatin: boolean
): DealContributorsAvailability[] {

    const availableDeals = catalog.data.deals
        .filter(d => !d.restrictions || isTemporallyAvailableForOrder(order, getTimezoneName(catalog), d.restrictions, disableExpectedTimeResetForEatin)) // Only available deal at this time
        .filter(d => isApplicable(skusQuantity, d))

    // Getting the number of deal usages left for each order contributor
    const avaialbleDealPossibilites = availableDeals.map(deal => {

        const number_of_usages: { [contributor_uid: string]: number } = {};

        for (const contributorUid in order.contributors) {
            number_of_usages[contributorUid] = checkRestrictionAndGetDealNumberOfUsagesLeft(deal, order.contributors[contributorUid], catalog.id);
        }

        return {
            deal: deal,
            number_of_usages,
        }
    });

    return avaialbleDealPossibilites;
}

/**
 * Explore the catalog and return all the deals that could be applied, temporally speaking, to the order. 
 * Also remove the disabled deals.
 * @param catalog 
 * @param order 
 * @param disableExpectedTimeResetForEatin 
 * @returns 
 */
export function getTemporallyAvailableCatalogDeals(catalog: Catalog, order: Order, disableExpectedTimeResetForEatin: boolean): Deal[] {
    return catalog.data.deals
        .filter(deal => {
            if (deal.disable) {
                return false
            }

            if (!deal.restrictions) {
                return true
            }

            const available = isTemporallyAvailableForOrder(order, getTimezoneName(catalog), deal.restrictions, disableExpectedTimeResetForEatin)

            return available;
        })
}

export function isDealMatchingRestrictions(
    deal: Deal,
    catalog: Catalog,
    now: Moment,
    service_type: SupportedServiceType | null,
    signin_provider: SignInProviders | null,
    customer: Customer | OrderContributor | null,
): Boolean {

    const restrictions = getRestrictionsArray(deal.restrictions);

    // Check if the deal require an authentication provider
    if (deal.usage_restriction && deal.usage_restriction.authentication_providers) {
        if (!signin_provider || !deal.usage_restriction.authentication_providers.includes(signin_provider)) {
            return false;
        }
    }

    // Check usage per customer
    if (
        deal.usage_restriction
        && deal.usage_restriction.max_per_customer
        && deal.usage_restriction.max_per_customer !== -1
    ) {
        if (!customer) {
            return false;
        }

        const usageLeft = checkRestrictionAndGetDealNumberOfUsagesLeft(deal, customer, catalog.id)

        if (usageLeft <= 0) {
            return false;
        }
    }

    return Boolean(!restrictions || restrictions.find(
        r => isMatchingTemporalRestriction(now, getTimezoneName(catalog), r)
    ))

}

export function getDealLinePrice(sku: Sku, simpleDealLine: DealLine, extra: string | undefined, currency: string): Money {

    let newPrice: Money = numberToMoney(0, currency);

    switch (simpleDealLine.pricing_effect) {

        case PricingEffect.EFFECT_UNCHANGED:

            newPrice = sku.price;
            break;

        case PricingEffect.EFFECT_FIXED_PRICE:

            if (simpleDealLine.pricing_value) {

                newPrice = simpleDealLine.pricing_value as Money;
            }
            else {
                throw OrderError.PRICING_VALUE_NEEDED
            }
            break;

        case PricingEffect.EFFECT_PRICE_OFF:

            if (simpleDealLine.pricing_value) {

                newPrice = substractMoney(sku.price, simpleDealLine.pricing_value as Money);
            }
            else {
                throw OrderError.PRICING_VALUE_NEEDED
            }
            break;

        case PricingEffect.EFFECT_PERCENTAGE_OFF:

            if (simpleDealLine.pricing_value) {

                let percentage: number = parseFloat(simpleDealLine.pricing_value as string) / 100;
                newPrice = multiplyMoney(sku.price, (1 - percentage));

            }
            else {
                throw OrderError.PRICING_VALUE_NEEDED
            }
            break;
    }

    if (extra) {

        newPrice = addMoney(newPrice, extra);
    }

    return newPrice;
}

/**
 * Compute the order item totals and update the fields in order item
 * WARNING: the item prices must have been affected using catalog
 * @param orderItem 
 */
export function computeOrderItemTotals(orderItem: OrderItem, currency: string): number {
    let optionPrices = 0;
    orderItem.options?.forEach((option) => {
        if (option.price) {
            optionPrices += moneyToNumber(option.price);
        }
    })
    orderItem.options_price = numberToMoney(optionPrices, currency);
    if (!orderItem.quantity) {
        orderItem.quantity = 1;
    }
    const unitPrice = moneyToNumber(orderItem.price);
    const subtotal = orderItem.quantity * (unitPrice + optionPrices);
    orderItem.subtotal = numberToMoney(subtotal, currency);
    return subtotal;
}

/**
 * this function verify if the item in params has options to put in the price
 * it return the item price + options or just the item price FOR ONLY ONE ITEM
 * 
 * perhaps this function already exists
 * @param item 
 * @returns 
 */
export const itemUnitPriceWithOptions = (item: OrderItem): number => {
    let itemPrice: number
    if (item.options) {
        if (!item.options_price) {

            let itemPlusOptionsPrice = moneyToNumber(item.price as string)

            if (item.options.length > 1) {

                itemPlusOptionsPrice += item.options
                    .map((option: OrderOption) => option.price
                        ? moneyToNumber(option.price)
                        : 0)
                    .reduce((val, cur) => val + cur);

            }
            else {
                if (item.options[0]?.price) {
                    itemPlusOptionsPrice += moneyToNumber(item.options[0].price as string);
                }
            }
            itemPrice = itemPlusOptionsPrice;
        } else {
            itemPrice = moneyToNumber(item.options_price as string) + moneyToNumber(item.price)
        }
    }
    else {
        itemPrice = moneyToNumber(item.price as string)
    }
    return itemPrice;
}


export function consolidateOptionsInfo(
    orderItem: OrderItem,
    product: Product,
    catalogsOptionLists: OptionList[],
    catalogOptions: Option[],
): number {

    let optionsPrice = 0;

    if (orderItem.options) {
        const productOptionListRefs = productService.getProductOptionListRefs(product);

        const newOptions: OrderOption[] = []
        const usedByOptionList = new Map<string, number>();

        orderItem.options.forEach(orderOption => {

            // Search for option list ref in product
            const productOptionListIndex = productOptionListRefs?.indexOf(orderOption.option_list_ref);
            if (!productOptionListIndex || productOptionListIndex < 0) {
                /* TODO: to be added & fix all tests
                throw OrderError.OPTION_LIST_REF_NOT_FOUND_IN_PRODUCT.withValue({
                    "option_list_ref": orderOption.option_list_ref
                })*/
            }

            const optionList = catalogsOptionLists.find(optList => optList.ref === orderOption.option_list_ref)
            if (!optionList) {
                throw OrderError.OPTION_LIST_NOT_FOUND.withValue({
                    option_list_ref: orderOption.option_list_ref,
                    optionList,
                });
            }

            // Throw error if option is not repeatable and already exists in order item
            if (!optionList.repeatable) {

                const isMultiple = orderItem.options.filter(opt => (
                    opt.option_list_ref === orderOption.option_list_ref
                    && opt.ref === orderOption.ref
                )).length > 1;

                if (isMultiple) {
                    throw OrderError.OPTION_NOT_REPEATABLE.withValue({
                        option_list_ref: orderOption.option_list_ref,
                        option_ref: orderOption.ref,
                        isMultiple,
                    })
                }
            }

            const optionLine = optionList.option_lines.find((ol) => ol.option_ref === orderOption.ref);
            const option = catalogOptions.find(opt => opt.ref === orderOption.ref);

            if (!optionLine || !option) {
                throw OrderError.OPTION_NOT_FOUND.withValue({
                    option_list_ref: orderOption.option_list_ref,
                    option_ref: orderOption.ref,
                    optionLine,
                    option,
                })
            }

            let optionPrice = optionLine.price;

            if (optionList.max_before_extra) {
                const used = usedByOptionList.get(optionList.ref) ?? 0;
                if (used >= optionList.max_before_extra) {
                    optionPrice = optionLine.extra_price || optionLine.price;
                }
                usedByOptionList.set(optionList.ref, used + 1);
            }

            optionsPrice += moneyToNumber(optionPrice);

            const newOption: OrderOption = {
                name: option.name,
                ref: option.ref,
                option_list_name: optionList.name,
                option_list_ref: optionList.ref,
                option_list_index: productOptionListIndex,
                price: optionPrice,
            }
            if (optionList.connector_type) {
                newOption.connector_type = optionList.connector_type;
            }
            newOptions.push(newOption);
        });

        orderItem.options = newOptions.sort((a, b) => (a.option_list_index ?? 0) - (b.option_list_index ?? 0));

    }


    return optionsPrice
}


