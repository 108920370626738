import firebase from 'firebase/app';
import 'firebase/firestore';
import _ from "lodash";
import { DateTime } from 'luxon';
import TimeslotsAvailable from '../../Common/models/TimeslotsAvailable';
import CheckedDeliveryZone from '../../delivery/models/CheckedDeliveryZone';
import { Catalog } from '../../my-lemonade-library/model/Catalog';
import { Location, SupportedServiceType, Table } from '../../my-lemonade-library/model/Location';
import { OrderCharge, OrderInBase, OrderItem, OrderOption } from '../../my-lemonade-library/model/Order';
import { OrderError } from '../../my-lemonade-library/model/OrderError';
import { CatalogExtended } from '../../my-lemonade-library/model/catalogExtended/CatalogExtended';
import { PaymentInfos } from '../../my-lemonade-library/model/payments/PaymentInfos';
import { Customer } from '../../my-lemonade-library/src/authentications/models/Customer';
import { CatalogEntityType } from '../../my-lemonade-library/src/catalogs/models/CatalogEntity';
import { Money } from '../../my-lemonade-library/src/common/models/Money';
import { ConnectorGetTableOpenedOrdersError } from '../../my-lemonade-library/src/connectors/models/ConnectorGetTableOpenedOrdersError';
import { Discount } from '../../my-lemonade-library/src/discounts/models/Discount';
import { ComputeLoyaltyResult } from '../../my-lemonade-library/src/loyalties/models/ComputeLoyaltyResult';
import OrderContributor from '../../my-lemonade-library/src/orders/models/OrderContributor';
import { OrderItemToPay } from '../../my-lemonade-library/src/payments/models/OrderItemToPay';
import { PaymentAmountType } from '../../my-lemonade-library/src/payments/models/PaymentAmountType';
import { PaymentTypeExtended } from '../../my-lemonade-library/src/payments/models/PaymentTypeExtended';
import { ListItemsToAdd } from '../models/ListItemsToAdd';

export const LATEST_ORDERS_DEFAULT_LIMIT = 100;

export const LOAD_ORDERS = "LOAD_ORDERS";
export interface LoadOrdersAction {

    type: typeof LOAD_ORDERS

    payload: {

        userOrdersLimit: number,
        tableOrdersLimit: number,

        requestParams?: URLSearchParams,

        table: Table | null,
        catalog: CatalogExtended | null,

        acountId?: string,
        locationId?: string,
        tableId?: string,
        catalogId?: string,
        location?: Location,

        allowLoadOrder: boolean,
    }
}

export const LOAD_ORDERS_SUCCESS = "LOAD_ORDERS_SUCCESS"
export interface LoadOrdersSuccessAction {
    type: typeof LOAD_ORDERS_SUCCESS;
    payload: {
        latestUserOrders: OrderInBase[],
        latestTableOrders: OrderInBase[],
        connectorGetTableOpenedOrdersError?: ConnectorGetTableOpenedOrdersError | null,
    };
}

export const LOAD_ORDERS_ERROR = "LOAD_ORDERS_ERROR"
export interface LoadOrdersErrorAction {
    type: typeof LOAD_ORDERS_ERROR;
    payload: {
        orderError: OrderError,
    }
}

export const LOAD_AVAILABLE_TIMESLOTS = "LOAD_AVAILABLE_TIMESLOTS"
export interface LoadAvailableTimeSlotsAction {
    type: typeof LOAD_AVAILABLE_TIMESLOTS;
    payload: {
        serviceType: SupportedServiceType;
        forDate: DateTime;
        deliveryZoneRef: string | undefined;
    }
}

export const LOAD_AVAILABLE_TIMESLOTS_SUCCESS = "LOAD_AVAILABLE_TIMESLOTS_SUCCESS"
export interface LoadAvailableTimeSlotsSuccessAction {
    type: typeof LOAD_AVAILABLE_TIMESLOTS_SUCCESS;
    payload: TimeslotsAvailable | null;
}

export const LOAD_AVAILABLE_TIMESLOTS_ERROR = "LOAD_AVAILABLE_TIMESLOTS_ERROR"
export interface LoadAvailableTimeSlotsErrorAction {
    type: typeof LOAD_AVAILABLE_TIMESLOTS_ERROR;
}

export const SEND_ORDER = 'SEND_ORDER';
export interface SendOrderAction {
    type: typeof SEND_ORDER;
    payload: {
        type: PaymentTypeExtended;
        paymentAmount?: string;
        paymentData?: any;
        pin?: string,
        payment_items?: OrderItemToPay[],
        payment_amount_type?: PaymentAmountType,
        tip_charge_amount?: Money,
    }
}

export const SEND_ORDER_SUCCESS = 'SEND_ORDER_SUCCESS';
export interface SendOrderSuccessAction {
    type: typeof SEND_ORDER_SUCCESS;
    payload: {
        order: OrderInBase;
        paymentInfos: PaymentInfos | undefined;
        paymentPage: string | undefined;
    }
}

export const SEND_ORDER_ERROR = 'SEND_ORDER_ERROR';
export interface SendOrderErrorAction {
    type: typeof SEND_ORDER_ERROR;
    payload: {
        code?: string;
        message?: string;
        value?: { [key in CatalogEntityType]: any[] }
    }
}

export const OPEN_MODAL = 'OPEN_MODAL';
export interface OpenModalAction {
    type: typeof OPEN_MODAL;
    payload: null;
}

export const CLOSE_MODAL = 'CLOSE_MODAL';
export interface CloseModalAction {
    type: typeof CLOSE_MODAL;
    payload: null;
}

export const SET_CUSTOMER_INFO = "SET_CUSTOMER_INFO";
export interface SetCustomerInformationAction {
    type: typeof SET_CUSTOMER_INFO;
    payload: {
        customer: Customer | null;
        setAddressFromCustomer?: boolean;
        forceSetAll?: boolean;
    }
}

export const SET_TABLE_ID = "SET_TABLE_ID";
export interface SetTableIdAction {
    type: typeof SET_TABLE_ID;
    payload: string;
}

export const SET_ORDER_SERVICE_TYPE = "SET_ORDER_SERVICE_TYPE";
export interface SetServiceTypeAction {
    type: typeof SET_ORDER_SERVICE_TYPE;
    payload: SupportedServiceType;
}

export const SET_CHARGE = "SET CHARGE";
export interface SetChargeAction {
    type: typeof SET_CHARGE,
    payload: {
        charge: OrderCharge;
        catalog: CatalogExtended;
        location: Location;
        table: Table;
    }
}

export const ADD_ITEM = 'ADD_ITEM';
export interface AddItemAction {
    type: typeof ADD_ITEM;
    payload: {
        product: {
            product_ref: string,
            sku_ref: string,
            options: OrderOption[],
            product_name: string,
            quantity: number,
            price: Money,
            contributor_user_id?: string,
            customer_notes: string
        },
        catalog: CatalogExtended,
        location: Location,
        table: Table,
        calculPrice?: boolean,
    },
}

export const ADD_ITEMS = "ADD_ITEMS";
export interface AddItemsAction {
    type: typeof ADD_ITEMS;
    payload: {
        listItems: ListItemsToAdd[],
        catalog: Catalog,
        location: Location,
        table: Table,
        calculPrice?: boolean,
    },
}

export const SET_CUSTOMER_NOTES = "SET CUSTOMER NOTES";
export interface SetCustomerNotesAction {
    type: typeof SET_CUSTOMER_NOTES;
    payload: { customer_notes: string }
}

export const ADD_DEAL = 'ADD_DEAL';
export interface AddDealAction {
    type: typeof ADD_DEAL;
    payload: {
        dealRef: string;
        dealItems: OrderItem[];
        catalog: CatalogExtended;
        location: Location;
        table: Table;
        userId: string;
    }
}

export const EDIT_DEAL = 'EDIT_DEAL';
export interface EditDealAction {
    type: typeof EDIT_DEAL;
    payload: {
        dealRef: string;
        dealItems: OrderItem[];
        catalog: CatalogExtended;
        location: Location;
        table: Table;
        userId: string;
    }
}

export const REMOVE_DEAL = 'REMOVE_DEAL';

export interface RemoveDealAction {
    type: typeof REMOVE_DEAL;
    payload: {
        dealKey: string,
    }
}

export const REMOVE_ITEM = 'REMOVE_ITEM';
export interface RemoveItemAction {
    type: typeof REMOVE_ITEM;
    payload: {
        sku_ref: string,
        options: any,
        catalog: Catalog,
        location: Location,
        table: Table,
    }
}

export const RESET_ITEMS = 'RESET_ITEMS';
export interface ResetItemsAction {
    type: typeof RESET_ITEMS;
    payload: null;
}

export const RESET_ORDER = 'RESET_ORDER';
export interface ResetOrderAction {
    type: typeof RESET_ORDER;
    payload: null;
}

export const RESET_ORDER_ERROR = 'RESET_ORDER_ERROR';
export interface ResetOrderErrorAction {
    type: typeof RESET_ORDER_ERROR;
    payload: null;
}

export const RESET_ORDER_PAYMENTS = 'RESET_ORDER_PAYMENTS';
export interface ResetOrderPaymentsAction {
    type: typeof RESET_ORDER_PAYMENTS;
    payload: null;
}

export const SET_DELIVERY_ZONE = "SET_DELIVERY_ZONE";
export interface SetDeliveryZoneAction {
    type: typeof SET_DELIVERY_ZONE;
    payload: CheckedDeliveryZone;
}

export const SETUP_SYNC_CUSTOMER = "SETUP_SYNC_CUSTOMER";
export interface SetupSyncCustomerAction {
    type: typeof SETUP_SYNC_CUSTOMER;
    payload: {
        userId?: string;
        isAnonymous?: boolean;
    }
}

export const SYNC_ORDER = "SYNC_ORDER";
export interface SyncOrderAction {
    type: typeof SYNC_ORDER;
    payload: firebase.firestore.DocumentSnapshot;
}

export const SYNC_CUSTOMER = "SYNC_CUSTOMER";
export interface SyncCustomerAction {
    type: typeof SYNC_CUSTOMER;
    payload: firebase.firestore.DocumentSnapshot;
}

export const SET_PAYMENT_TYPE = 'SET_PAYMENT_TYPE'
export interface SetPaymentTypeAction {
    type: typeof SET_PAYMENT_TYPE;
    payload: PaymentTypeExtended;
}

export const SET_ORDER = 'SET_ORDER';
export interface SetOrderAction {
    type: typeof SET_ORDER;
    payload: {
        order: OrderInBase,
        catalog: Catalog,
        location: Location,
        table: Table,
        ignoreDraft?: boolean,
    }
}

export const CREATE_DRAFT_ORDER = "CREATE_DRAFT_ORDER";
export interface CreateDraftOrderAction {
    type: typeof CREATE_DRAFT_ORDER;
    payload: {
        location?: Location;
        catalog?: CatalogExtended;
        table?: Table;
    };
}

export const ADD_CONTRIBUTOR = "ADD_CONTRIBUTOR";
export interface AddContributorAction {
    type: typeof ADD_CONTRIBUTOR;
    payload: {
        uid: string,
        user: Customer,
        location?: Location;
        catalog?: CatalogExtended;
        table?: Table;
        patch?: Partial<OrderContributor>;
    }
}

export const REMOVE_CONTRIBUTOR_LOCALLY = "REMOVE_CONTRIBUTOR_LOCALLY";
export interface RemoveContributorLocallyAction {
    type: typeof REMOVE_CONTRIBUTOR_LOCALLY;
    payload: {
        uid: string,
    }
}

// Used to actually add the contributor using the reducer (and not the API)
export const ADD_CONTRIBUTOR_LOCAL = "ADD_CONTRIBUTOR_LOCAL";
export interface AddContributorLocalAction {
    type: typeof ADD_CONTRIBUTOR_LOCAL;
    payload: {
        uid: string;
        user: Customer;
        patch?: Partial<OrderContributor>;
    }
}

export const UPDATE_CONTRIBUTOR = "UPDATE_CONTRIBUTOR";
export interface UpdateContributorAction {
    type: typeof UPDATE_CONTRIBUTOR;
    payload: {
        uid: string,
        contributors: { [key: string]: OrderContributor },
        location?: Location;
        catalog?: CatalogExtended;
        table?: Table;
    }
}

export const SET_MASTER_USER = "SET_MASTER_USER";
export interface SetMasterUserAction {
    type: typeof SET_MASTER_USER;
    payload: string | undefined | null;
}

export const SET_STATUS_WAITING_SUBMISSION = "SET_STATUS_WAITING_SUBMISSION";
export interface SetStatusWaitingSubmissionAction {
    type: typeof SET_STATUS_WAITING_SUBMISSION;
    payload: boolean;
}

export const MERGE_ORDER = "MERGE_ORDER";
export interface MergeOrderAction {
    type: typeof MERGE_ORDER;
    payload: OrderInBase;
}

export const SEND_ADDED_ITEMS = "SEND_ADDED_ITEMS";
export interface SendAddedItemsAction {
    type: typeof SEND_ADDED_ITEMS;
    payload: {
        successUrl: string,  // via generatePath
    };
}

export const UPDATE_ITEM_NOTE = "UPDATE_ITEM_NOTE";
export interface UpdateItemNoteAction {
    type: typeof UPDATE_ITEM_NOTE;
    payload: {
        note: string,
        product_ref: string,
        sku_ref: string
    }
}

export const UPDATE_LOYALTY_POINTS_USAGE = 'UPDATE_LOYALTY_POINTS_USAGE';
export interface UpdateLoyaltyPointsUsageAction {
    type: typeof UPDATE_LOYALTY_POINTS_USAGE;
    payload: {
        userId: string,
        usePoints: boolean,
        catalog: Catalog,
        location: Location,
        table: Table,
    }
}

export const SET_LOYALTY_USER_ID = 'SET_LOYALTY_USER_ID';
export interface SetLoyaltyUserIdAction {
    type: typeof SET_LOYALTY_USER_ID;
    payload: {
        userId: string,
        onlyIfEmpty: boolean,
    }
}

export const SET_INFO = 'SET_INFO';
export interface SetInfoAction {
    type: typeof SET_INFO;
    payload: {
        account_id: string,
        location_id: string,
        catalog_id: string,
        table_id: string | undefined,
        currency: string,
        service_type: SupportedServiceType | null,
    }
}

export const SET_EXPECTED_TIME = 'SET_EXPECTED_TIME';
export interface SetExpectedTimeAction {
    type: typeof SET_EXPECTED_TIME;
    payload: {
        expectedTime?: Date;
        expectedTimeAsap?: boolean,
        endPreparationTime?: Date
    }
}

export const SET_PICKUP = 'SET_PICKUP';
export interface SetPickupAction {
    type: typeof SET_PICKUP;
    payload: {
        customerInfo: Customer;
    }
}

export const COMPUTE_ORDER_PRICE = "COMPUTE_ORDER_PRICE";
export interface ComputeOrderPriceAction {
    type: typeof COMPUTE_ORDER_PRICE;
    payload: {
        catalog: Catalog,
        location: Location,
        table: Table,
    }
}

export const ADD_DISCOUNT_TO_ORDER = "ADD_DISCOUNT_TO_ORDER";
export interface AddDiscountToOrderAction {
    type: typeof ADD_DISCOUNT_TO_ORDER
    payload: {
        discount: Discount,
        catalog: Catalog,
        location: Location,
        uid: string,
        table: Table,
    }
}
export const REMOVE_DISCOUNT_FROM_ORDER = "REMOVE_DISCOUNT_FROM_ORDER";
export interface RemoveDiscountFromOrderAction {
    type: typeof REMOVE_DISCOUNT_FROM_ORDER
    payload: {
        discount: Discount,
        contributor_uid_to_remove_auto_apply: string,
        catalog: Catalog,
        location: Location,
    }
}

export const FORCE_SEND_TO_API_LOADER = "FORCE_SEND_TO_API_LOADER";
export interface ForceSendToApiLoaderAction {
    type: typeof FORCE_SEND_TO_API_LOADER
}

export const SET_ITEMS_TO_PAY = "SET_ITEMS_TO_PAY";
export interface SetItemsToPayAction {
    type: typeof SET_ITEMS_TO_PAY;
    payload: {
        itemsToPay: OrderItemToPay[],
    }
}

export const EARN_LOYALTY = "EARN_LOYALTY";
export interface EarnLoyaltyAction {
    type: typeof EARN_LOYALTY;
    payload: {
        orderId: string;
    }
}

export const EARN_LOYALTY_SUCCESS = "EARN_LOYALTY_SUCCESS";
export interface EarnLoyaltySuccessAction {
    type: typeof EARN_LOYALTY_SUCCESS;
    payload: {
        loyaltyResult: ComputeLoyaltyResult | null;
    }
}

export const EARN_LOYALTY_ERROR = "EARN_LOYALTY_ERROR";
export interface EarnLoyaltyErrorAction {
    type: typeof EARN_LOYALTY_ERROR;
    payload: {
        code?: string;
        message?: string;
    }
}

export const REFRESH_ORDER_FROM_CONNECTOR = "REFRESH_ORDER_FROM_CONNECTOR";
export interface RefreshOrderFromConnectorAction {
    type: typeof REFRESH_ORDER_FROM_CONNECTOR;
    payload: {
        accountId: string;
        locationId: string;
        catalogId: string;
        tableId: string;
        currentOrderId: string;
    }
}

export const REFRESH_ORDER_FROM_CONNECTOR_SUCCESS = "REFRESH_ORDER_FROM_CONNECTOR_SUCCESS";
export interface RefreshOrderFromConnectorSuccessAction {
    type: typeof REFRESH_ORDER_FROM_CONNECTOR_SUCCESS;
}

export const REFRESH_ORDER_FROM_CONNECTOR_ERROR = "REFRESH_ORDER_FROM_CONNECTOR_ERROR";
export interface RefreshOrderFromConnectorErrorAction {
    type: typeof REFRESH_ORDER_FROM_CONNECTOR_ERROR;
}

export const UPDATE_CURRENCY = 'UPDATE_CURRENCY'
export interface UpdateCurrencyAction {
    type: typeof UPDATE_CURRENCY
    payload: {
        currency: string
    }
}



export type OrderActionTypes =
    OpenModalAction
    | CloseModalAction
    | AddItemAction
    | RemoveItemAction
    | LoadOrdersAction
    | LoadOrdersSuccessAction
    | LoadOrdersErrorAction
    | ComputeOrderPriceAction
    | UpdateLoyaltyPointsUsageAction
    | SetLoyaltyUserIdAction
    | UpdateItemNoteAction
    | MergeOrderAction
    | SetStatusWaitingSubmissionAction
    | SetMasterUserAction
    | AddContributorAction
    | RemoveContributorLocallyAction
    | AddContributorLocalAction
    | UpdateContributorAction
    | CreateDraftOrderAction
    | SetOrderAction
    | SyncOrderAction
    | SetDeliveryZoneAction
    | ResetOrderPaymentsAction
    | ResetOrderAction
    | ResetOrderErrorAction
    | ResetItemsAction
    | RemoveItemAction
    | AddDealAction
    | EditDealAction
    | RemoveDealAction
    | SetCustomerNotesAction
    | AddItemsAction
    | SetChargeAction
    | SetServiceTypeAction
    | SetTableIdAction
    | SetCustomerInformationAction
    | SetPaymentTypeAction
    | SendOrderAction
    | SendOrderSuccessAction
    | SendOrderErrorAction
    | SendAddedItemsAction
    | SetInfoAction
    | SetExpectedTimeAction
    | SetPickupAction
    | AddDiscountToOrderAction
    | RemoveDiscountFromOrderAction
    | ForceSendToApiLoaderAction
    | LoadAvailableTimeSlotsAction
    | LoadAvailableTimeSlotsSuccessAction
    | LoadAvailableTimeSlotsErrorAction
    | SetItemsToPayAction
    | EarnLoyaltyAction
    | EarnLoyaltySuccessAction
    | EarnLoyaltyErrorAction
    | RefreshOrderFromConnectorAction
    | RefreshOrderFromConnectorSuccessAction
    | RefreshOrderFromConnectorErrorAction
    | UpdateCurrencyAction;


export const orderActions = {

    /**
     * Use this function to force display the loader in the webapp. It
     * sets sendToApi.loading to true in the state
     * @returns 
     */
    forceSendToApiLoader: (): ForceSendToApiLoaderAction => ({
        type: FORCE_SEND_TO_API_LOADER
    }),

    addDiscountToOrder: (discount: Discount, catalog: Catalog, location: Location, table: Table, uid: string): AddDiscountToOrderAction => ({
        type: ADD_DISCOUNT_TO_ORDER,
        payload: {
            discount,
            catalog,
            location,
            table,
            uid: uid
        }
    }),
    removeDiscountFromOrder: (
        discount: Discount,
        contributor_uid_to_remove_auto_apply: string,
        catalog: Catalog,
        location: Location
    ): RemoveDiscountFromOrderAction => ({
        type: REMOVE_DISCOUNT_FROM_ORDER,
        payload: {
            discount,
            contributor_uid_to_remove_auto_apply,
            catalog,
            location
        }
    }),
    updateItemNote: (productRef: string, skuRef: string, note: string): UpdateItemNoteAction => ({
        type: UPDATE_ITEM_NOTE,
        payload: {
            note: note,
            product_ref: productRef,
            sku_ref: skuRef
        }
    }),

    updateLoyaltyPointsUsage: (
        userId: string,
        usePoints: boolean,
        catalog: Catalog,
        location: Location,
        table: Table,
    ): UpdateLoyaltyPointsUsageAction => ({
        type: UPDATE_LOYALTY_POINTS_USAGE,
        payload: {
            userId,
            usePoints,
            catalog,
            location,
            table
        }
    }),

    computeOrderPrice: (catalog: Catalog, location: Location, table: Table): ComputeOrderPriceAction => ({
        type: COMPUTE_ORDER_PRICE,
        payload: {
            catalog,
            location,
            table,
        }
    }),

    /**
     * 
     * @param customer 
     * @param setAddressFromCustomer 
     * @param forceSetAll if true, the customer will be entirely replaced by the value provided
     * @returns 
     */
    setCustomerInfo: (customer: Customer | null, setAddressFromCustomer?: boolean, forceSetAll?: boolean): SetCustomerInformationAction => ({
        type: SET_CUSTOMER_INFO,
        payload: {
            customer,
            setAddressFromCustomer,
            forceSetAll,
        }
    }),

    setTableId: (id: string): SetTableIdAction => ({
        type: SET_TABLE_ID,
        payload: id
    }),

    setServiceType: (serviceType: SupportedServiceType): SetServiceTypeAction => ({
        type: SET_ORDER_SERVICE_TYPE,
        payload: serviceType
    }),

    openModal: (): OpenModalAction => ({
        type: OPEN_MODAL,
        payload: null
    }),

    closeModal: (): CloseModalAction => ({
        type: CLOSE_MODAL,
        payload: null
    }),

    addDeal: (
        dealRef: string,
        dealItems: OrderItem[],
        catalog: CatalogExtended,
        location: Location,
        table: Table,
        userId: string,
    ): AddDealAction => ({
        type: ADD_DEAL,
        payload: {
            dealRef,
            dealItems,
            catalog,
            location,
            table,
            userId,
        },
    }),

    editDeal: (
        dealRef: string,
        dealItems: OrderItem[],
        catalog: CatalogExtended,
        location: Location,
        table: Table,
        userId: string,
    ): EditDealAction => ({
        type: EDIT_DEAL,
        payload: {
            dealRef,
            dealItems,
            catalog,
            location,
            table,
            userId,
        },
    }),

    removeDeal: (
        dealKey: string,
    ): RemoveDealAction => ({
        type: REMOVE_DEAL,
        payload: {
            dealKey,
        }
    }),

    addItems: (
        listItems: ListItemsToAdd[],
        catalog: Catalog,
        location: Location,
        table: Table,
        calculPrice?: boolean,
    ): AddItemsAction => {
        // Clear update id for added items (clone to be sure)
        const addedItems: ListItemsToAdd[] = [];
        listItems?.forEach((item) => {
            const orderItem = _.cloneDeep(item) as OrderItem;
            delete orderItem.update_id;
            delete orderItem.created_at;
            addedItems.push(orderItem);
        });
        return {
            type: ADD_ITEMS,
            payload: {
                listItems: addedItems,
                catalog,
                location,
                table,
                calculPrice,
            },
        }
    },

    addItem: (
        product_ref: string,
        sku_ref: string,
        options: OrderOption[],
        catalog: CatalogExtended,
        location: Location,
        table: Table,
        product_name: string,
        quantity: number,
        price: Money,
        customer_notes: string,
        contributor_user_id?: string,
        calculPrice?: boolean,
    ): AddItemAction => ({
        type: ADD_ITEM,
        payload: {
            product: {
                product_ref,
                sku_ref,
                options,
                quantity,
                price,
                product_name,
                contributor_user_id,
                customer_notes
            },
            catalog,
            location,
            table,
            calculPrice,
        },
    }),

    removeItem: (
        sku_ref: string,
        options: any,
        catalog: any,
        location: Location,
        table: Table,
    ): RemoveItemAction => ({
        type: REMOVE_ITEM,
        payload: {
            sku_ref,
            options,
            catalog,
            location,
            table,
        },
    }),

    /**
     * Only reset the items of an order.
     * @returns 
     */
    resetItems: (): ResetItemsAction => ({
        type: RESET_ITEMS,
        payload: null
    }),

    /**
     * Reset the payment info of the order. Useful when
     * getting back from the paymentchoice for example
     * @returns 
     */
    resetOrderPayments: (): ResetOrderPaymentsAction => ({
        type: RESET_ORDER_PAYMENTS,
        payload: null
    }),

    /**
     * Reset an order: empty the items, id, min amount, ...
     * Keep the customer details, the info about the table, catalog etc,
     * the latestOrders, the charges.
     * @param serviceType 
     * @returns 
     */
    resetOrder: (): ResetOrderAction => ({
        type: RESET_ORDER,
        payload: null
    }),

    /**
     * Reset the error code, status and message
     * @returns 
     */
    resetOrderError: (): ResetOrderErrorAction => ({
        type: RESET_ORDER_ERROR,
        payload: null
    }),

    setOrder: (
        order: OrderInBase,
        catalog: Catalog,
        location: Location,
        table: Table,
        ignoreDraft?: boolean,
    ): SetOrderAction => ({
        type: SET_ORDER,
        payload: {
            order,
            catalog,
            location,
            table,
            ignoreDraft: Boolean(ignoreDraft),
        }
    }),

    // table_id and service nullable because can be updated later
    setInfo: (
        account_id: string,
        location_id: string,
        catalog_id: string,
        table_id: string | undefined,
        currency: string,
        service_type: SupportedServiceType | null,
    ): SetInfoAction => ({
        type: SET_INFO,
        payload: {
            account_id,
            location_id,
            catalog_id,
            table_id,
            currency,
            service_type,
        },
    }),

    setPaymentType: (
        paymentType: PaymentTypeExtended,
    ): SetPaymentTypeAction => ({
        type: SET_PAYMENT_TYPE,
        payload: paymentType,
    }
    ),

    /**
     * Send the order with the specified payment type
     * and the pin to be checked if any (not implemented)
     */
    send: (
        type: PaymentTypeExtended,
        paymentAmount?: string,
        paymentData?: any,
        pin = '',
        payment_items?: OrderItemToPay[],
        payment_amount_type?: PaymentAmountType,
        tipChargeAmount?: Money,
    ): SendOrderAction => ({
        type: SEND_ORDER,
        payload: {
            type,
            paymentAmount,
            paymentData,
            pin,
            payment_items,
            payment_amount_type,
            tip_charge_amount: tipChargeAmount,
        },
    }),

    /**
     * Send the added items to the api to append the existing order
     */
    sendAddedItems: (successUrl: string): SendAddedItemsAction => ({
        type: SEND_ADDED_ITEMS,
        payload: {
            successUrl,
        }
    }),

    setInfoPickUp: (customerInfo: Customer): SetPickupAction => ({
        type: SET_PICKUP,
        payload: {
            customerInfo,
        },
    }),

    /**
     * Set the pickup date for the order.
     * @param expectedTime 
     * @returns 
     */
    setExpectedTime: (expectedTime?: Date, expectedTimeAsap?: boolean, endPreparationTime?: Date): SetExpectedTimeAction => ({
        type: SET_EXPECTED_TIME,
        payload: {
            expectedTime,
            expectedTimeAsap,
            endPreparationTime,
        },
    }),

    /**
     * The order has been sent but the api return an error
     */
    orderSendError: (code?: string, message?: string, value?: { [key in CatalogEntityType]: any[] }): SendOrderErrorAction => ({
        type: SEND_ORDER_ERROR,
        payload: { code, message, value },
    }),

    /**
     * The order has been correctly sent
     */
    orderSendSuccess: (
        order: OrderInBase,
        paymentInfos: PaymentInfos | undefined,
        paymentPage: string | undefined,
    ): SendOrderSuccessAction => ({
        type: SEND_ORDER_SUCCESS,
        payload: { order, paymentInfos, paymentPage },
    }),

    /**
     * Setting the customer notes
     */
    setCustomerNotes: (customer_notes: string): SetCustomerNotesAction => ({
        type: SET_CUSTOMER_NOTES,
        payload: { customer_notes }
    }),

    setCharge: (
        charge: OrderCharge,
        catalog: CatalogExtended,
        location: Location,
        table: Table,
    ): SetChargeAction => ({
        type: SET_CHARGE,
        payload: { charge, catalog, location, table }
    }),

    setDeliveryZone: (
        zone: CheckedDeliveryZone
    ): SetDeliveryZoneAction => ({
        type: SET_DELIVERY_ZONE,
        payload: zone,
    }),

    loadAvailableTimeSlots: (serviceType: SupportedServiceType, forDate: DateTime, deliveryZoneRef: string | undefined): LoadAvailableTimeSlotsAction => {
        return {
            type: LOAD_AVAILABLE_TIMESLOTS,
            payload: {
                serviceType,
                forDate,
                deliveryZoneRef
            }
        }
    },

    loadAvailableTimeSlotsSuccess: (availableTimeslots: TimeslotsAvailable | null): LoadAvailableTimeSlotsSuccessAction => {
        return {
            type: LOAD_AVAILABLE_TIMESLOTS_SUCCESS,
            payload: availableTimeslots
        }
    },

    loadAvailableTimeSlotsError: (): LoadAvailableTimeSlotsErrorAction => {
        return {
            type: LOAD_AVAILABLE_TIMESLOTS_ERROR,
        }
    },

    /*
     * Load the orders. See OrderLoadingState.ts for more info
     */
    loadOrders: (
        allowLoadOrder: boolean = false,
        requestParams?: URLSearchParams,
        tableId?: string,
        acountId?: string,
        locationId?: string,
        catalogId?: string,
        location?: Location,
        userOrdersLimit: number = LATEST_ORDERS_DEFAULT_LIMIT,
        tableOrdersLimit: number = LATEST_ORDERS_DEFAULT_LIMIT,
    ): LoadOrdersAction => ({
        type: LOAD_ORDERS,
        payload: {
            userOrdersLimit,
            tableOrdersLimit,
            table: null,  // Only useful for the yield call from Location saga
            catalog: null,  // Only useful for the yield call from Location saga
            acountId,
            locationId,
            tableId,
            catalogId,
            location,
            allowLoadOrder,
            requestParams,
        }
    }),

    loadOrdersSuccess: (
        userOrders: OrderInBase[],
        tableOrders: OrderInBase[],
        connectorGetTableOpenedOrdersError?: ConnectorGetTableOpenedOrdersError | undefined,
    ): LoadOrdersSuccessAction => ({
        type: LOAD_ORDERS_SUCCESS,
        payload: {
            latestUserOrders: userOrders,
            latestTableOrders: tableOrders,
            connectorGetTableOpenedOrdersError,
        }
    }),

    loadOrdersError: (
        orderError: OrderError,
    ): LoadOrdersErrorAction => ({
        type: LOAD_ORDERS_ERROR,
        payload: {
            orderError,
        },
    }),

    /**
     * Merge an OrderInBase with the order in state
     */
    mergeOrder: (
        order: OrderInBase
    ): MergeOrderAction => ({
        type: MERGE_ORDER,
        payload: order,
    }),

    /**
     * Called each time the order is updated in firestore
     * @param data the document snapshot
     */
    syncOrder: (
        data: firebase.firestore.DocumentSnapshot
    ): SyncOrderAction => ({
        type: SYNC_ORDER,
        payload: data,
    }),

    setupSyncCustomer: (userId?: string, isAnonymous?: boolean): SetupSyncCustomerAction => ({
        type: SETUP_SYNC_CUSTOMER,
        payload: { userId, isAnonymous },
    }),

    /**
     * Called each time the customer is updated in firestore
     * @param data the document snapshot
     */
    syncCustomer: (
        data: firebase.firestore.DocumentSnapshot
    ): SyncCustomerAction => ({
        type: SYNC_CUSTOMER,
        payload: data,
    }),

    /**
     * Create a draft empty order in firestore and set it to the state
     * @param order 
     * @returns 
     */
    createDraftOrder: (location?: Location, catalog?: CatalogExtended, table?: Table): CreateDraftOrderAction => ({
        type: CREATE_DRAFT_ORDER,
        payload: {
            location,
            catalog,
            table
        }
    }),

    /**
     * Add a contributor to the order and send it to firestore
     * @param user 
     * @returns 
     */
    addContributor: (
        uid: string,
        user: Customer,
        location?: Location,
        catalog?: CatalogExtended,
        table?: Table,
        patch?: Partial<OrderContributor>
    ): AddContributorAction => ({
        type: ADD_CONTRIBUTOR,
        payload: {
            uid,
            user,
            location,
            catalog,
            table,
            patch,
        }
    }),

    /**
     * Remove a contributor from the redux state list
     * @param user 
     * @returns 
     */
    removeContributorLocally: (uid: string): RemoveContributorLocallyAction => ({
        type: REMOVE_CONTRIBUTOR_LOCALLY,
        payload: { uid }
    }),

    /**
     * WARNING: do not use directly! Meant to be called by the saga when trying to
     * add a contributor to an order which has not been saved un db yet.
     */
    addContributorLocal: (uid: string, user: Customer, patch?: Partial<OrderContributor>): AddContributorLocalAction => ({
        type: ADD_CONTRIBUTOR_LOCAL,
        payload: { uid, user, patch }
    }),

    updateContributors: (
        uid: string,
        contributors: { [key: string]: OrderContributor },
        location?: Location,
        catalog?: CatalogExtended,
        table?: Table
    ): UpdateContributorAction => ({
        type: UPDATE_CONTRIBUTOR,
        payload: {
            uid,
            contributors,
            location,
            catalog,
            table
        }
    }),

    /**
     * Set the master user of the order by its UID. If undefined or null,
     * remove the master_user_uid property from the order
     * @param master_uid 
     * @returns 
     */
    setMasterUser: (master_uid: string | null | undefined): SetMasterUserAction => ({
        type: SET_MASTER_USER,
        payload: master_uid,
    }),

    /**
     * Set the status of the order to WAITING_SUBMISSION (or DRAFT depending on the boolean)
     * @param toWaitingSubmission true = WAITING_SUBMISSION, false = DRAFT 
     * @returns 
     */
    setStatusWaitingSubmission: (
        toWaitingSubmission: boolean
    ): SetStatusWaitingSubmissionAction => ({
        type: SET_STATUS_WAITING_SUBMISSION,
        payload: toWaitingSubmission,
    }),

    setItemsToPay: (
        itemsToPay: OrderItemToPay[],
    ): SetItemsToPayAction => ({
        type: SET_ITEMS_TO_PAY,
        payload: {
            itemsToPay,
        }
    }),
    /**
     * Set the order loyalty_user_id property.
     * @param userId 
     * @param onlyIfEmpty if true and if the property is already filled in the order, do not replace.
     * @returns 
     */
    setLoyaltyUserId: (
        userId: string,
        onlyIfEmpty: boolean = false,
    ): SetLoyaltyUserIdAction => ({
        type: SET_LOYALTY_USER_ID,
        payload: {
            userId: userId,
            onlyIfEmpty: onlyIfEmpty,
        }
    }),

    /**
     * Ask the API to compute loyalty for an order and its user
     */
    earnLoyalty: (
        orderId: string,
    ): EarnLoyaltyAction => ({
        type: EARN_LOYALTY,
        payload: {
            orderId: orderId,
        }
    }),

    earnLoyaltySuccess: (
        result: ComputeLoyaltyResult | null
    ): EarnLoyaltySuccessAction => ({
        type: EARN_LOYALTY_SUCCESS,
        payload: {
            loyaltyResult: result,
        }
    }),

    earnLoyaltyError: (
        code?: string,
        message?: string,
    ): EarnLoyaltyErrorAction => ({
        type: EARN_LOYALTY_ERROR,
        payload: {
            code,
            message,
        }
    }),

    /**
     * This action will trigger an API call to the route ORDERS_FETCH_TABLE.
     * In the result, we look for the order which has the same ID as the order in state.
     * If we find it, we replace it in the state so that we're sure the webapp is updated with
     * the latest data from the connector.
     * @param accountId 
     * @param locationId 
     * @param catalogId 
     * @param tableId 
     * @returns 
     */
    refreshOrderFromConnector: (
        accountId: string,
        locationId: string,
        catalogId: string,
        tableId: string,
        currentOrderId: string,
    ): RefreshOrderFromConnectorAction => ({
        type: REFRESH_ORDER_FROM_CONNECTOR,
        payload: {
            accountId,
            locationId,
            catalogId,
            tableId,
            currentOrderId,
        }
    }),

    refreshOrderFromConnectorSuccess: (): RefreshOrderFromConnectorSuccessAction => ({
        type: REFRESH_ORDER_FROM_CONNECTOR_SUCCESS,
    }),

    refreshOrderFromConnectorError: (): RefreshOrderFromConnectorErrorAction => ({
        type: REFRESH_ORDER_FROM_CONNECTOR_ERROR,
    }),

    updateCurrency: (currency: string): UpdateCurrencyAction => {
        return {
            type: UPDATE_CURRENCY,
            payload: { currency }
        }
    },


};

export default orderActions;
