import { Alert, Box, Typography } from '@mui/material';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DeviceContext } from '../../App';
import LoaderComponent from '../../Common/components/LoaderComponent';
import useShowAlcoholDisclaimer from '../../Common/hooks/useShowAlcoholDisclaimer';
import { AddButtonRedirect } from '../../Common/models/AddButtonRedirect';
import AlcoholDisclaimerModal from '../../Products/component/AlcoholDisclaimerModal';
import ProductModal from '../../Products/pages/ProductModal';
import { Product, Sku } from '../../my-lemonade-library/model/Catalog';
import DealExt from '../../my-lemonade-library/model/DealExtended/DealExt';
import { SupportedServiceType } from '../../my-lemonade-library/model/Location';
import { OrderItem, OrderOption } from '../../my-lemonade-library/model/Order';
import { SkuExtended } from '../../my-lemonade-library/model/ProductExtended/ProductExtended';
import { moneyToNumber } from '../../my-lemonade-library/src/common/models/Money';
import translationService from '../../my-lemonade-library/src/translations/services/TranslationService';
import { useTypedSelector } from '../../redux/root-reducer';
import { default as actions, default as DealActions } from '../redux/actions';
import { getDealTranslation, getFullDealSkuList, updateOrderItem } from '../service/dealDisplayService';
import DealActionButton from './DealDisplay/DealActionButton';
import DealDisplayHeader from './DealDisplay/DealDisplayHeader';
import DealDisplayImage from './DealDisplay/DealDisplayImage';
import DealDisplayLines from './DealDisplay/DealDisplayLines';

interface DealDisplayWipProps {
    selectedDeal: DealExt,
    onSubmit: (redirect: AddButtonRedirect) => void,
    onDealEdition: (redirect: AddButtonRedirect) => void;
}

const DealDisplay: React.FC<DealDisplayWipProps> = (props) => {
    const {
        selectedDeal,
        onSubmit,
        onDealEdition
    } = props;

    const intl = useIntl();
    const dispatch = useDispatch();

    const { selectedCatalog, isLoading, selectedTable, selectedLocation } = useTypedSelector(state => state.locations);
    const { listItems, dealPrice } = useTypedSelector(state => state.deals);
    const { order } = useTypedSelector(state => state.order);

    const isDealEdition: boolean = new URLSearchParams(useLocation().search).get('isDealEdition') === "true";

    const { mobile_device } = useContext(DeviceContext);

    const [initialOptions, setInitialOptions] = useState<OrderOption[]>();
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [currentDealLineIndex, setCurrentDealLineIndex] = useState<number>(0);
    const [selectedSku, setSelectedSku] = useState<SkuExtended | undefined>(undefined);
    const [selectedDealItems, setSelectedDealItems] = useState<{ skuRef: string, product: Product | null }[]>([]);
    const [accordionsExpander, setAccordionsExpander] = useState<boolean[]>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openAlcoholModal, setOpenAlcoholModal] = useState<boolean>(false);
    const [addButtonRedirect, setAddButtonRedirect] = useState<AddButtonRedirect>(AddButtonRedirect.CATALOG);
    const [currentDealPrice, setCurrentDealPrice] = useState<number>(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
    const [isMaxDealPerOrderMessageDisplayed, setIsMaxDealPerOrderMessageDisplayed] = useState<boolean>(false);

    const shouldDisplayAlcoholDisclaimer = useShowAlcoholDisclaimer(selectedDealItems, true);

    const setCurrentDealPriceAndButtonDisabled = () => {
        const numDealStartingPrice: number = selectedDeal.starting_price
            ? moneyToNumber(selectedDeal.starting_price, false, selectedDeal)
            : 0

        setCurrentDealPrice(dealPrice > numDealStartingPrice ? dealPrice : numDealStartingPrice)

        const allSkusSelected = (selectedDealItems || []).every((sku) => sku.skuRef);
        const correctLength = listItems.length === selectedDeal.lines.length;

        setIsButtonDisabled(!(allSkusSelected && correctLength));
    }

    const setSelectedSkusForEdition = () => {
        if (!accordionsExpander) setAccordionsExpander(selectedDeal.lines.map(() => true));

        setSelectedDealItems(selectedDeal.lines.map((line) => {
            const dealItem = listItems.find(item => item.deal_line?.deal_line_ref === line.ref);
            const product = selectedCatalog?.data.products.find(p => p.ref === dealItem?.product_ref);

            return {
                skuRef: dealItem?.sku_ref || '',
                product: product || null,
            }

        }));
    }

    useMemo(() => {
        if (selectedCatalog && isDealEdition) {
            setSelectedSkusForEdition();
        }
    }, [selectedCatalog, dealPrice, listItems, selectedDeal, isDealEdition]);

    useEffect(() => {
        setCurrentDealPriceAndButtonDisabled();
        if (selectedDeal.usage_restriction?.max_per_order && Object.values(order.deals).filter(deal => deal.ref === selectedDeal.ref).length >= selectedDeal.usage_restriction.max_per_order) {
            setIsMaxDealPerOrderMessageDisplayed(true);
            setAccordionsExpander(selectedDeal.lines.map(() => false));
        } else {
            setIsMaxDealPerOrderMessageDisplayed(false);
            setAccordionsExpander(selectedDeal.lines.map(() => true));
        }
    }, [selectedDeal, dealPrice]);

    useEffect(() => {
        setCurrentDealPriceAndButtonDisabled();
    }, [listItems, selectedDealItems, selectedDeal.starting_price, dealPrice]);

    // Reset the deal component if we are not in edit mode
    useMemo(() => {
        if (!isDealEdition) {
            dispatch(DealActions.initDeal(selectedDeal, selectedCatalog!, selectedLocation!, order, selectedTable));
        }
    }, [isDealEdition]);

    const openModal = (product: Product, sku: SkuExtended) => {
        const foundItemInCurrentDeal = listItems.find((item) => item.sku_ref === sku.ref);
        if (foundItemInCurrentDeal?.options && foundItemInCurrentDeal.options.length > 0) {
            setInitialOptions(foundItemInCurrentDeal.options);
        }
        else {
            setInitialOptions(undefined);
        }

        setSelectedProduct(product)
        setSelectedSku(sku)
        setIsOpen(true)
    }

    const handleAccordion = (index: number, isOverriding?: boolean): void => {
        if (accordionsExpander) {
            const tempArray: boolean[] = [...accordionsExpander]
            tempArray[index] = (isOverriding !== undefined && isOverriding !== null) ? isOverriding : !accordionsExpander[index]
            setAccordionsExpander(tempArray)
        }
    }

    const handleSubmitDeal = (redirect: AddButtonRedirect) => {
        setAddButtonRedirect(redirect);
        shouldDisplayAlcoholDisclaimer ? setOpenAlcoholModal(true) : onSubmit(redirect)
    }

    const handleEditDeal = (redirect: AddButtonRedirect) => {
        setAddButtonRedirect(redirect);
        shouldDisplayAlcoholDisclaimer ? setOpenAlcoholModal(true) : onDealEdition(redirect);
    }

    /**
    * @param product 
    * @param skuSelected 
    * @param lineIndex 
    * @param optionArray 
    */
    const updateItemOption = (product: Product, skuSelected: Sku, lineIndex: number, optionArray?: any[]) => {
        // Want to see the product and select options
        if (
            skuSelected.option_list_refs
            && skuSelected.option_list_refs.length > 0
            && !isOpen
        ) {
            setCurrentDealLineIndex(lineIndex);
            openModal(product, skuSelected);
        }

        // Want to validate the product and continue to the other deal lines
        else if (selectedTable.service_type !== SupportedServiceType.VIEW) {
            const otherItemDeal: OrderItem | undefined = listItems.find((item) => item.sku_ref !== skuSelected.ref);
            const dealKeyToAssign: string = otherItemDeal?.deal_line?.deal_key || '0';

            // The last arg is not good, each deal line MUST have a deal key
            let orderItem: OrderItem = updateOrderItem(selectedDeal, product, skuSelected, lineIndex, dealKeyToAssign);

            const optionOrderList: any[] = []
            if (optionArray) {
                optionArray.forEach(opt => {
                    const option: OrderOption = {
                        name: opt.name,
                        option_list_name: opt.option_list_ref,
                        ref: opt.ref,
                        option_list_ref: opt.option_list_ref,
                        price: opt.price,
                    };
                    optionOrderList.push(option)
                })
            }
            orderItem = { ...orderItem, options: optionOrderList } as OrderItem;

            dispatch(actions.addDealItem(
                orderItem, selectedDeal, selectedCatalog!, selectedLocation!, selectedTable.service_type!, order, selectedTable
            ));

            const tempSelectedSkus = [...selectedDealItems]
            tempSelectedSkus[lineIndex] = { skuRef: skuSelected.ref, product }
            setSelectedDealItems(tempSelectedSkus);
            handleAccordion(lineIndex, false);
        }

    }

    return (
        !isLoading && selectedDeal && selectedCatalog ? (
            <Box
                display={'flex'}
                flexDirection={mobile_device ? 'column' : 'row'}
                gap={3}
                flex={1}
                maxHeight={'100%'}
            >
                {!mobile_device &&
                    <DealDisplayImage
                        selectedCatalog={selectedCatalog}
                        selectedDeal={selectedDeal}
                    />}

                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    flex={1}
                    minHeight={'100%'}
                    maxHeight={'100%'}
                >
                    {selectedDeal &&
                        <Box
                            display={'flex'}
                            maxHeight={'100%'}
                            flexDirection={'column'}
                            gap={2}
                        >
                            <DealDisplayHeader selectedDeal={selectedDeal} />
                            <DealDisplayLines
                                dealLines={selectedDeal.lines}
                                selectedDealItems={selectedDealItems}
                                accordionsExpander={accordionsExpander}
                                getFullDealSkuList={(line) => {
                                    return getFullDealSkuList(line, selectedCatalog, order)
                                }}
                                updateItemOption={updateItemOption}
                                handleAccordion={handleAccordion}
                            />
                            {isButtonDisabled && isMaxDealPerOrderMessageDisplayed &&
                                <Alert
                                    severity='info'
                                    style={{
                                        marginBottom: '20px'
                                    }}
                                >
                                    <Typography
                                        textAlign={'left'}
                                        fontSize={'12px'}
                                    >
                                        {intl.formatMessage({ id: "deals.max_selection_per_order_has_been_reached" })}
                                    </Typography>
                                </Alert>
                            }
                            <DealActionButton
                                selectedCatalog={selectedCatalog}
                                isDealEdition={isDealEdition}
                                currentDealPrice={currentDealPrice}
                                isButtonDisabled={isButtonDisabled || isMaxDealPerOrderMessageDisplayed}
                                handleSubmitDeal={handleSubmitDeal}
                                handleEditDeal={handleEditDeal}
                            />
                        </Box>

                    }
                </Box>

                {/* Modals */}
                {selectedProduct &&
                    <>
                        <AlcoholDisclaimerModal
                            open={openAlcoholModal}
                            onClose={() => setOpenAlcoholModal(false)}
                            onAccept={() => {
                                setOpenAlcoholModal(false)
                                onSubmit(addButtonRedirect)
                            }}
                        />
                        <ProductModal
                            open={isOpen}
                            closeModal={() => {
                                setInitialOptions(undefined);
                                setIsOpen(false);
                            }}
                            buttonTitleId="deals.product.chooseButton"
                            selectedProduct={selectedProduct}
                            initialOptions={initialOptions}
                            dealSku={selectedSku}
                            hideQuantityButton={true}
                            onAddProduct={
                                (selectedProduct: Product, selectedOptions: OrderOption[], selectedSku: Sku, quantity: number) => {
                                    updateItemOption(selectedProduct, selectedSku, currentDealLineIndex, selectedOptions)
                                }
                            }
                            hideReorder
                            forceImageDisplay
                            forceDescriptionDisplay
                            forcedBackToLabel={getDealTranslation(intl, translationService.getDealNameTranslationKey(selectedDeal), selectedDeal.name)}
                        />
                    </>
                }
            </Box>
        ) : <LoaderComponent />
    )
}

export default DealDisplay;