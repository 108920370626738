import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from "@mui/material";
import { useContext, useRef } from "react";
import { useIntl } from 'react-intl';
import { DeviceContext } from '../../../App';
import { DealLine, Product, Sku } from "../../../my-lemonade-library/model/Catalog";
import FullDealSku from '../../../my-lemonade-library/src/deals/models/FullDealSku';
import translationService from '../../../my-lemonade-library/src/translations/services/TranslationService';
import DealLinesDisplay from '../DealLinesDisplay';

interface DealDisplayLinesProps {
    dealLines: DealLine[];
    selectedDealItems: { skuRef: string, product: Product | null }[];
    accordionsExpander?: boolean[];
    getFullDealSkuList: (line: DealLine) => FullDealSku[];
    updateItemOption: (product: Product, skuSelected: Sku, lineIndex: number, optionArray?: any[]) => void;
    handleAccordion: (index: number, bool?: boolean) => void;
}

const DealDisplayLines: React.FC<DealDisplayLinesProps> = (props) => {
    const {
        dealLines,
        selectedDealItems,
        accordionsExpander,
        getFullDealSkuList,
        updateItemOption,
        handleAccordion,
    } = props;

    const theme = useTheme();
    const intl = useIntl();

    const { mobile_device } = useContext(DeviceContext);

    const refs = useRef<HTMLDivElement[]>([]);

    const hasSomeOption = dealLines?.some((dealLine) => dealLine.skus.length > 1);

    const desktopScrollableDivStyling = {
        overflow: 'auto',
        scrollbarGutter: 'stable',
        '&::-webkit-scrollbar': {
            width: '9px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: '15px',
        },
        '@supports not selector(::-webkit-scrollbar)': {
            scrollbarColor: theme.palette.grey[200],
        },
    }

    const mobileStyling = {
        'padding-bottom': '80px'
    }

    const isAccordionExpanded = (index: number): boolean => {
        if (!accordionsExpander) return false;
        return accordionsExpander && accordionsExpander[index]
    }

    return (
        <Box
            flex={1}
            display={'flex'}
            flexDirection={'column'}
            gap={1}
            sx={mobile_device ? mobileStyling : desktopScrollableDivStyling}
        >
            {dealLines && hasSomeOption && dealLines.map((dealLine: DealLine, dealLineIndex: number) => {
                if (dealLine.skus.length === 0 || (dealLine.skus.length === 1 && dealLine.hide)) return null;
                return (
                    <Box
                        key={dealLineIndex}
                    >
                        <Accordion
                            ref={() => refs.current[dealLineIndex]}
                            onChange={() => handleAccordion(dealLineIndex)}
                            expanded={isAccordionExpanded(dealLineIndex)}
                        >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Box
                                    display='flex'
                                    flexDirection='column'
                                >
                                    <Typography
                                        variant="h4"
                                        fontWeight={500}
                                        color="textSecondary"
                                        style={{
                                            alignSelf: 'flex-start',
                                        }}
                                    >
                                        {dealLine.name ? dealLine.name.toUpperCase()
                                            : (dealLine.label ? dealLine.label.toUpperCase : "line " + dealLineIndex)
                                        }
                                    </Typography>

                                    {selectedDealItems[dealLineIndex]
                                        && selectedDealItems[dealLineIndex].product
                                        && dealLine.skus.some(sku => sku.ref === selectedDealItems[dealLineIndex].skuRef) &&
                                        <Typography
                                            variant='body1'
                                            style={{ color: theme.palette.text.disabled }}
                                        >
                                            {intl.formatMessage({
                                                id: translationService.getProductNameTranslationKey(selectedDealItems[dealLineIndex].product as Product),
                                                defaultMessage: selectedDealItems[dealLineIndex].product?.name
                                            })}
                                        </Typography>
                                    }
                                </Box>
                            </AccordionSummary>

                            <AccordionDetails style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                            >
                                <DealLinesDisplay
                                    dealSkus={getFullDealSkuList(dealLine) || []}
                                    lineIndex={dealLineIndex}
                                    onChange={updateItemOption}
                                    selected={selectedDealItems[dealLineIndex]?.skuRef || null}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                )
            })}
        </Box>
    )
}

export default DealDisplayLines;
